import { put, takeEvery, call } from 'redux-saga/effects';
import { ACTION_GET_MEDIA_LIBRARY_LIST } from '../constant';
import { createSetMediaLibraryListAction } from '../action';
import { fetchMediaLibraryList } from '../../http';

export function* getMediaLibraryList(action: any) {
  try {
    const data = yield call(fetchMediaLibraryList, action.param);
    if (Array.isArray(data.data.list)) {
      yield put(createSetMediaLibraryListAction(data.data));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetMediaLibraryList() {
  yield takeEvery(ACTION_GET_MEDIA_LIBRARY_LIST, getMediaLibraryList);
}
