import React, { memo, useState } from 'react';
import { Modal } from 'antd';

const Img = memo((props: any) => {
  const [show, setShow] = useState(false);
  return (
    <div style={{ ...props.style }} id={props.id} className={props.className}>
      <img
        src={props.imageUrl || props}
        alt=""
        className={props.className ? 'message-img-detail-img' : 'message-content-detail-img'}
        onClick={() => setShow(true)}
      />
      <Modal
        visible={show}
        onCancel={() => setShow(false)}
        footer={null}
        width={760}
        style={{ textAlign: 'center' }}
        centered
        title={<h3 className="send-title">图片预览</h3>}
      >
        <div style={{ textAlign: 'center' }}>
          <img src={props.imageUrl || props} alt="图片" className="see-img-modal" />
        </div>
      </Modal>
    </div>
  );
});

export default Img;
