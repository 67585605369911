import React from 'react';
import { Icon } from 'antd';

const WaringPop: React.FC<any> = props => {
  const popStyle = {
    position: 'relative' as 'relative',
    background: props.styleTheme.background,
    color: props.styleTheme.color,
    fontSize: '12px',
    padding: '12px 16px',
    display: 'inline-block',
    lineHeight: '14px',
    borderRadius: 4,
    marginTop: '12px',
  };
  const iconStyle = {
    position: 'absolute' as 'absolute',
    top: '-16px',
    left: '20px',
    border: 'solid',
    borderWidth: '8px 10px',
    borderColor: 'transparent transparent #E9E9E9',
  };
  return (
    <div style={popStyle}>
      <div style={iconStyle}></div>
      <Icon type="exclamation-circle" style={{ color: props.styleTheme.color }} />
      <span style={{ paddingLeft: 8 }}>{props.content}</span>
    </div>
  );
};
export default WaringPop;
