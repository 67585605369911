import React from 'react';
import { Tabs, Divider, Badge } from 'antd';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';
import { State, WxSession, Target, WxClient } from '@/store/types/state';
import {
  createSetCurrentTargetAction,
  createUpdateWxMessageListStatus,
  setCurrentTabAction,
  createGetWxFissionSessionListAction,
  createSetCurrentClientAction,
  deleteSyncSendMessageAction,
  createSetTabUnReadCountAction,
} from '@/store/action';
import { TARGET_TYPE_SESSION } from '@/store/constant';
import './index.less';
import ChatItem from '../../../../component/ChatItem';
import eventBus from '@/ws/event';

const tabFontSize = {
  fontSize: '14px',
};
interface Prop {
  currentTarget: Target;
  sessionList: WxSession[];
  sessionIsEnd: boolean | number;
  currentSiderTab: string;
  tabUnReadCount: any;
  setCurrentTab: (data: string) => void;
  setCurrentTarget: (data: Target | null) => any;
  setCurrentClient: (e: WxClient) => any;
  updateMessageListStatus: (data: any, sessionid: number) => any;
  getFissionSessionlist: (data: any) => any;
  deleteSyncMessage: () => any;
  setTabUnReadCount: (data: any) => any;
}
class FissionTab extends React.Component<Prop> {
  scrollBoxRef: any = '';

  pollingTimer: any = '';

  componentDidMount() {
    // 会话列表初始轮询
    this.pollingFn();
    eventBus.on('updateSessionList', () => this.hackScrollMove());
  }

  componentWillUnmount() {
    clearInterval(this.pollingTimer);
  }

  pollingFn = () => {
    this.pollingTimer = setInterval(() => {
      this.props.getFissionSessionlist({ type: this.props.currentSiderTab[1] });
    }, 19000);
  };

  handlerChatItemClick = (chatItem: any) => {
    if (!chatItem) return;
    let number = chatItem.newUnread || 0;
    const { id, chatType, newUnread } = chatItem;
    if (newUnread > 99) {
      number = 99;
    }
    if (this.props.currentTarget && this.props.currentTarget.id === id) return;
    // 重置请求messageList的控制参数
    this.props.setCurrentTarget({
      id,
      type: TARGET_TYPE_SESSION,
      isSingle: chatType === 1,
      chatId: chatItem.chatId,
      currSessionUnreadNum: number,
      chatType,
      nickname: chatItem && chatItem.nickname,
      avatar: chatItem && chatItem.headImg,
    });
    if (chatItem.newUnread) {
      let { qunUnReadCount, subUnReadCount } = this.props.tabUnReadCount;
      if (chatType === 1) {
        subUnReadCount -= 1;
      } else if (chatType === 2) {
        qunUnReadCount -= 1;
      }
      this.props.setTabUnReadCount({ qunUnReadCount, subUnReadCount });
    }
    // 每个会话对应的wxId不一样，需要重新设置
    const { headImg, nickname, remarkName, statusOnline, wxAlias, wxId } = chatItem.owner;
    this.props.setCurrentClient({
      headImg: headImg || '',
      nickname: nickname || '',
      remarkName: remarkName || '',
      statusOnline,
      wxAlias: wxAlias || '',
      wxId: wxId || '',
      isFission: true,
    });
    this.props.deleteSyncMessage();
  };

  handlerTabChange = (key: any) => {
    if (this.props.currentSiderTab !== key) {
      this.props.setCurrentTab(`0${key}`);
      this.props.getFissionSessionlist({ type: key });
      this.props.setCurrentTarget(null);
    }
  };

  hackScrollMove = () => {
    if (this.scrollBoxRef) {
      this.scrollBoxRef.scrollTop = this.scrollBoxRef.scrollTop + 1;
    }
  };

  render() {
    const { currentTarget, sessionList, currentSiderTab } = this.props;
    const { qunUnReadCount, subUnReadCount } = this.props.tabUnReadCount;
    const SessionList = (
      <LazyLoad resize overflow>
        <div
          className="scroll-box"
          ref={node => {
            this.scrollBoxRef = node;
          }}
          id="scroll-box-0"
        >
          {sessionList.map((item: WxSession) => (
            <div
              key={item.id}
              data-session-id={item.id}
              data-last-message-id={item.lastMsg && item.lastMsg.mid}
              style={{
                position: 'relative',
              }}
              className={`${
                currentTarget && item.id === currentTarget.id ? ' chatitem-active' : ''
              }`}
            >
              <ChatItem
                item={item}
                key={item.id}
                currentTarget={currentTarget}
                chatItemClick={() => this.handlerChatItemClick(item)}
                isFission
              />
              <Divider
                style={{
                  margin: '0px 16px',
                  width: '90%',
                  minWidth: 'unset',
                  position: 'absolute',
                }}
              />
            </div>
          ))}
        </div>
      </LazyLoad>
    );

    return (
      <Tabs
        className="fission-tab"
        animated={false}
        defaultActiveKey="1"
        activeKey={currentSiderTab[1]}
        onChange={this.handlerTabChange}
        tabBarStyle={tabFontSize}
      >
        <Tabs.TabPane
          key="1"
          tab={
            <span style={tabFontSize}>
              <Badge count={subUnReadCount > 0 ? subUnReadCount : ''} overflowCount={99999999}>
                <span>好友聊天</span>
              </Badge>
            </span>
          }
        >
          {SessionList}
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={
            <span style={tabFontSize}>
              <Badge count={qunUnReadCount > 0 ? qunUnReadCount : ''} overflowCount={99999999}>
                <span>群聊天</span>
              </Badge>
            </span>
          }
        >
          {SessionList}
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

function mapStateToProps(state: State) {
  const { currentTarget, sessionList, sessionIsEnd, currentSiderTab, tabUnReadCount } = state;
  return {
    currentTarget,
    sessionList,
    sessionIsEnd,
    currentSiderTab,
    tabUnReadCount,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    updateMessageListStatus: (params: any, sessionId: number) =>
      dispatch(createUpdateWxMessageListStatus(params, sessionId)),
    setCurrentTab: (data: string) => dispatch(setCurrentTabAction(data)),
    setCurrentTarget: (data: Target | null) => dispatch(createSetCurrentTargetAction(data)),
    getFissionSessionlist: (data: any) => dispatch(createGetWxFissionSessionListAction(data)),
    setCurrentClient: (e: WxClient) => dispatch(createSetCurrentClientAction(e)),
    deleteSyncMessage: () => dispatch(deleteSyncSendMessageAction()),
    setTabUnReadCount: (data: any) => dispatch(createSetTabUnReadCountAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FissionTab);
