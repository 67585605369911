import React, { Component } from 'react';
import { Layout, List, Tooltip, message, Empty, Select } from 'antd';
import { connect } from 'react-redux';
import { createSyncSendMessage } from '@/store/action';
import request from '@/http/request';

const { Option } = Select;

interface State {
  groupList: any[];
  quickReplayList: any[];
  currReplyId: string;
  activeKey: string;
}

class QuickReplayInfo extends Component<any, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      groupList: [],
      quickReplayList: [],
      currReplyId: '0',
      activeKey: '0',
    };
    this.init = this.init.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
    this.init();
  }

  async init() {
    const groupList = (await request.get('/im/v2/quick_reply/groups')).data.list;
    if (!groupList.length) {
      this.setState({
        groupList: [],
        quickReplayList: [],
      });
      return;
    }
    const { activeKey } = this.state;
    const item = groupList[parseInt(activeKey, 0)];
    this.getQuickReplayList(item && item.id);
    this.setState({
      groupList,
    });
  }

  getQuickReplayList = (groupId?: any) => {
    request.get('/im/v2/quick_replys', { groupId }).then((res: any) => {
      this.setState({
        quickReplayList: res.data.list,
      });
    });
  };

  render() {
    const { groupList, currReplyId, quickReplayList, activeKey } = this.state;
    const list = quickReplayList.map(item => ({
      id: item.id,
      content: item.content,
      type: item.msg_type,
    }));
    if (!groupList.length) {
      return (
        <Empty
          image="/noreply.png"
          description="没有快捷回复"
          style={{ color: 'rgba(89, 89, 89, 0.4)' }}
        />
      );
    }

    const options =
      groupList && groupList.map((item, index) => <Option key={index}>{item.name}</Option>);

    return (
      <Layout className="quick-reply-info">
        <div className="scroll-box" id="scroll-box-3">
          <Select
            showSearch
            className="quick-reply-select"
            placeholder="选择快捷回复分组"
            defaultValue={groupList[parseInt(activeKey, 0)].name}
            onChange={(e: any) => {
              this.setState({ activeKey: e });
              this.getQuickReplayList(groupList[e].id);
            }}
          >
            {options}
          </Select>
          <>
            {list.length ? (
              <List
                size="small"
                // bordered
                dataSource={list}
                style={{ borderBottom: '1px solid rgba(38,38,38,0.17)' }}
                renderItem={(item, index) => (
                  <List.Item
                    key={index}
                    style={{
                      background: currReplyId === item.id ? 'rgba(38,38,38,0.07)' : '',
                      padding: '12px 16px',
                      borderBottom: '1px solid rgba(38,38,38,0.17)',
                    }}
                    extra={
                      <Tooltip placement="left" title={item.type ? '' : item.content}>
                        <div
                          className="message-block"
                          onClick={() => {
                            if (!this.props.id) {
                              message.error('请选择会话');
                              return;
                            }
                            this.props.syncMessage({
                              rawMsg: item.content,
                              msgType: item.type,
                            });
                            this.setState({ currReplyId: item.id });
                          }}
                        >
                          {item.type ? (
                            <img
                              width="auto"
                              height={82}
                              alt="logo"
                              style={{ maxWidth: '100%', borderRadius: '4px' }}
                              src={item.content}
                            />
                          ) : (
                            item.content
                          )}
                        </div>
                      </Tooltip>
                    }
                  ></List.Item>
                )}
              />
            ) : (
              <Empty className="im-empty-status" image="/noreply.png" description="没有快捷回复" />
            )}
          </>
        </div>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    syncMessage: (param: any) => dispatch(createSyncSendMessage(param)),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(QuickReplayInfo);
