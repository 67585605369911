import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, Menu, Popconfirm, Popover, Button } from 'antd';
import clx from 'classnames';
import { formatDate } from '@/util';
import request from '@/http/request';
import UserCard from '@/components/UserCard';
import MessageContent from './MessageContent';
import ShowModal from '@/components/ShowModal';
import { State } from '@/store/types/state';
import { createGetGroupMemberListAction, createSyncSendMessage } from '@/store/action';

const ChatInvitationPreviewModal = (props: any, wxId: string, fromWxid: string) => {
  const ChatInvitationPreview = () => {
    const { status } = props;
    return (
      <div className="chat-invitation">
        {status === 4 ? (
          ''
        ) : (
          <>
            <img alt="头像" className="chat-invitation-avatar" src={props.groupAvatar} />
            <div className="chat-invitation-member">{props.memberCount}</div>
            <div className="chat-invitation-message">{props.message}</div>
          </>
        )}
        {(() => {
          if (fromWxid === wxId) {
            // 自己发出的邀请
            return <div style={{ fontSize: '20px' }}>该邀请已发送</div>;
          }
          switch (status) {
            case 0: // 页面可用，未接受邀请
            case 2: // 页面可用，未实名
            case 3: // 页面可用，未实名
              return (
                <>
                  <Button
                    ghost
                    type="primary"
                    disabled={status === 2 || status === 3}
                    style={{ position: 'relative' }}
                    onClick={() => {
                      request
                        .post('/im/v2/group/invitation/accept', {
                          wxId,
                          url: props.acceptUrl,
                        })
                        .then(() => {
                          ShowModal.close();
                        });
                    }}
                  >
                    {status === 2 || status === 3 ? <div className="mask-btn"></div> : ''}
                    加入群聊
                  </Button>
                  <div className="chat-invitation-info">
                    {props.extraInfos &&
                      props.extraInfos.length &&
                      props.extraInfos.map((text: string, index: number) => (
                        <p key={index}>{`${index + 1}、${text}`}</p>
                      ))}
                  </div>
                </>
              );
            case 1: // 页面可用，已接受邀请
              return <div style={{ color: '#389E0D', fontSize: '20px' }}>你已接受邀请</div>;
            case 4: // 页面不可用，已失效
              return (
                <>
                  <i style={{ color: '#FF1A2E', fontSize: 80 }} className="iconfont">
                    &#xe632;
                  </i>
                  <p style={{ fontSize: '20px' }}>该邀请已失效</p>
                </>
              );
            default:
              return '';
          }
        })()}
      </div>
    );
  };
  ShowModal({
    width: 500,
    bodyStyle: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      height: '504px',
    },
    destroyOnClose: 'destroyOnClose',
    hasFooter: false,
    childrens: [ChatInvitationPreview],
  });
};

// 删除群成员
const DeleteQunMemberItem = (props: any) => {
  const { itemData } = props;
  const onRemoveFromGroup = () => {
    request
      .post('/v1/group/member/remove', {
        group_id: itemData.target.chatId,
        member_list: itemData.fromWxid,
        from_wx_id: itemData.wxId,
        mid: itemData.mid,
      })
      .then(() => {
        setTimeout(
          itemData.getGroupMemberList(
            {
              groupId: itemData.target.chatId,
              start: 0,
              count: 1000,
            },
            false,
          ),
          1500,
        );
      });
  };

  return (
    <Popconfirm
      title={`确认删除${itemData.fromNickname}？`}
      okText="确认"
      cancelText="取消"
      onConfirm={() => onRemoveFromGroup()}
    >
      <div>移除该成员</div>
    </Popconfirm>
  );
};

// 加入黑名单
const AddToBlackItem = (props: any) => {
  const { itemData } = props;
  const addToBlackList = () => {
    request
      .post('/v2/wx_user/black/list', {
        groupId: itemData.target.chatId,
        wxId: itemData.fromWxid,
        mid: itemData.mid,
      })
      .then(() => {
        setTimeout(
          itemData.getGroupMemberList(
            {
              groupId: itemData.target.chatId,
              start: 0,
              count: 1000,
            },
            false,
          ),
          1500,
        );
      });
  };

  return (
    <Popconfirm
      title="加入黑名单后，该用户的好友申请和入群申请将不再自动通过"
      okText="确认"
      cancelText="取消"
      onConfirm={() => addToBlackList()}
    >
      <div>加入黑名单</div>
    </Popconfirm>
  );
};

class Message extends React.Component<any> {
  handleAt = () => {
    const { fromNickname, fromWxid } = this.props;
    this.props.syncMessage({
      rawMsg: `@${fromNickname} `,
      editorValue: ` @[${fromNickname}](${fromWxid}) `,
      newPlainTextValue: ` @${fromNickname} `,
      msgType: 0,
      stamp: new Date().getTime(),
      atList: [{ id: fromWxid, display: `@${fromNickname}`, childIndex: 0 }],
    });
  };

  // 头像右键菜单
  DropdownMenu = () => {
    const {
      target: { isSingle },
      wxId,
      fromWxid,
      currentGroupInfo,
      fromNickname,
    } = this.props;

    const isQunMasterAndNoMe =
      !isSingle &&
      currentGroupInfo &&
      currentGroupInfo.groupMember &&
      currentGroupInfo.groupMember.role > 0 &&
      wxId !== fromWxid;

    if (isQunMasterAndNoMe) {
      return (
        <Menu selectable={false}>
          <Menu.Item key="3">
            <div onClick={this.handleAt}>@{fromNickname}</div>
          </Menu.Item>
          <Menu.Item key="1">
            <DeleteQunMemberItem itemData={this.props} />
          </Menu.Item>
          <Menu.Item key="2">
            <AddToBlackItem itemData={this.props} />
          </Menu.Item>
        </Menu>
      );
    }
    return (
      <Menu selectable={false}>
        <Menu.Item key="3">
          <div onClick={this.handleAt}>@{fromNickname}</div>
        </Menu.Item>
        <Menu.Item key="2">
          <AddToBlackItem itemData={this.props} />
        </Menu.Item>
      </Menu>
    );
  };

  getChatInvitationInfo = (url: any) => {
    request
      .get('/im/v2/group/invitation', {
        wxId: this.props.wxId,
        url,
      })
      .then((res: any) => {
        ChatInvitationPreviewModal(res.data, this.props.wxId, this.props.fromWxid);
      });
  };

  render() {
    const {
      fromAvatar = '',
      wxId,
      msgType,
      fromWxid,
      currentGroupInfo,
      mid,
      target,
      fromNickname,
      createTime,
      status,
      comment,
    } = this.props;
    const size = fromAvatar.indexOf('wework') > -1 ? 100 : 132;
    const miniHeadImage =
      fromAvatar && fromAvatar.substr(-2) === '/0'
        ? `${fromAvatar.substr(0, fromAvatar.length - 1)}${size}`
        : fromAvatar;
    const showMsgId = <div style={{ color: '#eee' }}>[{mid}]</div>;

    // 判断 message 类型; 影响展示位置
    let type = 'other';
    if (fromWxid === wxId) {
      type = 'self'; // right
    } else if (msgType === 10000 || msgType === 10002) {
      type = 'sys'; // center
    } else {
      type = 'other'; // left
    }

    return (
      <div className={`${type} message-item`} key={mid} data-message-id={mid}>
        {msgType < 10000 &&
          (!target.isSingle && wxId !== fromWxid ? (
            <Dropdown overlay={this.DropdownMenu()} trigger={['contextMenu']}>
              <Popover
                key={currentGroupInfo && currentGroupInfo.group && currentGroupInfo.group.groupWxId}
                placement="rightBottom"
                trigger="click"
                content={
                  <UserCard
                    groupId={
                      currentGroupInfo && currentGroupInfo.group && currentGroupInfo.group.groupWxId
                    }
                    memberWxId={fromWxid}
                    wxId={wxId}
                  ></UserCard>
                }
              >
                <img
                  alt=""
                  className="message-avatar"
                  style={{ marginRight: 6 }}
                  src={miniHeadImage || '/defaultHead.jpg'}
                />
              </Popover>
            </Dropdown>
          ) : (
            <img
              alt=""
              className="message-avatar "
              style={{ marginRight: 6 }}
              src={miniHeadImage || '/defaultHead.jpg'}
            />
          ))}

        <div className="message-wrapper">
          <div>
            {msgType < 10000 ? (
              <div
                className={clx('date', {
                  'self-date': type === 'self',
                })}
              >
                <span style={{ color: '#8C8C8C', fontSize: 13 }}>
                  {fromWxid === wxId ? '' : fromNickname || ''}
                </span>
                &nbsp;&nbsp;{createTime ? formatDate(new Date(createTime).getTime()) : ''}
              </div>
            ) : (
              <div
                className={clx('date', {
                  'self-date': type === 'self',
                })}
              >
                {createTime ? formatDate(new Date(createTime).getTime()) : ''}
              </div>
            )}
            <MessageContent
              isMe={this.props.fromWxid === wxId}
              {...this.props}
              type={type}
              getChatInvitationInfo={this.getChatInvitationInfo}
              handleImgUrl={(url: string, mid1: string) => this.props.handleImgUrl(url, mid1)}
            ></MessageContent>
            <div className={`date ${type}`}>
              {(() => {
                if (status === 1) {
                  return '消息已撤回';
                }
                return comment || '';
              })()}
            </div>
            {/* enable_log=true 时添加 id 显示 */}
            {window.location.href.indexOf('enable_log=true') > -1 ? showMsgId : null}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    currentGroupInfo: state.currentGroupInfo,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getGroupMemberList: (data: any, merge: boolean) =>
      dispatch(createGetGroupMemberListAction(data, merge)),
    syncMessage: (param: any) => dispatch(createSyncSendMessage(param)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Message);
