import React from 'react';
import { Tabs, Empty } from 'antd';
import throttle from 'lodash/throttle';
import './index.less';
import NewFriendItem from './NewFriendItem';

const { TabPane } = Tabs;

interface Prop {
  serverTitle: string;
  friendApplyList: any[];
  onClick: (id: any, type: string) => void;
  onIgnoreClick: (id: any, type: string) => void;
}
interface State {
  activeKey: string;
}
class NewFriendApply extends React.Component<Prop, State> {
  constructor(props: Prop) {
    super(props);
    this.state = {
      activeKey: '1',
    };
  }

  filterFriends = () => {
    const { activeKey } = this.state;
    const list = this.props.friendApplyList.filter((friend: any) => {
      if (activeKey === '2' && friend.status === 0) {
        // 待处理
        return true;
      }
      if (activeKey === '3' && friend.status === 3) {
        // 超限额
        return true;
      }
      if (activeKey === '4' && friend.status === 1) {
        // 待处理
        return true;
      }
      if (activeKey === '5' && friend.status === 2) {
        // 已忽略
        return true;
      }
      if (activeKey === '6' && friend.status === 4) {
        // 已过期
        return true;
      }
      if (activeKey === '1') {
        return true;
      }
      return false;
    });
    return list;
  };

  onAgreeClick = throttle((wxId: any, ticket: any, index: number) => {
    const status = this.props.friendApplyList[index] && this.props.friendApplyList[index].status;
    if (status === 0 || status === 3 || status === 2) {
      this.props.onClick(wxId, ticket);
    }
  }, 2000);

  render() {
    const { friendApplyList, serverTitle } = this.props;
    const { activeKey } = this.state;
    return (
      <Tabs
        defaultActiveKey={activeKey}
        onChange={(e: any) => {
          this.setState({
            activeKey: e,
          });
        }}
        tabBarStyle={{ padding: '0 16px', border: 0 }}
      >
        {['全部', '需处理', '超限额', '已通过', '已忽略', '已过期'].map((name, idx) => (
          <TabPane key={`${idx + 1}`} tab={<span style={{ fontSize: '12px' }}>{name}</span>}>
            <div className="friends-layout">
              {friendApplyList.length && this.filterFriends().length ? (
                this.filterFriends().map((item: any, index: number) => (
                  <NewFriendItem
                    key={index}
                    onClick={() => this.onAgreeClick(item.fromWxId, item.ticket, index)}
                    onIgnoreClick={() => {
                      this.props.onIgnoreClick(item.fromWxId, '手动操作');
                    }}
                    serverTitle={serverTitle}
                    userInfo={item}
                  />
                ))
              ) : (
                <Empty
                  className="im-empty-status"
                  image="/noinvite.png"
                  description="没有好友申请"
                />
              )}
            </div>
          </TabPane>
        ))}
      </Tabs>
    );
  }
}

export default NewFriendApply;
