import { put, takeEvery, call, all } from 'redux-saga/effects';
import {
  ACTION_GET_SESSION_LIST,
  ACTION_CREATE_SESSION,
  ACTION_GET_FISSION_SESSION_LIST,
} from '../constant';
import {
  createSetWxSessionListAction,
  createSetCurrentTargetAction,
  setCurrentTabAction,
  setCurrentSessionListFilterCondition,
  createTopSingleSessionAction,
  createGetWxSessionListAction,
  createSetSessionIsEndAction,
  createGetWxFissionSessionListAction,
  createSetCurrentClientAction,
} from '../action';
import { fetchSessionList, fetchCreateSession, fetchFissionSessionList } from '../../http';
import { TARGET_TYPE_SESSION } from '@/store/constant';
import store from '@/store';
import eventBus from '@/ws/event';

export function* getSessionList(action: any) {
  const {
    currentSessionListFilterCondition,
    clients: {
      current: { wxId: currClientWxId },
    },
  } = store.getState();
  try {
    const start = action.param.polling ? 0 : action.param.start;
    const {
      data,
      data: { list },
    } = yield call(fetchSessionList, {
      limit: 30,
      ...action.param,
      start,
      typ: currentSessionListFilterCondition,
    });
    if (Array.isArray(list)) {
      // 针对账号进行会话列表缓存
      localStorage.setItem(`${currClientWxId}-sessionList`, JSON.stringify(list.slice(0, 20)));
      yield put(createSetSessionIsEndAction({ ...data, polling: action.param.polling }));
      // bugfix 筛选时不能走merge逻辑
      if (action.param.polling) {
        yield put(createTopSingleSessionAction(list));
      } else {
        // eslint-disable-next-line
        if (start > 0) {
          yield put(createTopSingleSessionAction(list));
        } else {
          yield put(createSetWxSessionListAction(list));
        }
      }
      // if (currentSessionListFilterCondition > 0 && !action.param.polling) {
      //   yield put(createSetWxSessionListAction(list));
      // } else {
      //   yield put(createTopSingleSessionAction(list));
      // }

      yield () => eventBus.emit('updateSessionList');
    }
  } catch (error) {
    console.error(error);
  }
}

export function* createSession(action: any) {
  const {
    currentSessionListFilterCondition,
    currentSiderTab,
    clients: {
      current: { wxId: currClientWxId },
    },
  } = store.getState();
  try {
    const { data } = yield call(fetchCreateSession, { wxId: currClientWxId, ...action.param });
    const { chatType, id: sessionId } = data;
    // 若当前处于“群聊” 筛选条件下，切要新建一个单聊，则将筛选切换回”全部“
    // 目的是避免列表区-会话目标没有与当前会话对象处于同步显示
    const type = action.param.chatType;
    if (type) {
      // 裂变tab
      if (currentSiderTab[1] !== type) {
        yield put(setCurrentTabAction(`0${type}`));
        yield put(createGetWxFissionSessionListAction(type));
      }
    } else {
      yield put(setCurrentTabAction('1'));
      if (currentSessionListFilterCondition === '3' && chatType === 1) {
        yield put(setCurrentSessionListFilterCondition('0'));
        yield put(
          createGetWxSessionListAction({
            limit: 50,
            typ: currentSessionListFilterCondition,
          }),
        );
      }
    }
    yield put(
      createSetCurrentTargetAction({
        ...data,
        id: sessionId,
        type: TARGET_TYPE_SESSION,
      }),
    );
    // 新建的会话需要移动到顶部
    if (type) {
      // 每个会话对应的wxId不一样，需要重新设置
      const { headImg, nickname, remarkName, statusOnline, wxAlias, wxId } = data.owner;
      yield put(
        createSetCurrentClientAction({
          headImg: headImg || '',
          nickname: nickname || '',
          remarkName: remarkName || '',
          statusOnline,
          wxAlias: wxAlias || '',
          wxId: wxId || '',
          isFission: true,
        }),
      );
    } else {
      yield put(createTopSingleSessionAction([data]));
    }
    yield () => eventBus.emit('updateSessionList');
  } catch (error) {
    console.error(error);
  }
}

export function* getFissionSessionList(action: any) {
  const {
    data: { list },
  } = yield call(fetchFissionSessionList, {
    ...action.param,
  });
  if (Array.isArray(list)) {
    yield put(createSetWxSessionListAction(list));
  }
}

export function* watchGetSessionList() {
  yield takeEvery(ACTION_GET_SESSION_LIST, getSessionList);
}

export function* watchCreateSession() {
  yield takeEvery(ACTION_CREATE_SESSION, createSession);
}

export function* watchGetFissionSessionList() {
  yield takeEvery(ACTION_GET_FISSION_SESSION_LIST, getFissionSessionList);
}

export function* watchSession() {
  yield all([watchCreateSession(), watchGetSessionList(), watchGetFissionSessionList()]);
}
