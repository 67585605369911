import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import defaultStyle from './defaultStyle';
import './index.less';

interface IProps {
  editorValue: string;
  pos: any;
  onChange: (e: any) => any;
  onPaste?: (e: any) => any;
  onClick?: () => any;
  target?: any;
  memberList?: any[];
  placeholder?: string;
}

const MessageSender: React.FC<IProps> = props => {
  // componentDidMount() {
  //   if (this.props.target.chatId === undefined) return;
  //   this.fetchMemberList(this.props.target.chatId);
  // }

  const emitIPaste = (e: any) => {
    const { clipboardData } = e;
    const pasteValue = clipboardData.getData('text/plain');
    if (!pasteValue && props.onPaste) {
      // 如果不是文本, onPaste 发送图片
      props.onPaste(e);
    }
  };

  const emitIKey = (e: any) => {
    if (!e.shiftKey && e.keyCode === 13) {
      // 如果需要ctrl+enter换行，需要单独做，系统默认shift+enter换行，不是ctrl+enter
      e.preventDefault();
      setTimeout(() => {
        if (props.onClick) {
          props.onClick();
        }
      }, 0);
    }
  };

  const onIChange = (event: any, newValue: string, newPlainTextValue: string, mentions: any[]) => {
    props.onChange({
      editorValue: newValue,
      newPlainTextValue,
      mentions,
      stamp: new Date().getTime(),
    });
  };

  const { target, memberList = [], editorValue, onChange, placeholder = '请输入消息' } = props;
  if (target && target.chatType === 2) {
    return (
      <MentionsInput
        className="chat-pannel-input"
        style={defaultStyle}
        value={editorValue}
        onChange={onIChange}
        placeholder={placeholder}
        onBlur={(e: any) => {
          onChange({
            pos: {
              start: e.target.selectionStart,
              end: e.target.selectionEnd,
            },
          });
        }}
        onPaste={emitIPaste}
        onKeyDown={emitIKey}
      >
        <Mention
          trigger="@"
          data={memberList.map((item: any) => ({
            id: item.wxId,
            display: item.nickname,
          }))}
          // renderSuggestion={(item, a, h, i, f) => {
          //   return (
          //     <div>
          //       <img src={} alt="" />
          //       {item.display}
          //     </div>
          //   );
          // }}
          displayTransform={(id: string, display: string) => `@${display}`}
          appendSpaceOnAdd
        />
      </MentionsInput>
    );
  }
  return (
    <MentionsInput
      className="chat-pannel-input"
      style={target ? defaultStyle : { ...defaultStyle, background: '#fff' }}
      value={props.editorValue}
      onChange={onIChange}
      placeholder={placeholder}
      onBlur={(e: any) => {
        onChange({
          pos: {
            start: e.target.selectionStart,
            end: e.target.selectionEnd,
          },
        });
      }}
      onPaste={emitIPaste}
      onKeyDown={emitIKey}
    >
      <Mention trigger="" data={[]} />
    </MentionsInput>
  );
};

export default MessageSender;
