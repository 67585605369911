import React, { PureComponent } from 'react';
import { Row, Col, Card, Modal, Input, message } from 'antd';
import { connect } from 'react-redux';
import { formatStringDisplay } from '@/util';
import { createGetWxSessionListAction } from '@/store/action';
import request from '@/http/request';
import IconFont from '@/components/IconFont';
import { State } from '@/store/types/state';
import AddedOtherWx from './AddedOtherWx';
import '../index.less';

const rowStyle = {
  marginBottom: 16,
};

const labelStyle = {
  color: '#8C8C8C',
};

class UserCustomerInfo extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      remarkName: props.remarkName,
      visible: false,
    };
  }

  static getDerivedStateFromProps(props: any) {
    return {
      remarkName: props.remarkName,
    };
  }

  showModal = () => {
    this.setState((state: any) => {
      return {
        visible: true,
        cachedName: state.remarkName,
      };
    });
  };

  handleOk = () => {
    const { friendId, wxId } = this.props;
    const name = this.state.cachedName;
    if (name === this.state.remarkName) {
      message.warning('备注无修改！');
      return;
    }
    request
      .put(`/im/v2/accounts/${wxId}/friends/${friendId}/remark_name`, {
        name,
      })
      .then(() => {
        this.props.getDetailData();
        this.props.getSessionList();
        this.setState({
          visible: false,
          remarkName: name,
          cachedName: '',
        });
      });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onChangeRemarkName = (e: any) => {
    this.setState({ cachedName: e.target.value });
  };

  render() {
    const { props } = this;
    const { visible, remarkName, cachedName } = this.state;
    return (
      <Card className="im-card im-body-font-small" bordered={false}>
        <Row style={rowStyle}>
          <Col span={6} style={labelStyle}>
            微信昵称
          </Col>
          <Col span={18}>
            <span className="im-title-font-small">{props.nickname || '无'}</span>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={6} style={labelStyle}>
            微信备注
          </Col>
          <Col span={18}>
            <span className="im-title-font-small">{formatStringDisplay(remarkName, 16)} </span>
            <IconFont iconfont="iconbianji" onClick={this.showModal}></IconFont>
            <Modal
              visible={visible}
              title="修改备注"
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <Input value={cachedName} onChange={this.onChangeRemarkName} maxLength={50} />
            </Modal>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={6} style={labelStyle}>
            微信号
          </Col>
          <Col span={18}>
            <span className="im-title-font-small">{props.wxAlias || props.friendId || '无'}</span>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={6} style={labelStyle}>
            地区
          </Col>
          <Col span={18}>
            <span className="im-title-font-small">
              {props.country} {props.province} {props.city}
            </span>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={6} style={labelStyle}>
            来源
          </Col>
          <Col span={18}>
            <span className="im-title-font-small">{props.source || '无'}</span>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col span={6} style={labelStyle}>
            性别
          </Col>
          <Col span={18}>
            <span className="im-title-font-small">{`${props.sex === 1 ? '男' : '女'}`}</span>
          </Col>
        </Row>
        <AddedOtherWx chatId={props.target && props.target.chatId} />
      </Card>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 30 })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCustomerInfo);
