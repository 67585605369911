import React, { Component, Fragment } from 'react';
import { Tabs, Collapse, Button, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import { State, Target } from '@/store/types/state';
import eventBus from '@/ws/event';
import request from '@/http/request';
import {
  createSetCurrentTargetAction,
  createGetWxFriendListAction,
  createGetWxSessionListAction,
} from '@/store/action';
import UserCustomerInfo from '../UserCustomerInfo';
import UserCustomerLabel from '../UserCustomerLabel';
import UserCustomerData from '../UserCustomerData';
import IconFont from '@/components/IconFont';
import '../../index.less';

const { Panel } = Collapse;
const { TabPane } = Tabs;

class FriendPanel extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      detailData: null,
      originData: null,
      customer: '',
    };
  }

  componentDidMount() {
    this.getDetailData();
    this.onListenerReload();
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.target === null ||
      prevProps.target.id !== this.props.target.id ||
      prevProps.target.type !== this.props.target.type
    ) {
      // this.setState({ isDeleteFriend: false, tabIndex: '1' });
      this.getDetailData();
    }
  }

  getDetailData = () => {
    if (this.props.target && this.props.target.type === 'TARGET_TYPE_SESSION') {
      request.get(`/im/v2/sessions/${this.props.target.id}/meta`).then((res: any) => {
        const { data } = res;
        this.setState({
          customer: data.customer,
          detailData: data.friend,
          originData: data,
        });
      });
    }
  };

  onListenerReload = () => {
    eventBus.on('reloadFunctionWindow', () => this.reload());
  };

  reload = () => {
    this.getDetailData();
  };

  deleteFriend = () => {
    const { detailData } = this.state;
    request.delete(`im/v2/accounts/${detailData.wxId}/friends/${detailData.friendId}`).then(() => {
      this.props.getSessionList({ typ: 0 });
      this.props.setCurrentTarget(null);
      this.props.getFriendList();
    });
  };

  render() {
    const { detailData, originData, customer } = this.state;
    return (
      <>
        {detailData === null || originData.chatType === 0 ? null : (
          <Fragment>
            <div className="functionWindow-tabs-header">
              <div>
                <span style={{ marginRight: 10 }} className="im-title-font-large">
                  好友资料
                </span>
                <IconFont
                  style={{ fontSize: '15px' }}
                  iconfont="iconshuaxin"
                  onClick={this.reload}
                ></IconFont>
              </div>
              <Popconfirm
                title={`确认删除好友${detailData.nickname || ''}`}
                okText="确认"
                cancelText="取消"
                onConfirm={this.deleteFriend}
              >
                <Button className="im-btn-default im-btn-icon">
                  <IconFont iconfont="iconshanchu"></IconFont>
                  删除
                </Button>
              </Popconfirm>
            </div>
            <div className="scroll-box" id="scroll-box-6">
              <Tabs
                defaultActiveKey="1"
                onChange={() => {}}
                className="user-info"
                style={{ textAlign: 'center', background: '#E9E9E9' }}
              >
                <TabPane tab="个人信息" key="1" style={{ textAlign: 'left' }}>
                  <UserCustomerInfo {...detailData} getDetailData={this.getDetailData} />
                  <div className="panel-content">
                    <Collapse
                      bordered={false}
                      defaultActiveKey={['1', '2', '3']}
                      className="im-card"
                      expandIconPosition="right"
                    >
                      <Panel header={<span className="im-title-font">客户标签</span>} key="1">
                        <UserCustomerLabel
                          customerWxId={detailData.friendId}
                          wxId={detailData.wxId}
                          friendId={detailData.id}
                        />
                      </Panel>
                      <Panel
                        header={<span className="im-title-font">客户资料</span>}
                        key="2"
                        className="panel-userdata"
                      >
                        <UserCustomerData
                          {...customer}
                          wxId={detailData.friendId}
                          initFn={this.reload}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </TabPane>
                {/* <TabPane tab="订单数据" key="2" style={{ textAlign: 'left' }}>
                  <div className="panel-content-order panel-content">
                    <p className="im-title-font-small im-bold">购物平台账号绑定</p>
                    <ConnectCustomer {...originData} getDetailData={this.getDetailData} />
                    <Collapse
                      bordered={false}
                      defaultActiveKey={[0]}
                      expandIconPosition="right"
                      style={{ marginTop: '20px' }}
                      className="im-collapse-nopadding"
                    >
                      <Panel
                        header={<span className="im-title-font">订单数据</span>}
                        key={0}
                        style={customPanelStyle}
                      >
                        {originData && !originData.orders ? (
                          <p
                            className="im-caption-font-small"
                            style={{ textAlign: 'center', padding: '40px 0' }}
                          >
                            暂无订单数据
                          </p>
                        ) : (
                          originData.orders.map((item: any, index: number) => (
                            <Collapse
                              bordered={false}
                              defaultActiveKey={[0]}
                              expandIconPosition="right"
                              className="im-collapse"
                              key={item + index}
                            >
                              <Panel
                                className="im-collapse-header"
                                header={`订单${index + 1}`}
                                key={index}
                                style={customPanelStyle}
                              >
                                <UserOrderData {...item} />
                              </Panel>
                            </Collapse>
                          ))
                        )}
                      </Panel>
                    </Collapse>
                  </div>
                </TabPane> */}
              </Tabs>
            </div>
          </Fragment>
        )}
      </>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentTarget: (data: Target) => dispatch(createSetCurrentTargetAction(data)),
    getSessionList: (extraData: any = {}) =>
      dispatch(createGetWxSessionListAction({ limit: 30, ...extraData })),
    getFriendList: () => dispatch(createGetWxFriendListAction({})),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FriendPanel);
