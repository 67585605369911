import { WxMessage } from '../types/state';
import { BaseAction } from '../types/action';
import {
  ACTION_SET_MESSAGE_LIST,
  ACTION_MERGE_MESSAGE_LIST,
  ACTION_SYNC_SEND_MESSAGE,
  ACTION_SYNC_DELETE_MESSAGE,
  ACTION_UPDATE_MESSAGE_LIST_STATUS,
} from '../constant/index';

interface Message {
  [propName: number]: WxMessage[];
  messageNextIndex?: string;
  messageIsEnd?: boolean;
  sessionId?: number;
  topicId?: string;
  chatType?: number;
}

const reduceRepeat = (data: any[]) => {
  const obj: any = {};
  return data.reduce((cur: any, next: any) => {
    if (!obj[next.mid]) {
      obj[next.mid] = true;
      cur.push(next);
    }
    return cur;
  }, []);
};

export default (state: Message = {}, action: BaseAction) => {
  const { sessionId, data, isNew } = action;
  // if (Array.isArray(data) && sessionId !== state.sessionId) {
  //   return state; // actointype: ACTION_SET_MESSAGE_LIST
  // }
  switch (action.type) {
    // 设置messalgeList
    case ACTION_SET_MESSAGE_LIST: {
      return {
        messageIsEnd: state.messageIsEnd,
        messageNextIndex: state.messageNextIndex,
        sessionId: state.sessionId,
        [sessionId]: data.filter((item: Message) => item.sessionId === sessionId),
      };
    }

    // messageList列表加载状态更新
    case ACTION_UPDATE_MESSAGE_LIST_STATUS: {
      return {
        ...state,
        ...data,
        sessionId,
      };
    }

    // 新消息合并
    case ACTION_MERGE_MESSAGE_LIST: {
      if (!Array.isArray(data) && state.sessionId !== data.sessionId) return state;

      // 新消息
      if (isNew) {
        const currentMessageList = state[sessionId] ? state[sessionId] : [];
        if (Array.isArray(data)) {
          // 收到新消息
          return {
            ...state,
            [sessionId]: reduceRepeat(currentMessageList.concat(data)),
          };
        }

        // 发消息 & 推送更新
        const index = currentMessageList.findIndex(
          (item: any) => item.mid === data.MockClientMsgId,
        );
        let arr = [...currentMessageList];
        if (index > -1) {
          arr.splice(index, 1, data);
        } else {
          arr = reduceRepeat(currentMessageList.concat(data));
        }
        return {
          ...state,
          [sessionId]: arr,
        };
      }

      // 加载历史消息
      if (Array.isArray(data)) {
        return {
          ...state,
          [sessionId]: reduceRepeat(data.concat(state[sessionId])),
        };
      }

      // 撤回消息
      const index = state[sessionId].findIndex((item: any) => item.mid === data.mid);
      let arr = [...state[sessionId]];
      if (index > -1) {
        arr.splice(index, 1, { ...arr[index], ...data.session });
      } else {
        arr = state[sessionId].concat(data.session);
      }
      return {
        ...state,
        [sessionId]: arr,
      };
      // if (!state[sessionId]) {
      //   let messageList: any[] = [];
      //   if (Array.isArray(data)) {
      //     messageList = data
      //       .filter((item: any) => item.sessionId === sessionId)
      //       .map((item: any) => {
      //         return { ...item.session.lastMsg, clientMsgId: item.clientMsgId };
      //       });
      //   } else {
      //     let mergeData: any;
      //     if (data.session && data.session.lastMsg) {
      //       mergeData = data.session.lastMsg;
      //     } else {
      //       mergeData = data;
      //     }
      //     messageList.push({ ...mergeData, clientMsgId: data.clientMsgId });
      //   }
      //   return {
      //     ...state,
      //     [sessionId]: messageList,
      //   };
      // }

      // 获取redux中对应sessionId的messageList
      // let resData = state[sessionId];
      // // 更新新消息到聊天窗口
      // // 分为：假数据上屏、真数据替换（拼接）、新messageList拼接
      // if (isNew) {
      //   // 为避免出现重复的数据，若在己方发送消息时，对方在同一时间发送了消息，则需要去重处理
      //   // 该情况下，data长度为大于 1 的数组
      //   if (data.length > 1) {
      //     resData = resData.reduce((prevList: any, originItem: any) => {
      //       if (data.find((newItem: any) => newItem.mid === originItem.mid)) {
      //         return prevList;
      //       }
      //       prevList.push(originItem);
      //       return prevList;
      //     }, []);
      //   }

      //   if (isUpdate) {
      //     // 真数据替换（拼接）
      //     resData = resData.map((item: any) => {
      //       if (!item.clientMsgId || !data.clientMsgId || item.clientMsgId !== data.clientMsgId) {
      //         return item;
      //       }
      //       return data.session.lastMsg;
      //     });
      //     return {
      //       ...state,
      //       [sessionId]: resData,
      //     };
      //   }

      //   // 假数据上屏，此时data为对象
      //   // 新messageList拼接，此时data为通过请求messageList返回的对象数组，每个对象的结构与完整的session会话结构中的lastMsg结构一致
      //   let resArr: any[] = [];
      //   if (Array.isArray(data)) {
      //     resArr = data;
      //   } else {
      //     resArr.push({ ...data.session.lastMsg, clientMsgId: data.clientMsgId });
      //   }
      //   return {
      //     ...state,
      //     [sessionId]: resData.concat(resArr),
      //   };
      // }

      // // 处理旧数据,此时data为具体消息对象
      // // 将接受到的数据替换到历史数据中
      // if (isReplace) {
      //   console.log('isReplace');
      //   let tempData: any = resData;
      //   tempData = tempData.map((item: any) => {
      //     return item.mid === data.mid
      //       ? {
      //           ...item,
      //           ...data,
      //         }
      //       : item;
      //   });
      //   return {
      //     ...state,
      //     [sessionId]: tempData,
      //   };
      // }
      // return {
      //   ...state,
      //   [sessionId]: data.concat(resData),
      // };
    }

    case ACTION_SYNC_SEND_MESSAGE: {
      return {
        ...state,
        0: action.data,
      };
    }
    case ACTION_SYNC_DELETE_MESSAGE: {
      const newState = { ...state };
      delete newState[0];
      return newState;
    }
    default:
      return state;
  }
};
