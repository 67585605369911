export { Uint8ArryToString, StringToUint8Array, pbEncode, pbDecode, getUrlPath } from './pb';

export function getParamValue(param: string) {
  let search = window.location.href;
  if (search !== '') {
    const index = search.indexOf('?');
    search = search.slice(index + 1);
    const paramArr = search.split('&');
    const params: any = {};
    paramArr.forEach(item => {
      const itemArr = item.split('=');
      if (itemArr.length > 1) {
        params[itemArr[0]] = itemArr[1]; //eslint-disable-line
      }
    });
    return params[param];
  }
  return '';
}

export function random(n: number, m: number) {
  return Math.floor(Math.random() * (m - n + 1) + n);
}

// 时间处理
// 返回 MM-DD HH:MM:SS
export function formatDate(t: any) {
  const time = new Date(parseInt(`${t}000`.slice(0, 13), 10));
  return `${`0${time.getMonth() + 1}`.slice(-2)}-${`0${time.getDate()}`.slice(
    -2,
  )} ${`0${time.getHours()}`.slice(-2)}:${`0${time.getMinutes()}`.slice(
    -2,
  )}:${`0${time.getSeconds()}`.slice(-2)}`;
}

export function requestSetInterval(callback: any, interval: number) {
  let timer: any;
  let startTime: any = Date.now();
  let endTime: any = startTime;
  const loop: any = () => {
    timer = requestAnimationFrame(loop);
    endTime = Date.now();
    if (endTime - startTime >= interval) {
      startTime = Date.now();
      endTime = startTime;
      callback(timer);
    }
  };
  timer = requestAnimationFrame(loop);
  return timer;
}

export function formatStringDisplay(str: string, len: number) {
  if (typeof str !== 'string') {
    return str;
  }
  if (str.length === 0) {
    return '';
  }
  if (str.length < len) {
    return str;
  }
  return str.slice(0, len).concat('...');
}
