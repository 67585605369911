import React from 'react';
import EmojiText from '@/components/EmojiText';
import Audio from './Component/Audio';
import Img from './Component/Img';
import Video from './Component/Video';
import LinkCard from './Component/LinkCard';

const Message = (props: any) => {
  const { item, keyword, right } = props;
  switch (item.msgType) {
    case 1:
      /* 文本消息 */
      return (
        <>
          <div style={{ textAlign: 'left' }}>{item.atMe === 2 ? '@所有人' : null}</div>
          <EmojiText
            text={
              item.msgContent.content !== undefined
                ? item.msgContent.content.replace(/(^\n*)|(^\r)|(^\r\n)/, '')
                : item.msgContent
            }
            keyword={keyword}
          ></EmojiText>
        </>
      );
    case 3:
    case 4:
      /* 图片消息 */
      return (
        <Img
          {...(item.msgContent.imageUrl ? item.msgContent : { imageUrl: item.msgContent })}
        ></Img>
      );
    case 34:
      /* 语音消息消息 */
      return <Audio {...item.msgContent} right={right}></Audio>;
    case 42:
      /* 个人名片 */
      return '';
    case 43:
      /* 视频 */
      return <Video {...item.msgContent}></Video>;
    // case 44:
    //   /* 表情 */
    //   return '';
    case 49:
      /* 链接 */
      return <LinkCard {...item.msgContent}></LinkCard>;
    case 2000:
      /* 转账 */
      return '[收到一个转账，请在手机微信上查看]';
    case 2001:
      /* 红包 */
      return '[收到一个红包，请在手机微信上查看]';
    case 4901:
      /* 小程序 */
      return '[收到一个小程序，请在手机微信上查看]';
    case 4903:
      /* 小程序 */
      return '[收到一个文件，请在手机微信上查看]';
    default:
      return '';
  }
};

export default Message;
