interface EmojiType {
  reg: RegExp;
  text: string;
  x?: number;
  y?: number;
}
export const list_express: EmojiType[] = [
  { reg: /\[微笑]/g, text: '[微笑]', x: 0, y: 0 },
  { reg: /\[撇嘴]/g, text: '[撇嘴]', x: -48, y: 0 },
  { reg: /\[色]/g, text: '[色]', x: -96, y: 0 },
  { reg: /\[发呆]/g, text: '[发呆]', x: -144, y: 0 },
  { reg: /\[得意]/g, text: '[得意]', x: -192, y: 0 },
  { reg: /\[流泪]/g, text: '[流泪]', x: -240, y: 0 },
  { reg: /\[害羞]/g, text: '[害羞]', x: -288, y: 0 },
  { reg: /\[闭嘴]/g, text: '[闭嘴]', x: -336, y: 0 },
  { reg: /\[睡]/g, text: '[睡]', x: -384, y: 0 },
  { reg: /\[大哭]/g, text: '[大哭]', x: -432, y: 0 },
  { reg: /\[尴尬]/g, text: '[尴尬]', x: -480, y: 0 },
  { reg: /\[发怒]/g, text: '[发怒]', x: -528, y: 0 },
  { reg: /\[调皮]/g, text: '[调皮]', x: -576, y: 0 },
  { reg: /\[呲牙]/g, text: '[呲牙]', x: -624, y: 0 },
  { reg: /\[惊讶]/g, text: '[惊讶]', x: -672, y: 0 },
  { reg: /\[难过]/g, text: '[难过]', x: -720, y: 0 },
  { reg: /\[酷]/g, text: '[酷]', x: -768, y: 0 },
  { reg: /\[囧]/g, text: '[囧]', x: -816, y: 0 },
  { reg: /\[抓狂]/g, text: '[抓狂]', x: -864, y: 0 },
  { reg: /\[吐]/g, text: '[吐]', x: -912, y: 0 },
  { reg: /\[偷笑]/g, text: '[偷笑]', x: -960, y: 0 },
  { reg: /\[愉快]/g, text: '[愉快]', x: -1008, y: 0 },
  { reg: /\[白眼]/g, text: '[白眼]', x: -1056, y: 0 },
  { reg: /\[傲慢]/g, text: '[傲慢]', x: -1104, y: 0 },
  { reg: /\[饥饿]/g, text: '[饥饿]', x: -1152, y: 0 },
  { reg: /\[困]/g, text: '[困]', x: -1200, y: 0 },
  { reg: /\[惊恐]/g, text: '[惊恐]', x: -1248, y: 0 },
  { reg: /\[流汗]/g, text: '[流汗]', x: -1296, y: 0 },
  { reg: /\[憨笑]/g, text: '[憨笑]', x: -1344, y: 0 },
  { reg: /\[悠闲]/g, text: '[悠闲]', x: -1392, y: 0 },
  { reg: /\[奋斗]/g, text: '[奋斗]', x: -1440, y: 0 },
  { reg: /\[咒骂]/g, text: '[咒骂]', x: -1488, y: 0 },
  { reg: /\[疑问]/g, text: '[疑问]', x: -1536, y: 0 },
  { reg: /\[嘘]/g, text: '[嘘]', x: -1584, y: 0 },
  { reg: /\[晕]/g, text: '[晕]', x: -1632, y: 0 },
  { reg: /\[疯了]/g, text: '[疯了]', x: -1680, y: 0 },
  { reg: /\[衰]/g, text: '[衰]', x: -1728, y: 0 },
  { reg: /\[骷髅]/g, text: '[骷髅]', x: -1776, y: 0 },
  { reg: /\[敲打]/g, text: '[敲打]', x: -1824, y: 0 },
  { reg: /\[再见]/g, text: '[再见]', x: -1872, y: 0 },
  { reg: /\[擦汗]/g, text: '[擦汗]', x: -1920, y: 0 },
  { reg: /\[抠鼻]/g, text: '[抠鼻]', x: -1968, y: 0 },
  { reg: /\[鼓掌]/g, text: '[鼓掌]', x: -2016, y: 0 },
  { reg: /\[糗大了]/g, text: '[糗大了]', x: -2064, y: 0 },
  { reg: /\[坏笑]/g, text: '[坏笑]', x: -2112, y: 0 },
  { reg: /\[左哼哼]/g, text: '[左哼哼]', x: -2160, y: 0 },
  { reg: /\[右哼哼]/g, text: '[右哼哼]', x: -2208, y: 0 },
  { reg: /\[哈欠]/g, text: '[哈欠]', x: -2256, y: 0 },
  { reg: /\[鄙视]/g, text: '[鄙视]', x: -2304, y: 0 },
  { reg: /\[委屈]/g, text: '[委屈]', x: -2352, y: 0 },
  { reg: /\[快哭了]/g, text: '[快哭了]', x: -2400, y: 0 },
  { reg: /\[阴险]/g, text: '[阴险]', x: -2448, y: 0 },
  { reg: /\[亲亲]/g, text: '[亲亲]', x: -2496, y: 0 },
  { reg: /\[吓]/g, text: '[吓]', x: -2544, y: 0 },
  { reg: /\[可怜]/g, text: '[可怜]', x: -2592, y: 0 },
  { reg: /\[菜刀]/g, text: '[菜刀]', x: -2640, y: 0 },
  { reg: /\[西瓜]/g, text: '[西瓜]', x: -2688, y: 0 },
  { reg: /\[啤酒]/g, text: '[啤酒]', x: -2736, y: 0 },
  { reg: /\[篮球]/g, text: '[篮球]', x: -2784, y: 0 },
  { reg: /\[乒乓]/g, text: '[乒乓]', x: -2832, y: 0 },
  { reg: /\[咖啡]/g, text: '[咖啡]', x: -2880, y: 0 },
  { reg: /\[饭]/g, text: '[饭]', x: -2928, y: 0 },
  { reg: /\[猪头]/g, text: '[猪头]', x: -2976, y: 0 },
  { reg: /\[玫瑰]/g, text: '[玫瑰]', x: -3024, y: 0 },
  { reg: /\[凋谢]/g, text: '[凋谢]', x: -3072, y: 0 },
  { reg: /\[嘴唇]/g, text: '[嘴唇]', x: -3120, y: 0 },
  { reg: /\[爱心]/g, text: '[爱心]', x: -3168, y: 0 },
  { reg: /\[心碎]/g, text: '[心碎]', x: -3216, y: 0 },
  { reg: /\[蛋糕]/g, text: '[蛋糕]', x: -3264, y: 0 },
  { reg: /\[闪电]/g, text: '[闪电]', x: -3312, y: 0 },
  { reg: /\[炸弹]/g, text: '[炸弹]', x: -3360, y: 0 },
  { reg: /\[刀]/g, text: '[刀]', x: -3408, y: 0 },
  { reg: /\[足球]/g, text: '[足球]', x: -3456, y: 0 },
  { reg: /\[瓢虫]/g, text: '[瓢虫]', x: -3504, y: 0 },
  { reg: /\[便便]/g, text: '[便便]', x: -3552, y: 0 },
  { reg: /\[月亮]/g, text: '[月亮]', x: -3600, y: 0 },
  { reg: /\[太阳]/g, text: '[太阳]', x: -3648, y: 0 },
  { reg: /\[礼物]/g, text: '[礼物]', x: -3696, y: 0 },
  { reg: /\[拥抱]/g, text: '[拥抱]', x: -3744, y: 0 },
  { reg: /\[强]/g, text: '[强]', x: -3792, y: 0 },
  { reg: /\[弱]/g, text: '[弱]', x: -3840, y: 0 },
  { reg: /\[握手]/g, text: '[握手]', x: -3888, y: 0 },
  { reg: /\[胜利]/g, text: '[胜利]', x: -3936, y: 0 },
  { reg: /\[抱拳]/g, text: '[抱拳]', x: -3984, y: 0 },
  { reg: /\[勾引]/g, text: '[勾引]', x: -4032, y: 0 },
  { reg: /\[拳头]/g, text: '[拳头]', x: -4080, y: 0 },
  { reg: /\[差劲]/g, text: '[差劲]', x: -4128, y: 0 },
  { reg: /\[爱你]/g, text: '[爱你]', x: -4176, y: 0 },
  { reg: /\[NO]/g, text: '[NO]', x: -4224, y: 0 },
  { reg: /\[OK]/g, text: '[OK]', x: -4272, y: 0 },
  { reg: /\[爱情]/g, text: '[爱情]', x: -4320, y: 0 },
  { reg: /\[飞吻]/g, text: '[飞吻]', x: -4368, y: 0 },
  { reg: /\[跳跳]/g, text: '[跳跳]', x: -4416, y: 0 },
  { reg: /\[发抖]/g, text: '[发抖]', x: -4464, y: 0 },
  { reg: /\[怄火]/g, text: '[怄火]', x: -4512, y: 0 },
  { reg: /\[转圈]/g, text: '[转圈]', x: -4560, y: 0 },
  { reg: /\[磕头]/g, text: '[磕头]', x: -4608, y: 0 },
  { reg: /\[回头]/g, text: '[回头]', x: -4656, y: 0 },
  { reg: /\[跳绳]/g, text: '[跳绳]', x: -4704, y: 0 },
  { reg: /\[投降]/g, text: '[投降]', x: -4752, y: 0 },
  { reg: /\[激动]/g, text: '[激动]', x: -4800, y: 0 },
  { reg: /\[乱舞]/g, text: '[乱舞]', x: -4848, y: 0 },
  { reg: /\[献吻]/g, text: '[献吻]', x: -4896, y: 0 },
  { reg: /\[左太极]/g, text: '[左太极]', x: -4944, y: 0 },
  { reg: /\[右太极]/g, text: '[右太极]', x: -4992, y: 0 },
  { reg: /\[奸笑]/g, text: '[奸笑]', x: -5040, y: 0 },
  { reg: /\[嘿哈]/g, text: '[嘿哈]', x: -5088, y: 0 },
  { reg: /\[捂脸]/g, text: '[捂脸]', x: -5136, y: 0 },
  { reg: /\[机智]/g, text: '[机智]', x: -5184, y: 0 },
  { reg: /\[茶]/g, text: '[茶]', x: -5232, y: 0 },
  { reg: /\[红包]/g, text: '[红包]', x: -5280, y: 0 },
  { reg: /\[蜡烛]/g, text: '[蜡烛]', x: -5328, y: 0 },
  { reg: /\[耶]/g, text: '[耶]', x: -5376, y: 0 },
  { reg: /\[皱眉]/g, text: '[皱眉]', x: -5424, y: 0 },
  { reg: /\[鸡]/g, text: '[鸡]', x: -5472, y: 0 },
  { reg: /\[福]/g, text: '[福]', x: -5520, y: 0 },
  { reg: /\[發]/g, text: '[發]', x: -5568, y: 0 },
  { reg: /\[小狗]/g, text: '[小狗]' }, // 占位
  { reg: /\[加油]/g, text: '[加油]', x: -5664, y: 0 },
  { reg: /\[一言難盡]/g, text: '[一言難盡]', x: -5712, y: 0 },
  { reg: /\[好的]/g, text: '[好的]', x: -5760, y: 0 },
  { reg: /\[天啊]/g, text: '[天啊]', x: -5808, y: 0 },
  { reg: /\[打脸]/g, text: '[打脸]', x: -5856, y: 0 },
  { reg: /\[社会社会]/g, text: '[社会社会]', x: -5904, y: 0 },
  { reg: /\[汗]/g, text: '[汗]', x: -5952, y: 0 },
  { reg: /\[吃瓜]/g, text: '[吃瓜]', x: -6000, y: 0 },
  { reg: /\[哇]/g, text: '[哇]', x: -6048, y: 0 },
  { reg: /\[旺柴]/g, text: '[旺柴]', x: -6096, y: 0 },
];
