import http from './http';
import request from './request';

export function fetchQrCode(): Promise<any> {
  return http.post('/api/v1/login_qrcode');
}

export function fetchLoginStatus(): Promise<any> {
  return http.post('/api/v1/login_status');
}

export function fetchAccountList(param: any): Promise<any> {
  return http.post('/api/v1/account/list', param);
}

export function fetchSessionList(param: any): Promise<any> {
  return request.get('/bee/v2/account/sessions', {
    ...param,
    count: param.limit,
    typ: param.typ,
  });
}

export function fetchFissionSessionList(param: any): Promise<any> {
  return request.get('/im/v2/account/sessions', {
    type: parseInt(param.type, 10),
    category: 1,
    count: 5000,
  });
}

export function fetchFriendList(param: any): Promise<any> {
  return request.get(`/im/v2/accounts/${param.currClientWxId}/friends`, {
    count: param.count || 30,
    start: param.start || 0,
  });
}

export function fetchGroupList(param: any): Promise<any> {
  return request.get(`/im/v2/accounts/${param.currClientWxId}/groups`, {
    count: param.count || 30,
    start: param.start || 0,
  });
}

export function fetchFriendApplyList(param: any): Promise<any> {
  return http.post('/api/v1/friend_apply/list', param);
}

export function fetchMessageList(param: any): Promise<any> {
  return request
    .get(`/im/v2/sessions/${param.sessionId}/messages`, {
      count: param.limit,
      start: param.start,
      upDownFlag: param.upDownFlag,
    })
    .then((res: any) => {
      return res.data;
    });
}

export function fetchClientList(param: any): Promise<any> {
  return http.post('/api/v1/client/list', param);
}

export function fetchAccountDetail(param: any): Promise<any> {
  return http.post('/api/v1/account/detail', param);
}

// export function fetchCreateSession(param: any): Promise<any> {
//   return http.post('/api/v1/session/create', param);
// }

export function fetchCreateSession(param: any): Promise<any> {
  return request.post('/im/v2/account/sessions', param, undefined, true);
}

export function fetchSendTextMessage(param: any): Promise<any> {
  return request.post(`/im/v2/sessions/${param.sessionId}/send_text`, {
    content: param.content,
    clientMsgId: param.clientMsgId,
    at: param.atList,
  });
}

export function fetchSendImageMessage(param: any): Promise<any> {
  let resultParam = param;
  if (
    resultParam.imgData &&
    Object.prototype.toString.call(resultParam.imgData) === '[object FormData]'
  ) {
    resultParam = param.imgData;
    resultParam.append('clientMsgId', param.clientMsgId);
  }
  return request.post(`/im/v2/sessions/${param.sessionId}/send_image`, resultParam);
}

export function fetchSendLuckMoneyMessage(param: any): Promise<any> {
  return request.post(`/im/v2/sessions/${param.sessionId}/send_redpack`, {
    redPackId: param.redPackId,
    clientMsgId: param.clientMsgId,
    kind: param.kind,
  });
}

export function fetchSendCustomLuckMoneyMessage(param: any): Promise<any> {
  return request.post(`/im/v2/sessions/${param.sessionId}/send_custom_redpack`, {
    amount: param.amount,
    clientMsgId: param.clientMsgId,
    kind: param.kind,
    payDesc: param.payDesc,
    mchId: param.mchId,
    total: param.total,
  });
}

export function fetchSendLinkMessage(param: any): Promise<any> {
  return request.post(`/im/v2/sessions/${param.sessionId}/send_link`, {
    linkUrl: param.linkUrl,
    mediaUrl: param.mediaUrl,
    title: param.title,
    description: param.description,
    clientMsgId: param.clientMsgId,
  });
}

export function fetchSendQuantouMessage(param: any): Promise<any> {
  return request.post(`/im/v2/sessions/${param.sessionId}/finger_guess`, {
    clientMsgId: param.clientMsgId,
  });
}

export function fetchSendShaiziMessage(param: any): Promise<any> {
  return request.post(`/im/v2/sessions/${param.sessionId}/throw_dice`, {
    clientMsgId: param.clientMsgId,
  });
}

export function sendAgreeFriendApply(param: any): Promise<any> {
  return http.post('/api/v1/agree_apply', param);
}

export function sendIgnoreFriendApply(param: any): Promise<any> {
  return http.post('/api/v1/ignore_apply', param);
}

export function uploadImage(param: any, option?: any): Promise<any> {
  return http.post('/api/v1/upload', param, option);
}

export function fetchFriendSearch(param: any): Promise<any> {
  return http.post('/api/v1/friend/search', param);
}

export function fetchRestart(param: any): Promise<any> {
  return http.post('/api/v1/client/restart', param);
}

// export function fetchOnlineClientList(): Promise<any> {
//   return http.post('/api/v1/client/conn/list');
// }

export function fetchOnlineClientList(): Promise<any> {
  return request.get('/im/v2/client/conn/list');
}

export function fetchGroupMemberList(param: any): Promise<any> {
  const data = request.get('/v2/group/members', param);
  return data;
}
export function fetchMoreGroupMemberList(param: any): Promise<any> {
  const data = request.get('/v2/group/members', param);
  return data;
}
export function fetchMediaLibraryList(param: any): Promise<any> {
  const data = request.get('/im/v2/media', param);
  return data;
}
