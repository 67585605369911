import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { setCurrentUserAction } from '@/store/action';
import request from '@/http/request';

import SessionPanel from './container/SessionPanel';
// import Sider from './component/Sider';
import ChatPanel from './container/ChatPanel';
// import Main from './component/Main';
// import ClientList from './component/ClientList';
import AccountPanel from './container/AccountPanel';
// import FunctionWindow from './component/FunctionWindow';
import OperationPanel from './container/OperationPanel';

import './index.less';

class WechatIM extends PureComponent<any, any> {
  componentDidMount() {
    request.get('/v2/current_user', { category: 1 }).then((res: any) => {
      (window as any).user = res.data.username;
      (window as any).level = 40;
      this.props.setCurrentUser({ userId: res.data.id });
    });
  }

  render() {
    return (
      <Layout className="wechat-im">
        <Layout.Sider className="sider" width={264}>
          <AccountPanel />
          <SessionPanel />
        </Layout.Sider>
        <ChatPanel />
        <OperationPanel />
      </Layout>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    target: state.currentTarget,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentUser: (data: any) => dispatch(setCurrentUserAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WechatIM);
