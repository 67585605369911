import React from 'react';
import { Input, List, Spin, Icon, Divider } from 'antd';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { State, Target, WxClient } from '@/store/types/state';
import { createSetCurrentTargetAction } from '@/store/action';
import UserItem from '../UserItem';
import { TARGET_TYPE_FRIEND } from '@/store/constant';
import './index.less';
import request from '@/http/request';

interface Prop {
  currentClient?: WxClient;
  target: Target;
  current?: any;
  // setCurrentTarget: (data: Target) => any;
  onClick: (e: any, type: string, wxId: string) => any;
  openCreateQunMember: () => any;
  isFission: boolean;
  currentSiderTab: string;
}

class Search extends React.Component<Prop> {
  state = {
    loading: false,
    visible: false,
    searchFriend: {
      list: [],
      total: 0,
    },
    searchGroup: {
      list: [],
      total: 0,
    },
    searchFriendShowMore: false,
    searchGroupShowMore: false,
    query: '',
  };

  start: number = 0;

  hasMore: boolean = true;

  scroller: React.RefObject<HTMLObjectElement> = React.createRef();

  // 查看全部/折叠；type：1-好友；type:2-群;
  foldSwitch = (type: number) => {
    const CHECKMOREPARAMS = {
      query: this.state.query,
      start: 0,
      category: 1,
    };
    if (type === 1) {
      this.setState(
        (prevState: any) => {
          return {
            searchFriendShowMore: !prevState.searchFriendShowMore,
          };
        },
        () => {
          this.getFriendsSearch({
            ...CHECKMOREPARAMS,
            count: this.state.searchFriendShowMore ? 9999 : 4,
          });
        },
      );
    } else if (type === 2) {
      this.setState(
        (prevState: any) => {
          return {
            searchGroupShowMore: !prevState.searchGroupShowMore,
          };
        },
        () => {
          this.getGroupsSearch({
            ...CHECKMOREPARAMS,
            count: this.state.searchGroupShowMore ? 9999 : 4,
          });
        },
      );
    }
  };

  foldFn = (type: number) => {};

  handlerInput = (e: any) => {
    e.persist();
    this.setState(
      {
        query: e.target.value,
      },
      () => {
        this.search();
      },
    );
  };

  resetSearch = () => {
    this.start = 0;
    this.hasMore = true;
  };

  handlerFocus = () => {
    setTimeout(() => {
      if (!this.state.visible) {
        this.setState({
          visible: true,
        });
      }
    }, 200);
  };

  handlerBlur = () => {
    setTimeout(() => {
      if (this.state.visible) {
        this.setState({
          visible: false,
          query: '',
          searchFriend: {
            list: [],
            total: 0,
          },
          searchGroup: {
            list: [],
            total: 0,
          },
        });
      }
    }, 200);
  };

  handlerItemClick = (chatId: string, chatType: string, wxId: string) => () => {
    this.props.onClick(chatId, chatType, wxId);
  };

  // 弃用
  handlerScroll = () => {
    const scroller: any = this.scroller.current;
    if (
      scroller.scrollTop !== 0 &&
      scroller.scrollTop >= scroller.scrollHeight - scroller.offsetHeight - 50
    ) {
      this.getMore();
    }
  };

  search = debounce(() => {
    this.resetSearch();
    if (!this.state.query) {
      this.setState({
        searchFriend: {
          list: [],
          total: 0,
        },
        searchGroup: {
          list: [],
          total: 0,
        },
      });
      return;
    }
    this.$_search();
  }, 500);

  getMore = () => {
    const { loading } = this.state;
    if (loading || !this.hasMore) return;
    this.$_search();
  };

  getFriendsSearch = (params: any) => {
    let URL = '/bee/v2/accounts/search';
    if (!this.props.isFission && this.props.current !== null) {
      URL = `/im/v2/accounts/${this.props.current.wxId}/friends`;
    }
    if (this.props.current !== null) {
      return request
        .get(URL, {
          ...params,
          chatType: 1,
        })
        .then((res: any) => {
          this.setState({
            searchFriend: res.data || {},
          });
        });
    }
    return null;
  };

  getGroupsSearch = (params: any) => {
    let URL = '/bee/v2/accounts/search';
    if (!this.props.isFission && this.props.current !== null) {
      URL = `/im/v2/accounts/${this.props.current.wxId}/groups`;
    }
    return request
      .get(URL, {
        ...params,
        chatType: 2,
      })
      .then((res: any) => {
        this.setState({
          searchGroup: res.data || {},
        });
      });
  };

  $_search = () => {
    this.setState({
      loading: true,
    });
    const params = {
      query: this.state.query,
      start: this.start,
      count: 4,
      category: 1,
    };
    Promise.all([this.getFriendsSearch(params), this.getGroupsSearch(params)]).then(() => {
      this.setState({
        loading: false,
      });
    });
  };

  render() {
    const {
      visible,
      query,
      searchFriend: { list: searchFriendList, total: searchFriendCount },
      searchGroup: { list: searchGroupList, total: searchGroupCount },
    } = this.state;
    const { isFission } = this.props;
    return (
      <div className={`search-container ${visible ? 'visible-container' : ''}`}>
        <Input
          className="sider-search"
          onBlur={this.handlerBlur}
          onChange={this.handlerInput}
          onFocus={this.handlerFocus}
          placeholder="输入关键字搜索"
          spellCheck={false}
          value={query}
          allowClear
        />
        {!isFission ? (
          <div onClick={() => this.props.openCreateQunMember()}>
            <img alt="add" src="./add.png" style={{ width: '28px', height: '28px' }} />
          </div>
        ) : (
          ''
        )}
        <div
          className={`scroll-container ${visible ? 'visible' : 'hidden'}`}
          onScroll={this.handlerScroll}
          ref={this.scroller}
        >
          <div className="scroll-container-title">好友</div>
          <Divider
            style={{
              margin: '2px 10px 0 10px',
              width: '90%',
              minWidth: 'unset',
            }}
          />
          <List
            className="scroll-container-list"
            dataSource={searchFriendList || []}
            renderItem={(item: any) => (
              <div style={{ position: 'relative' }}>
                <UserItem
                  avatar={item.avatar}
                  key={item.wxId}
                  name={item.remarkName || item.nickname}
                  onClick={this.handlerItemClick(item.friendId, '1', item.wxId)}
                  wxAlias={item.wxAlias || item.friendId}
                />
                <Divider
                  style={{
                    margin: '0px 16px',
                    width: '90%',
                    minWidth: 'unset',
                    position: 'absolute',
                  }}
                />
              </div>
            )}
            split={false}
          />
          {(searchFriendList && !searchFriendList.length) || !searchFriendList ? (
            <div className="scroll-container-empty-prompt" style={{ color: '#262626' }}>
              <img className="friend-img" alt="friends" src="./friend.png" />
              {query ? '无匹配的好友' : '关键字搜索好友'}
            </div>
          ) : (
            ''
          )}
          {(() => {
            if (searchFriendCount > 4) {
              if (searchFriendList && searchFriendList.length <= 4) {
                return (
                  <div
                    className="scroll-container-loadmore"
                    onMouseDown={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.foldSwitch(1);
                    }}
                  >
                    查看全部（{searchFriendCount}）
                  </div>
                );
              }
              return (
                <div
                  className="scroll-container-loadmore"
                  onMouseDown={e => {
                    this.foldSwitch(1);
                    e.preventDefault();
                  }}
                >
                  收起
                </div>
              );
            }
            return '';
          })()}
          <div className="scroll-container-title">群</div>
          <Divider
            style={{
              margin: '2px 10px 0 10px',
              width: '90%',
              minWidth: 'unset',
            }}
          />
          <List
            className="scroll-container-list"
            dataSource={searchGroupList || []}
            renderItem={(item: any) => {
              return (
                <div style={{ position: 'relative' }}>
                  <UserItem
                    avatar={item.avatar}
                    key={item.roomId}
                    name={item.name}
                    onClick={this.handlerItemClick(item.roomId, '2', item.wxId)}
                  />
                  <Divider
                    style={{
                      margin: '0px 16px',
                      width: '90%',
                      minWidth: 'unset',
                      position: 'absolute',
                    }}
                  />
                </div>
              );
            }}
            split={false}
          />
          {(searchGroupList && !searchGroupList.length) || !searchGroupList ? (
            <div className="scroll-container-empty-prompt" style={{ color: '#262626' }}>
              <img className="group-img" alt="groups" src="./group.png" />
              {query ? '无匹配的群' : '关键字搜索群'}
            </div>
          ) : (
            ''
          )}
          {(() => {
            if (searchGroupCount > 4) {
              if (searchGroupList && searchGroupList.length <= 4) {
                return (
                  <div
                    className="scroll-container-loadmore"
                    onMouseDown={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.foldSwitch(2);
                    }}
                  >
                    查看全部（{searchGroupCount}）
                  </div>
                );
              }
              return (
                <div
                  className="scroll-container-loadmore"
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.foldSwitch(2);
                  }}
                >
                  收起
                </div>
              );
            }
            return '';
          })()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
    current: state.clients.current,
  };
}

// function mapDispatchToProps(dispatch: any) {
//   return {
//     setCurrentTarget: (data: Target) => dispatch(createSetCurrentTargetAction(data)),
//   };
// }

export default connect(
  mapStateToProps,
  // mapDispatchToProps,
)(Search);
