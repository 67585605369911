import { BaseAction } from '../types/action';
import { ACTION_SET_MEDIA_LIBRARY_LIST } from '../constant/index';

export default (state: any = [], action: BaseAction) => {
  switch (action.type) {
    case ACTION_SET_MEDIA_LIBRARY_LIST:
      return action.data;
    default:
      return state;
  }
};
