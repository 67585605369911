import { put, takeEvery, call, all } from 'redux-saga/effects';
import { ACTION_GET_GROUPMEMBER_LIST, ACTION_MERGE_GROUPMEMBER_LIST } from '../constant';
import {
  createGetGroupMemberListAction,
  createSetGroupMemberListAction,
  createMergeGroupMemberListAction,
} from '../action';
import { fetchGroupMemberList } from '../../http';

export function* getGroupMemberList(action: any) {
  try {
    const data = yield call(fetchGroupMemberList, action.param);
    if (Array.isArray(data.data.list)) {
      if (action.merge) {
        yield put(createMergeGroupMemberListAction(data.data));
      } else {
        yield put(createSetGroupMemberListAction(data.data));
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetGroupMemberList() {
  yield takeEvery(ACTION_GET_GROUPMEMBER_LIST, getGroupMemberList);
}

export function* watchOperation() {
  yield all([watchGetGroupMemberList()]);
}
