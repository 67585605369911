import React from 'react';
import zh_CN from 'antd/es/locale/zh_CN';
import { Modal, ConfigProvider } from 'antd';
import { connect } from 'react-redux';
import './index.less';
import MessageSender from '../MessageSender';
import EmojiSuggestions from '../../../../../component/EmojiSuggestions';
import LuckMoneyIcon from './LuckyMoney';
import LuckMoneyIconGroup from './LuckyMoneyGroup';
import { State } from '@/store/types/state';
import { createSyncSendMessage, createSendGamesMessage } from '@/store/action';
import request from '@/http/request';

interface IProps {
  message?: any;
  leftHeader?: any;
  rightHeader?: any;
  bottomBlock?: any;
  target: any;
  // saveStorage?: (val1: any, val2: any) => any;
  sendImage?: (e: any, s?: any) => any;
  sendText?: (e: any, mentions?: any[]) => any;
  sendLuckMoney?: (
    redPack: number,
    price: number,
    kind: number,
    payDesc: string,
    total: number,
    mchId: number,
    event: any,
  ) => void;
  sendLink?: (e: any) => void;
  onRef?: (e: any) => any;
  syncMessage: (data: any) => void;
  sendGamesMessage: (data: any) => void;
  current?: any;
}
interface OwnState {
  senderMessage: any;
  pos: any;
  pasteVisible: boolean;
  pasteSource: any;
  blob: any;
  editorValue: string;
  sessionId?: number;
  newPlainTextValue: string;
  mentions?: any[];
  memberList: any[];
  stamp?: number;
  linkVisible: boolean;
  linkSourceObj: any;
}

class ChatSub extends React.Component<IProps, OwnState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      sessionId: props.target.id || -1,
      editorValue: '',
      senderMessage: '',
      pos: { line: 0, pos: 0 },
      pasteVisible: false,
      pasteSource: '',
      blob: '',
      newPlainTextValue: '',
      mentions: [],
      memberList: [],
      stamp: 0,
      linkVisible: false,
      linkSourceObj: {},
    };
  }

  static getDerivedStateFromProps(props: IProps, state: OwnState) {
    return {
      sessionId: props.target.id,
      editorValue: props.target.id !== state.sessionId ? '' : state.editorValue, // 切换tab时清空输入框值
      newPlainTextValue: props.target.id !== state.sessionId ? '' : state.newPlainTextValue,
    };
  }

  fetchMemberList = () => {
    console.log(this.props.target.chatId);
    if (this.props.target.isSingle || this.props.target.chatType === 1) return;
    request
      .get('/v2/group/members', {
        groupId: this.props.target.chatId,
        start: 0,
        count: 1000,
      })
      .then((res: any) => {
        this.setState({
          memberList: res.data.list,
        });
      });
  };

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    // 获取群成员列表
    if (this.props.target.chatId !== undefined) this.fetchMemberList();
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      this.props.target.chatId !== undefined &&
      this.props.target.chatId !== prevProps.target.chatId
    ) {
      this.fetchMemberList();
    }
    if (
      this.props.message &&
      this.props.message.rawMsg &&
      this.props.message.rawMsg !== '' &&
      this.props.message.stamp !== this.state.stamp
    ) {
      this.props.syncMessage({
        msgType: 1,
        rawMsg: '',
      });
      this.updateText();
    }
  }

  updateText = () => {
    if (!this.props.message) {
      // 切换会话, 清空
      this.setState({ newPlainTextValue: '', editorValue: '' });
    } else {
      const { rawMsg, msgType, atList = [], stamp } = this.props.message;
      const { newPlainTextValue, editorValue, mentions = [] } = this.state;
      if (!msgType) {
        // 判断是不是文本信息 msgType = 0: 文字, 1: 图片, 2: 链接
        if (msgType === 0 && atList.length > 0) {
          // @人
          const param: any = {};
          const plainText: any = ''.concat(
            newPlainTextValue,
            this.props.message.newPlainTextValue || rawMsg,
          );
          param.stamp = stamp;
          param.newPlainTextValue = plainText;
          param.editorValue = ''.concat(editorValue, this.props.message.editorValue || rawMsg);
          param.mentions = mentions.concat(atList);
          param.pos = {
            start: plainText.length,
            end: plainText.length,
          };
          this.handleChange(param);
        } else {
          // 快捷回复, 收藏
          this.setState({ newPlainTextValue: rawMsg, editorValue: rawMsg });
        }
      } else if (msgType === 1) {
        // 如果是图片 弹窗显示
        this.setState(
          {
            pasteVisible: true,
            pasteSource: rawMsg,
            blob: '',
          },
          () => {
            this.props.syncMessage({
              msgType: 1,
              stamp: 0,
              mentions: [],
            });
          },
        );
      } else if (msgType === 2) {
        this.setState({
          linkVisible: true,
          linkSourceObj: JSON.parse(rawMsg),
        });
      }
    }
  };

  handlerSendBtnClick = () => {
    const { newPlainTextValue, mentions } = this.state;
    if (!newPlainTextValue) return;
    if (this.props.sendText) {
      this.props.sendText(newPlainTextValue, mentions);
    }
    this.setState({
      editorValue: '',
      newPlainTextValue: '',
    });
  };

  handlerPaste = (e: any) => {
    const { clipboardData } = e;
    if (clipboardData.items) {
      const { items } = clipboardData;
      let blob: any = null;
      for (let i = 0, len = items.length; i < len; i += 1) {
        if (items[i].type.indexOf('image') !== -1) {
          blob = items[i].getAsFile();
        }
      }
      if (!blob) return;
      const URLObj = window.URL;
      const source = URLObj && URLObj.createObjectURL(blob);

      this.setState({
        pasteVisible: true,
        pasteSource: source,
        blob,
      });
    }
  };

  handleChange = (e: any) => {
    // if (this.props.saveStorage) {
    //   this.props.saveStorage(e.target.value, e.target.senderValue);
    // }
    const s = this.state;
    this.setState({
      ...s,
      ...e,
    });
  };

  handlerImgTextChange = (value: string) => {
    const { newPlainTextValue, pos } = this.state;
    const newString =
      newPlainTextValue.substring(0, pos.start) +
      value +
      newPlainTextValue.substring(pos.end, newPlainTextValue.length);
    this.setState({
      editorValue: newString,
      newPlainTextValue: newString,
      pos: {
        start: pos.start + value.length,
        end: pos.start + value.length,
      },
    });
  };

  handleCancelPaste = () => {
    this.setState({
      pasteVisible: false,
    });
  };

  handleOkPaste = () => {
    if (this.props.sendImage) {
      this.props.sendImage(this.state.blob, this.state.pasteSource);
    }
    this.handleCancelPaste();
  };

  handleCancelLink = () => {
    this.setState({
      linkVisible: false,
    });
  };

  handleOkSendLink = () => {
    if (this.props.sendLink) {
      this.props.sendLink(this.state.linkSourceObj);
    }
    this.handleCancelLink();
  };

  render() {
    const {
      editorValue,
      pos,
      pasteVisible,
      pasteSource,
      memberList,
      linkVisible,
      linkSourceObj,
    } = this.state;

    const { target, syncMessage, current, leftHeader, rightHeader, bottomBlock } = this.props;

    const luckMoney =
      target.chatType === 1 ? (
        <LuckMoneyIcon
          onClick={(id, price, kind, payDesc, mchId, confirmEvent) => {
            if (this.props.sendLuckMoney) {
              this.props.sendLuckMoney(id, price, kind, payDesc, 1, mchId, confirmEvent);
            }
          }}
        />
      ) : (
        <LuckMoneyIconGroup
          onClick={(id, price, kind, payDesc, total, mchId, confirmEvent) => {
            if (this.props.sendLuckMoney) {
              this.props.sendLuckMoney(id, price, kind, payDesc, total, mchId, confirmEvent);
            }
          }}
        />
      );
    return (
      <div className="chat-pannel">
        <div className="chat-pannel-header">
          <div className="chat-panel-header-left">
            <EmojiSuggestions
              onSelectChange={this.handlerImgTextChange}
              syncMessage={syncMessage}
              sendGamesMessage={(data: any) => {
                this.props.sendGamesMessage({ ...data, sessionId: target.id });
              }}
              showEmogticon
            />
            {leftHeader}
            {(window as any).level === 40 && current && current.isFission ? '' : luckMoney}
          </div>
          <div className="chat-panel-header-right">{rightHeader}</div>
        </div>
        <div className="chat-pannel-content">
          <MessageSender
            pos={pos}
            target={target}
            editorValue={editorValue}
            onChange={this.handleChange}
            onClick={this.handlerSendBtnClick}
            onPaste={this.handlerPaste}
            memberList={memberList}
          ></MessageSender>
        </div>
        {bottomBlock}
        <ConfigProvider locale={zh_CN}>
          <Modal
            width={760}
            style={{ textAlign: 'center' }}
            centered
            title={<h3 className="send-title">发送图片</h3>}
            visible={pasteVisible}
            onCancel={this.handleCancelPaste}
            onOk={this.handleOkPaste}
          >
            <img alt="发送图片" src={pasteSource} className="see-img-modal" />
          </Modal>
          <Modal
            width={450}
            style={{ textAlign: 'center' }}
            centered
            title={<h3 className="send-title">发送网页</h3>}
            visible={linkVisible}
            onCancel={this.handleCancelLink}
            onOk={this.handleOkSendLink}
          >
            <div className="share-card" style={{ border: '1px solid #BFBFBF', margin: '0 64px' }}>
              <div className="share-title">{linkSourceObj.title}</div>
              <div className="share-content">
                <div className="share-text">{linkSourceObj.description}</div>
                <img alt="图片" className="share-img" src={linkSourceObj.mediaUrl} />
              </div>
            </div>
          </Modal>
        </ConfigProvider>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    message: state.messageMap[0],
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    syncMessage: (param: any) => dispatch(createSyncSendMessage(param)),
    sendGamesMessage: (param: any) => dispatch(createSendGamesMessage(param)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatSub);
