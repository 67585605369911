import { BaseAction } from '../types/action';
import { ACTION_SET_MEDIA_LIBRARY_LIST, ACTION_GET_MEDIA_LIBRARY_LIST } from '../constant';

export function createGetMediaLibraryListAction(param: any): BaseAction {
  return {
    type: ACTION_GET_MEDIA_LIBRARY_LIST,
    param,
  };
}

export function createSetMediaLibraryListAction(data: string): BaseAction {
  return {
    type: ACTION_SET_MEDIA_LIBRARY_LIST,
    data,
  };
}
