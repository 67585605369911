import { WxClient, Clients } from '../types/state';
import { BaseAction } from '../types/action';
import {
  ACTION_SET_CURRENT_USER,
  ACTION_SET_CLIENT_LIST,
  ACTION_SET_SYNC_CLIENT,
  ACTION_UPDATE_CURR_CLIENT_INFO,
} from '../constant/index';

export default (state: Clients = { list: [], current: null }, action: BaseAction) => {
  switch (action.type) {
    // 暂存userId 在 client
    case ACTION_SET_CURRENT_USER:
      return { ...state, currentUserId: action.data.userId };
    case ACTION_SET_CLIENT_LIST:
      return {
        ...state,
        list: action.data,
      };
    case ACTION_SET_SYNC_CLIENT:
      return {
        ...state,
        current: action.data,
      };
    case ACTION_UPDATE_CURR_CLIENT_INFO: {
      const reList: any[] = [];
      state.list.forEach(client => {
        if (action.data && client.wxId === action.data.wxId) {
          reList.push({
            ...client,
            ...action.data,
          });
        } else {
          reList.push(client);
        }
      });
      return {
        ...state,
        list: reList,
        current: action.data,
      };
    }
    default:
      return state;
  }
};
