import React, { Component } from 'react';
import { Tag, Select, Icon } from 'antd';
import request from '@/http/request';
import '../index.less';

const { Option } = Select;

interface LabelsProps {
  customerWxId: any;
  wxId: any;
  friendId: number;
}

interface LabelsState {
  value: any;
  labels: any[];
  customerLabels: any[];
  filterLabels: any[];
  warning: boolean;
}

class UserCustomerLabel extends Component<LabelsProps, LabelsState> {
  constructor(props: LabelsProps) {
    super(props);
    this.state = {
      value: '',
      labels: [
        {
          id: '',
          name: '',
        },
      ],
      customerLabels: [],
      filterLabels: [],
      warning: false,
    };
  }

  timeout: any;

  currentValue: string = '';

  componentDidMount() {
    this.getNormalLabels();
    this.getCustomerLabels();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps: LabelsProps, prevState: LabelsState) {
    if (this.props.friendId !== prevProps.friendId) {
      this.getCustomerLabels();
    }
  }

  getNormalLabels = () => {
    request.get('v2/label/common').then((res: any) => {
      this.setState({
        labels: res.data,
      });
    });
  };

  getCustomerLabels = () => {
    if (!this.props.customerWxId) return;
    request.get(`v2/customer/${this.props.customerWxId}/label`).then((res: any) => {
      this.setState({
        customerLabels: res.data,
      });
    });
  };

  deleteCustomerLabel = (item: any) => {
    if (!this.props.customerWxId) return;
    request
      .delete(`v2/customer/${this.props.customerWxId}/label/${item.id}`, {}, true)
      .then((res: any) => {
        this.getCustomerLabels();
        if (!res.data.userCount) {
          this.getNormalLabels();
        }
      });
  };

  addCustomerLabel = (item: any) => {
    if (!this.props.customerWxId) return;
    request
      .post(
        `v2/customer/${this.props.customerWxId}/label`,
        {
          labelId: item.id,
          wxId: this.props.wxId,
        },
        undefined,
        true,
      )
      .then((res: any) => {
        this.getCustomerLabels();
      });
  };

  handleSearch = (value: string) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.currentValue = value;
    this.timeout = setTimeout(() => {
      request.get('v2/label/search', { query: value }).then((res: any) => {
        if (this.currentValue === value) {
          const { data } = res;
          if (!data.filter((item: any) => item.name === value).length && value) {
            data.unshift({
              id: 0,
              name: value,
            });
          }
          this.setState({
            filterLabels: data,
            value,
          });
        }
      });
    }, 300);
  };

  handleChange = (val: any) => {
    const { filterLabels } = this.state;
    const item = filterLabels[val];
    if (!item) return;
    if (item && item.name.length > 18) {
      this.setState(
        {
          warning: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              filterLabels: [],
              value: '',
              warning: false,
            });
          }, 1200);
        },
      );
      return;
    }
    this.setState({
      warning: false,
      filterLabels: [],
      value: '',
    });
    if (item.id === 0) {
      this.currentValue = '';
      request.post('v2/label', { name: item.name }).then((res: any) => {
        this.getNormalLabels();
        this.addCustomerLabel(res.data);
      });
    } else {
      this.addCustomerLabel(item);
    }
  };

  handleInputKeyDown = (e: any) => {
    if (e.keyCode === 13 && this.currentValue) {
      this.handleChange(0);
    }
  };

  render() {
    const { warning, value, filterLabels } = this.state;
    const options = filterLabels.map((item, index) => <Option key={index}>{item.name}</Option>);
    return (
      <div>
        <Select
          showSearch
          value={value}
          placeholder="输入标签"
          style={{ width: '100%' }}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          notFoundContent={null}
          allowClear
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          // onInputKeyDown={this.handleInputKeyDown}
        >
          {options}
        </Select>
        {warning ? (
          <p style={{ color: '#F52222', margin: '8px 0 8px 16px', fontSize: '12px' }}>
            标签最多支持18个字
          </p>
        ) : (
          ''
        )}
        {this.state.customerLabels.map((item, index) => (
          <div style={{ margin: '15px 0 0 0', display: 'inline-block' }} key={item.id}>
            <Tag
              closable
              onClose={() => this.deleteCustomerLabel(item)}
              key={index}
              className="im-tag-default-closable"
            >
              {item.name}
            </Tag>
          </div>
        ))}
        <div style={{ margin: '23px 0 0' }}>
          <span className="im-title-font-small">常用标签</span>
          <Icon
            type="reload"
            onClick={this.getNormalLabels}
            className="im-title-font-small"
            style={{ padding: '0 5px' }}
          />
        </div>
        {this.state.labels.map((item, index) => (
          <div style={{ margin: '15px 0 0 0', display: 'inline-block' }} key={item.id}>
            <Tag onClick={() => this.addCustomerLabel(item)} key={index} className="im-tag-default">
              {item.name}
            </Tag>
          </div>
        ))}
      </div>
    );
  }
}

export default UserCustomerLabel;
