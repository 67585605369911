import React from 'react';
import { Button, Icon } from 'antd';
import './index.less';

interface Prop {
  userInfo: {
    wxId: string;
    wxAlias: string;
    nickname: string;
    remarkName: string;
    avatar: string;
    sex: number;
    country: string;
    province: string;
    city: string;
    labelId: string;
    friendId: string;
    source: string;
  };
  onClick: (wxId: string) => any;
}

function UserDetail(props: Prop) {
  const {
    wxId,
    nickname,
    remarkName,
    avatar,
    sex,
    country,
    province,
    city,
    wxAlias,
    friendId,
    source,
  } = props.userInfo;
  const btnClick = () => props.onClick(friendId);
  return (
    <div className="user-detail">
      <div className="user-detail-baseinfo">
        <div className="user-detail-baseinfo-nickname">
          <span>
            {remarkName || nickname}
            {sex === 1 ? (
              <i style={{ color: '#45b6ef', fontSize: 20, marginLeft: 5 }} className="iconfont">
                &#xe636;
              </i>
            ) : (
              <i style={{ color: '#f37e7d', fontSize: 20, marginLeft: 5 }} className="iconfont">
                &#xe63a;
              </i>
            )}
          </span>
          <span className="user-detail-baseinfo-wxid">微信号：{wxAlias || friendId || '无'}</span>
        </div>
        <img
          className="user-detail-baseinfo-avarar"
          src={avatar || '/defaultHead.jpg'}
          alt="head"
        />
      </div>
      <div className="user-detail-more">
        <span>
          <strong>备注名</strong>
          <p>{remarkName}</p>
        </span>
        <span>
          <strong>地 区</strong>
          <p>{`${country} ${province} ${city}`}</p>
        </span>
        <span>
          <strong>来 源</strong>
          <p>{source}</p>
        </span>
      </div>
      <div className="user-detail-send" onClick={btnClick}>
        发消息
      </div>
    </div>
  );
}

export default UserDetail;
