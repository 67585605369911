/* *
 * 搜索结果页面，分页展示，可以查看上下文
 * */

import React, { PureComponent } from 'react';
import { Pagination, Empty } from 'antd';
import request from '@/http/request';
import MessageItem from '@/components/MessageItem';
import SearchBar from './SearchBar';

export default class SearchRecord extends PureComponent<any, any> {
  static defaultProps = {
    pages: 1,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      list: [],
      query: {
        ...this.props.query,
        queryType: 0,
      },
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.page !== this.props.page) {
      this.fetchData();
    }
  }

  fetchData = () => {
    request
      .get(`/im/v2/sessions/${this.props.sessionId}/message/query`, {
        ...this.state.query,
        page: this.props.page - 1,
        perpage: 10,
      })
      .then((res: any) => {
        this.setState({
          ...res.data,
        });
      });
  };

  onSearch = () => {
    this.props.onPageChange(1);
    if (this.props.page === 1) {
      this.fetchData();
    }
  };

  reset = () => {
    this.setState({
      query: {},
    });
  };

  onTimerChange = (startTime: string, endTime: string) => {
    this.setState((perState: any) => {
      const state = perState;
      state.query.startTime = endTime;
      state.query.endTime = startTime;
      return state;
    });
  };

  onSearchChange = (query: any) => {
    this.setState({
      query,
    });
  };

  render() {
    const { list, query, total } = this.state;
    const { page } = this.props;
    return (
      <div>
        <SearchBar
          onSearch={this.onSearch}
          query={query}
          onChange={this.onSearchChange}
          hasReset
        ></SearchBar>
        {list ? (
          <div className="record-list">
            <div className="scroll-box">
              {list &&
                list.map((item: any, index: number) => (
                  <MessageItem
                    hover={
                      <a
                        style={{ color: '#389E0D', fontSize: '12px' }}
                        onClick={() => this.props.onClick(item, query.q)}
                      >
                        查看上下文
                      </a>
                    }
                    item={item}
                    key={item.mid + index}
                    keyword={query.q}
                  ></MessageItem>
                ))}
            </div>
            <Pagination
              size="small"
              current={page}
              total={total}
              onChange={this.props.onPageChange}
              style={{ textAlign: 'right', margin: '10px 0' }}
            />
          </div>
        ) : (
          <Empty description="没有相关消息"></Empty>
        )}
      </div>
    );
  }
}
