import { put, takeEvery, call } from 'redux-saga/effects';
import { ACTION_GET_FRIEND_LIST } from '../constant';
import { fetchFriendList } from '../../http';
import { createSetWxFriendListAction, createMergeFriendListAction } from '../action';
import store from '@/store';
import eventBus from '@/ws/event';

export function* getFriendList(action: any) {
  const {
    clients: {
      current: { wxId: currClientWxId },
    },
  } = store.getState();
  try {
    const fetchFriendListParam = { ...{ currClientWxId }, ...action.param };
    const { data } = yield call(fetchFriendList, fetchFriendListParam);
    // console.log('》》》好友列表数据《《《')
    // console.table(data)
    eventBus.emit('updateRequestFriendListParam', {
      start: data.nextIndex,
      isEnd: data.isEnd,
    });
    // 判断是滚动加载请求的数据还是重新请求的list数据
    // 若请求参数isScrollLoading存在且为true，则表示该次请求 不是 当前好友列表的第一页请求，为下拉加载的请求，需要在原有数组上进行拼接
    // 若请求参数isScrollLoading不存在或为false，则表示该次请求 是 当前好友列表的第一页请求，重新设置当前好友列表的数据
    if (Array.isArray(data.list)) {
      if (action.param && action.param.isScrollLoading) {
        yield put(createMergeFriendListAction(data.list));
      } else {
        eventBus.emit('resetRequestFriendListParam');
        yield put(createSetWxFriendListAction(data.list));
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetFriendList() {
  yield takeEvery(ACTION_GET_FRIEND_LIST, getFriendList);
}
