import React, { useState } from 'react';
import { Card, Row, Col, Modal } from 'antd';
import moment from 'moment';

const rowStyle = {
  marginBottom: 16,
};

const invalidCountContent = (invalidCountDetail: any[]) => {
  return (
    <>
      <Row style={{ fontWeight: 'bold', marginBottom: '24px' }}>
        <Col span={5}>昵称</Col>
        <Col span={5}>进群时间</Col>
        <Col span={6}>无效原因</Col>
        <Col span={8}>历史进群记录</Col>
      </Row>
      {invalidCountDetail.length
        ? invalidCountDetail.map((item: any, index: number) => (
            <Row key={index} style={{ margin: '20px 0' }}>
              <Col span={5}>{item.nickname}</Col>
              <Col span={5}>{moment(item.createAt).format('YYYY-MM-DD HH:mm')}</Col>
              <Col span={6}>{item.invalidReason}</Col>
              <Col span={8}>
                {item.joinTime ? <div>进群时间：{item.joinTime}</div> : ''}
                {item.quitTime ? <div>退群时间：{item.quitTime}</div> : ''}
              </Col>
            </Row>
          ))
        : ''}
    </>
  );
};

const grossCountÇontent = (grossCountDetail: any) => {
  return (
    <>
      <Row style={{ fontWeight: 'bold', marginBottom: '24px' }}>
        <Col span={7}>昵称</Col>
        <Col span={10}>进群时间</Col>
        <Col span={7}>状态</Col>
      </Row>
      {grossCountDetail.length
        ? grossCountDetail.map((item: any, index: number) => (
            <Row key={index} style={{ margin: '20px 0' }}>
              <Col span={7}>{item.nickname}</Col>
              <Col span={10}>{item.joinTime}</Col>
              <Col span={7}>{item.desc}</Col>
            </Row>
          ))
        : ''}
    </>
  );
};

const exchangedCountContent = (exchangeRecords: any) => {
  return (
    <>
      <Row style={{ fontWeight: 'bold', marginBottom: '24px' }}>
        <Col span={4}>兑奖编号</Col>
        <Col span={4}>奖品名称</Col>
        <Col span={3}>消耗人数</Col>
        <Col span={6}>兑奖时间</Col>
        <Col span={7}>自动兑换状态</Col>
      </Row>
      {exchangeRecords.length
        ? exchangeRecords.map((item: any, index: number) => (
            <Row key={index} style={{ margin: '20px 0' }}>
              <Col span={4}>{item.exchangeCode}</Col>
              <Col span={4}>{item.prizeName}</Col>
              <Col span={3}>{item.costPoints}</Col>
              <Col span={6}>{moment(item.createAt).format('YYYY-MM-DD HH:mm')}</Col>
              <Col span={7}>
                {item.status ? '人工审核' : '兑奖成功'}
                {`${item.remark ? `(${item.remark})` : ''}`}
              </Col>
            </Row>
          ))
        : ''}
    </>
  );
};

const FissionInfo: React.FC<Readonly<any>> = props => {
  const [visible, setVisible] = useState(0);
  const {
    chatType,
    activity: { startTime, endTime, expireTime },
  } = props;
  const {
    groupName,
    netCount,
    invalidCount,
    invalidCountDetail,
    exchangedCount,
    exchangeRecords,
    exchangeableCount,
    grossCount,
    grossCountDetail,
    originalMemberCount,
    fissionCount,
    introductionCount,
    totalInviter,
    oldInviter,
    newInviter,
    redPackCost,
    prizeCost,
    CPA,
    isNewUser,
  } = props.invitationInfo;
  console.log(props);
  return (
    <div>
      <div className="scroll-box" id="scroll-box-5">
        <Card
          className="im-card im-body-font-small"
          bordered={false}
          bodyStyle={{ padding: '0 12px' }}
        >
          {chatType === 1 ? (
            <Row style={rowStyle}>
              <Col span={6}>来自群：</Col>
              <Col span={18}>
                <span className="im-title-font-small">{groupName}</span>
              </Col>
            </Row>
          ) : (
            ''
          )}
          <Row style={rowStyle}>
            <Col span={6}>活动时间：</Col>
            <Col span={18}>
              <span className="im-title-font-small">
                {moment(startTime).format('YYYY-MM-DD HH:mm')}&nbsp;~&nbsp;
                {moment(endTime).format('YYYY-MM-DD HH:mm')}
              </span>
            </Col>
          </Row>
          <Row style={rowStyle}>
            <Col span={10}>兑奖截止时间：</Col>
            <Col span={14}>
              <span className="im-title-font-small">
                {moment(expireTime).format('YYYY-MM-DD HH:mm')}
              </span>
            </Col>
          </Row>
          {chatType === 1 ? (
            <Row style={{ ...rowStyle, marginBottom: '40px' }}>
              <Col span={6}>用户标记：</Col>
              <Col span={18}>
                <span className="im-title-font-small">{isNewUser ? '新用户' : '老用户'}</span>
              </Col>
            </Row>
          ) : (
            ''
          )}
          {chatType === 1 ? (
            <>
              <Row style={rowStyle}>
                <Col span={10}>无效邀请：</Col>
                <Col span={10}>
                  <span className="im-title-font-small">{invalidCount}人</span>
                </Col>
                <Col span={4}>
                  <a onClick={() => setVisible(1)}>详情</a>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={10}>毛增有效邀请：</Col>
                <Col span={10}>
                  <span className="im-title-font-small">{grossCount}人</span>
                </Col>
                <Col span={4}>
                  <a onClick={() => setVisible(2)}>详情</a>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={10}>净增有效邀请：</Col>
                <Col span={14}>
                  <span className="im-title-font-small">{netCount}人</span>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={10}>被占用有效邀请：</Col>
                <Col span={10}>
                  <span className="im-title-font-small">{exchangedCount}人</span>
                </Col>
                <Col span={4}>
                  <a onClick={() => setVisible(3)}>详情</a>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={10}>可用有效邀请：</Col>
                <Col span={14}>
                  <span className="im-title-font-small">{exchangeableCount}人</span>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row style={rowStyle}>
                <Col span={9}>初始群人数：</Col>
                <Col span={15}>
                  <span className="im-title-font-small">{originalMemberCount}</span>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={9}>裂变人数：</Col>
                <Col span={15}>
                  <span className="im-title-font-small">{`${fissionCount.net}（净增），`}</span>
                  <span className="im-title-font-small">{`${fissionCount.gross}（毛增）`}</span>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={9}>引流人数：</Col>
                <Col span={15}>
                  <span className="im-title-font-small">{`${introductionCount.net}（净增），`}</span>
                  <span className="im-title-font-small">{`${introductionCount.gross}（毛增）`}</span>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={9}>总参与情况：</Col>
                <Col span={15}>
                  <span className="im-title-font-small">{`${totalInviter.count}（发起邀请），`}</span>
                  <span className="im-title-font-small">{`${(totalInviter.rate * 100).toFixed(
                    1,
                  )}%（参与率）`}</span>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={9}>老用户参与情况：</Col>
                <Col span={15}>
                  <span className="im-title-font-small">{`${oldInviter.count}（发起邀请），`}</span>
                  <span className="im-title-font-small">{`${(oldInviter.rate * 100).toFixed(
                    1,
                  )}%（参与率）`}</span>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={9}>新用户参与情况：</Col>
                <Col span={15}>
                  <span className="im-title-font-small">{`${newInviter.count}（发起邀请），`}</span>
                  <span className="im-title-font-small">{`${(newInviter.rate * 100).toFixed(
                    1,
                  )}%（参与率）`}</span>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={9}>个人红包支出：</Col>
                <Col span={15}>
                  <span className="im-title-font-small">{redPackCost}</span>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={9}>兑奖支出：</Col>
                <Col span={15}>
                  <span className="im-title-font-small">{prizeCost}</span>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col span={9}>CPA：</Col>
                <Col span={15}>
                  <span className="im-title-font-small">{CPA}</span>
                </Col>
              </Row>
            </>
          )}
        </Card>
      </div>
      <Modal
        visible={!!visible}
        onCancel={() => setVisible(0)}
        footer={null}
        width={visible === 2 ? 540 : 866}
        style={{ textAlign: 'center' }}
        centered
        title={
          <h3 className="send-title">
            {(() => {
              switch (visible) {
                case 1:
                  return '无效邀请详情';
                case 2:
                  return '毛增有效邀请详情';
                case 3:
                  return '兑奖邀请详情';
                default:
                  return '';
              }
            })()}
          </h3>
        }
      >
        <div style={{ maxHeight: '630px', overflow: 'auto' }}>
          {(() => {
            switch (visible) {
              case 1:
                return invalidCountContent(invalidCountDetail);
              case 2:
                return grossCountÇontent(grossCountDetail);
              case 3:
                return exchangedCountContent(exchangeRecords);
              default:
                return '';
            }
          })()}
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(FissionInfo);
