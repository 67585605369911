import React, { Component, CSSProperties } from 'react';
import moment from 'moment';
import { Icon, Menu, Dropdown } from 'antd';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import clx from 'classnames';
import { State } from '@/store/types/state';
import { createGetMediaLibraryListAction } from '@/store/action';
import request from '@/http/request';
import ShowModal from '@/components/ShowModal';
import { list_express } from '../../../../../component/EmojiSuggestions/express';
import ShareMessageItem from './ShareMessageItem';
import { computedAudioMessageLength, secondToDate } from './utils';

import MsgType from '../msgType';

const imgStr =
  "<img style='width: 18px; padding: 0 0 4px 0;' src=http://pic0.duoduopangyu.net//emoji/Expression_";

function replaceExpressTag(selectContent: any) {
  let content = selectContent;
  content = content.replace(/"http:\/\/pic0.duoduopangyu.net/g, 'http://pic0.duoduopangyu.net');
  content = content.replace(/%402x.png"/g, '%402x.png');
  content = content.replace(/"/g, "'");
  const chat = content.split(imgStr);
  const text = chat.map((item: any) => {
    const index = item.indexOf('%402x.png>');
    if (index === -1) return item;
    const express = index >= 0 ? list_express[item.slice(0, index) - 1].text : item;
    return express + item.slice('%402x.png>'.length + index, item.length);
  });
  return text;
}

const showVideo = (props: any) => {
  const VIDEOURL = props.msgContent && props.msgContent.videoUrl;
  const VideoPreview = () => {
    return (
      <video src={VIDEOURL} controls width="600" className="see-img-modal">
        <track default kind="captions" src="" />
        您的浏览器不支持 video 标签
      </video>
    );
  };
  const VideoTitle = <h3 className="send-title">视频预览</h3>;

  ShowModal({
    props: {
      url: VIDEOURL,
    },
    width: 760,
    bodyStyle: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    destroyOnClose: 'destroyOnClose',
    hasFooter: false,
    title: VideoTitle,
    childrens: [VideoPreview],
  });
};

const ImgPreviewModal = (props: any) => {
  const PICURL = props.msgContent && props.msgContent.imageUrl;
  // const ImgPreviewClass = { display: 'block', margin: '0 auto', maxWidth: '100%' };
  const ImgPreview = () => {
    return <img src={props.msgContent.imageUrl} alt="" className="see-img-modal" />;
  };
  const host =
    window.location.host.indexOf('bee.yipiandian') > -1
      ? window.location.host
      : 'bee.test.yipiandian.com';
  const ImgPreviewTitle = (
    <h3 className="send-title">
      图片预览
      <a
        href={`//${host}/templ/static/message/pic?messageId=${props.mid}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '0 10px', fontSize: 14 }}
      >
        查看原图
      </a>
    </h3>
  );

  ShowModal({
    props: { url: PICURL },
    width: 760,
    bodyStyle: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    hasFooter: false,
    title: ImgPreviewTitle,
    childrens: [ImgPreview],
  });
};

class MessageContent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      toggleMsg: true,
    };
  }

  currAudio: any = '';

  dom: any = '';

  audioPlayOperate = (item: any) => {
    const { updateCurrMessageId } = this.props;
    this.currAudio = document.getElementById('globalAudio');

    if (!this.currAudio) return;
    const AUDIOIMGSRC = item.msgContent && item.msgContent.audioUrl;
    if (AUDIOIMGSRC !== this.currAudio.src) {
      this.currAudio.src = AUDIOIMGSRC;
    }
    updateCurrMessageId(item.mid);

    if (this.currAudio.paused) {
      this.currAudio.play();
      updateCurrMessageId(item.mid);
    } else {
      this.currAudio.pause();
      this.currAudio.currentTime = 0;
      updateCurrMessageId(0);
    }
    const tempPauseListener: any = this.currAudio.addEventListener(
      'pause',
      () => {
        updateCurrMessageId(0);
        this.currAudio.removeEventListener('pause', tempPauseListener);
      },
      false,
    );
    const tempEndedListener: any = this.currAudio.addEventListener(
      'ended',
      () => {
        updateCurrMessageId(0);
        this.currAudio.removeEventListener('ended', tempEndedListener);
      },
      false,
    );
  };

  // 复制处理 ctrl+c触发的复制, 过滤表情图片
  preCopy = (e: any, props: any) => {
    const { clipboardData } = e;
    let selectContent = '';
    if (props.msgContent.content && props.msgContent.content.indexOf(imgStr) >= 0) {
      const selection = window.getSelection();
      const range = selection && selection.getRangeAt(0);
      const div = document.createElement('div');
      if (range) div.appendChild(range.cloneContents());
      selectContent = div.innerHTML;
      const text = replaceExpressTag(selectContent);
      clipboardData.setData('text/plain', text.join(''));
      e.preventDefault();
    }
  };

  toDataURL = (url: any) =>
    fetch(url)
      .then((response: any) => response.blob())
      .then(
        (blob: any) =>
          new Promise((resolve: any, reject: any) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      );

  handleCopy = () => {
    const { msgContent, msgType } = this.props;
    const getSelect = (targetNode: any) => {
      if (window.getSelection) {
        // chrome等主流浏览器
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNode(targetNode);
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    };
    switch (msgType) {
      case MsgType.TEXT:
        copy(replaceExpressTag(msgContent.content).join(''));
        break;
      case MsgType.IMG:
        getSelect(this.dom);
        document.execCommand('copy');
        copy(msgContent.imageUrl);
        this.toDataURL(msgContent.imageUrl).then((dataUrl: any) => {
          console.log(dataUrl);
        });
        break;
      case MsgType.EMOJI:
        copy(msgContent.imageUrl);
        break;
      default:
        break;
    }
  };

  handleAt = () => {
    const { fromNickname, fromWxid } = this.props;
    this.props.syncMessage({
      rawMsg: `@${fromNickname} `,
      editorValue: ` @[${fromNickname}](${fromWxid}) `,
      newPlainTextValue: ` @${fromNickname} `,
      msgType: 0,
      stamp: new Date().getTime(),
      atList: [{ id: fromWxid, display: `@${fromNickname}`, childIndex: 0 }],
    });
  };

  handleRevoke = (props: any) => {
    request.put(`/im/v2/messages/${props.mid}/revoke`, {
      wxId: props.wxId,
    });
  };

  handleLibrary = () => {
    const { mid, currentWindowTab } = this.props;
    const { id } = this.props.target;
    request.post('/im/v2/media', { mid, sessionId: id }).then(() => {
      if (currentWindowTab[0] === '4') {
        if (this.props.msgType === 1 && (!currentWindowTab[1] || currentWindowTab[1] === '1')) {
          this.props.getMediaList({ mediaType: 1 });
        } else if (this.props.msgType !== 1 && currentWindowTab[1] === '2') {
          this.props.getMediaList({ mediaType: 3 });
        } else if (this.props.msgType !== 1 && currentWindowTab[1] === '3') {
          this.props.getMediaList({ mediaType: 49 });
        }
      }
    });
  };

  handleExpress = () => {
    // 添加到表情
    const { mid } = this.props;
    request.post('/im/v2/emoticon', { mid }).then((res: any) => {
      console.log(res);
    });
  };

  menu = () => {
    const { props } = this;
    const overTime = moment(props.createTime).add(2, 'minutes') < moment(Date.now());
    const hasRevoke = props.status === 1;
    return (
      <Menu>
        {props.msgType === MsgType.TEXT ? (
          <Menu.Item onClick={this.handleCopy} key="1">
            复制
          </Menu.Item>
        ) : null}

        {/* {props.msgType === MsgType.IMG || props.msgType === MsgType.EMOJI ? (
          <Menu.Item onClick={this.handleCopy} key="2">
            复制链接
          </Menu.Item>
        ) : null} */}

        {/* {!props.isMe && !props.target.isSingle ? (
          <Menu.Item onClick={this.handleAt} key="2">
            @{props.fromNickname}
          </Menu.Item>
        ) : (
          ''
        )} */}
        {props.msgType === MsgType.IMG || props.msgType === MsgType.EMOJI ? (
          <Menu.Item onClick={this.handleExpress} key="5">
            添加到表情
          </Menu.Item>
        ) : (
          ''
        )}
        {props.msgType === MsgType.TEXT ||
        props.msgType === MsgType.IMG ||
        props.msgType === MsgType.EMOJI ||
        props.msgType === MsgType.SHARE ? (
          <Menu.Item onClick={this.handleLibrary} key="4">
            加入素材库
          </Menu.Item>
        ) : (
          ''
        )}
        {props.isMe ? (
          <Menu.Item
            disabled={overTime || hasRevoke}
            onClick={() => this.handleRevoke(props)}
            key="3"
          >
            撤回{`${overTime ? '（已超过2分钟）' : ''}`}
          </Menu.Item>
        ) : (
          ''
        )}
      </Menu>
    );
  };

  toggleMessage = () => {
    const { type } = this.props;
    const { toggleMsg } = this.state;
    if (type !== 'self') return;
    this.setState({
      toggleMsg: !toggleMsg,
    });
  };

  render() {
    const {
      props,
      props: { msgContent, type },
    } = this;
    const GifSizeStyle: React.CSSProperties =
      msgContent.width && msgContent.width / msgContent.height > 360 / 160
        ? { width: '100%', height: 'auto' }
        : { maxWidth: '100%', height: '100%' };
    const VideoClass = { width: 180, height: 320 };
    const TextMessage = `${props.atMe === 2 ? '@所有人\n' : ''}${msgContent &&
      msgContent.content &&
      msgContent.content.replace(/(^\n*)|(^\r)|(^\r\n)/, '')}`;
    let specialMessage = '[收到一个红包，请在手机微信上查看]';
    if (props.msgType === MsgType.FILE) {
      specialMessage = '[收到一个文件，请在手机微信上查看]';
    } else if (props.msgType === MsgType.APP) {
      specialMessage = '[收到一个小程序，请在手机微信上查看]';
    }

    // 202002新逻辑, 折叠多行文本
    const showType = this.props.source === 2 || this.props.source === 4 || this.props.source === 5;
    const mutiLine = TextMessage.split('\n').length > 1;
    let newTextMessage = TextMessage;
    let showMore: any = null;
    if (type === 'self' && mutiLine && showType) {
      const messageArray = TextMessage.split('\n');
      if (this.state.toggleMsg) {
        newTextMessage = messageArray[0].length ? messageArray[0] : messageArray[1];
      }

      showMore = this.state.toggleMsg ? (
        <div onClick={this.toggleMessage} className="link-show-more">
          加载更多
        </div>
      ) : (
        <div onClick={this.toggleMessage} className="link-show-more">
          折叠
        </div>
      );
    }

    return (
      <Dropdown overlay={this.menu()} trigger={['contextMenu']}>
        <div className={`message-container${props.status === 1 ? ' revoke' : ''}`}>
          {(() => {
            switch (props.msgType) {
              case MsgType.TEXT:
                return (
                  <div className="message-text-wrapper">
                    {(() => {
                      if (typeof props.mid === 'number') {
                        if (props.mid > 0) {
                          return (
                            <Icon
                              type="loading-3-quarters"
                              className="msgloading"
                              spin
                              style={{ zoom: '100%', color: 'rgba(0, 0, 0, 0.25)' }}
                            />
                          );
                        }
                        return (
                          <Icon
                            type="exclamation-circle"
                            className="msgloading"
                            theme="filled"
                            style={{ zoom: '100%', color: 'red' }}
                          />
                        );
                      }
                      return '';
                    })()}
                    <div
                      onCopy={(e: any) => this.preCopy(e, props)}
                      onClick={this.toggleMessage}
                      className="message-text"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: newTextMessage,
                        }}
                      ></div>
                      {showMore}
                    </div>
                  </div>
                );
              case MsgType.EMOJI:
                return (
                  <div
                    style={{
                      overflow: 'hidden',
                      position: 'relative',
                      maxWidth: '360px',
                      height: '160px',
                    }}
                  >
                    <img
                      alt="gif"
                      className={clx('message-img', {
                        'img-self': props.isMe,
                        'img-other': !props.isMe,
                      })}
                      style={GifSizeStyle}
                      src={msgContent && msgContent.imageUrl}
                    />
                  </div>
                );
              case MsgType.IMG:
                return (
                  <div
                    style={{
                      overflow: 'hidden',
                      position: 'relative',
                      maxWidth: '360px',
                      height: '160px',
                    }}
                  >
                    {typeof props.mid === 'number' ? (
                      <Icon
                        type="loading-3-quarters"
                        spin
                        style={{
                          zoom: '100%',
                          color: 'rgba(0, 0, 0, 0.25)',
                          position: 'absolute',
                          top: '25px',
                        }}
                      />
                    ) : (
                      ''
                    )}
                    <img
                      alt="图片"
                      className={clx('message-img', {
                        'img-self': props.isMe,
                        'img-other': !props.isMe,
                      })}
                      style={{ marginLeft: typeof props.mid === 'number' ? '20px' : '' }}
                      ref={(ref: any) => {
                        this.dom = ref;
                      }}
                      onClick={() => ImgPreviewModal(props)}
                      // onClick={() =>
                      //   this.props.handleImgUrl(msgContent && msgContent.imageUrl, props.mid)
                      // }
                      src={msgContent && msgContent.imageUrl}
                    />
                  </div>
                );
              case MsgType.SHARE:
                return (
                  <ShareMessageItem
                    msgContent={msgContent}
                    mid={props.mid}
                    getChatInvitationInfo={props.getChatInvitationInfo}
                  />
                );
              case MsgType.SYS:
              case MsgType.REVOKE:
                return (
                  <span
                    className="message-sys"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    dangerouslySetInnerHTML={{
                      __html: msgContent && msgContent.sysMsg,
                    }}
                  />
                );
              case MsgType.VIDEO:
                return (
                  <div
                    style={{
                      position: 'relative',
                    }}
                  >
                    <Icon
                      type="play-circle"
                      theme="filled"
                      className="message-video-icon"
                      onClick={() => {
                        showVideo(props);
                      }}
                    />
                    <div className="video-mask" />
                    <img
                      alt="视频"
                      className={clx('message-img', {
                        'img-self': props.isMe,
                        'img-other': !props.isMe,
                      })}
                      style={VideoClass}
                      onClick={() => {
                        showVideo(props);
                      }}
                      src={msgContent && msgContent.imageUrl}
                    />
                  </div>
                );
              case MsgType.AUDIO:
                return (
                  <div
                    style={{
                      width: computedAudioMessageLength(Math.round(msgContent.duration / 1000)),
                    }}
                    className={clx('message-audio', {
                      active: props.mid === props.currMessageId,
                    })}
                    onClick={() => this.audioPlayOperate(props)}
                  >
                    <div className="message-audio-icon" />
                    <p className="message-audio-time">
                      {msgContent ? `${secondToDate(Math.round(msgContent.duration / 1000))}` : ''}
                    </p>
                  </div>
                );
              case MsgType.APP:
              case MsgType.FILE:
              case MsgType.MONEY:
                return (
                  <div className="message-text-wrapper">
                    <pre
                      className="message-text"
                      dangerouslySetInnerHTML={{ __html: specialMessage }}
                    />
                  </div>
                );
              default:
                return (
                  <div className="message-text-wrapper">
                    <span>暂不支持此类消息</span>
                  </div>
                );
            }
          })()}
        </div>
      </Dropdown>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    currentWindowTab: state.currentWindowTab,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getMediaList: (data: any = {}) =>
      dispatch(createGetMediaLibraryListAction({ ...data, perpage: 5000 })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageContent);
