export const ACTION_SET_ACCOUNT_INFO = 'ACTION_SET_ACCOUNT_INFO';
export const ACTION_SET_SESSION_LIST = 'ACTION_SET_SESSION_LIST';
export const ACTION_SET_FRIEND_LIST = 'ACTION_SET_FRIEND_LIST';
export const ACTION_SET_GROUP_LIST = 'ACTION_SET_GROUP_LIST';
export const ACTION_SET_FRIEND_APPLY_LIST = 'ACTION_SET_FRIEND_APPLY_LIST';
export const ACTION_SET_MESSAGE_LIST = 'ACTION_SET_MESSAGE_LIST';
export const ACTION_SET_CURRENT_TARGET = 'ACTION_SET_CURRENT_TARGET';
export const ACTION_SET_LOGIN_INFO = 'ACTION_SET_LOGIN_INFO';
export const ACTION_SET_CLIENT_LIST = 'ACTION_SET_CLIENT_LIST';
export const ACTION_SET_CURRENT_CLIENT = 'ACTION_SET_CURRENT_CLIENT';
export const ACTION_SET_SYNC_CLIENT = 'ACTION_SET_SYNC_CLIENT';

export const ACTION_GET_ACCOUNT_INFO = 'ACTION_GET_ACCOUNT_INFO';
export const ACTION_GET_SESSION_LIST = 'ACTION_GET_SESSION_LIST';
export const ACTION_GET_FRIEND_LIST = 'ACTION_GET_FRIEND_LIST';
export const ACTION_GET_GROUP_LIST = 'ACTION_GET_GROUP_LIST';
export const ACTION_GET_FRIEND_APPLY_LIST = 'ACTION_GET_FRIEND_APPLY_LIST';
export const ACTION_GET_MESSAGE_LIST = 'ACTION_GET_MESSAGE_LIST';
export const ACTION_GET_CURRENT_TARGET = 'ACTION_GET_CURRENT_TARGET';
export const ACTION_GET_LOGIN_INFO = 'ACTION_GET_LOGIN_INFO';
export const ACTION_GET_CLIENT_LIST = 'ACTION_GET_CLIENT_LIST';
export const ACTION_GET_MOCK_SESSION_LIST = 'ACTION_GET_MOCK_SESSION_LIST';
export const ACTION_GET_FISSION_SESSION_LIST = 'ACTION_GET_FISSION_SESSION_LIST';

export const ACTION_MERGE_SESSION_LIST = 'ACTION_MERGE_SESSION_LIST';
export const ACTION_MERGE_FRIEND_LIST = 'ACTION_MERGE_FRIEND_LIST';
export const ACTION_MERGE_GROUP_LIST = 'ACTION_MERGE_GROUP_LIST';
export const ACTION_MERGE_FRIEND_APPLY_LIST = 'ACTION_MERGE_FRIEND_APPLY_LIST';
export const ACTION_MERGE_MESSAGE_LIST = 'ACTION_MERGE_MESSAGE_LIST';

export const ACTION_CREATE_SESSION = 'ACTION_CREATE_SESSION';

export const ACTION_SEND_TEXT_MESSAGE = 'ACTION_SEND_TEXT_MESSAGE';
export const ACTION_SEND_IMAGE_MESSAGE = 'ACTION_SEND_IMAGE_MESSAGE';
export const ACTION_SEND_LUCKY_MONEY_MESSAGE = 'ACTION_SEND_LUCKY_MONEY_MESSAGE';
export const ACTION_SEND_LINK_MESSAGE = 'ACTION_SEND_LINK_MESSAGE';
export const ACTION_SEND_GAMES_MESSAGE = 'ACTION_SEND_GAMES_MESSAGE';

export const ACTION_ALLOW_FRIEND_APPLY = 'ACTION_ALLOW_FRIEND_APPLY';
export const ACTION_IGNORE_FRIEND_APPLY = 'ACTION_IGNORE_FRIEND_APPLY';

export const TARGET_TYPE_SESSION = 'TARGET_TYPE_SESSION';
export const TARGET_TYPE_FRIEND = 'TARGET_TYPE_FRIEND';
export const TARGET_TYPE_GROUP = 'TARGET_TYPE_GROUP';
export const TARGET_TYPE_NEW_FRIEND = 'TARGET_TYPE_NEW_FRIEND';
export const TARGET_TYPE_NEW_GROUP = 'TARGET_TYPE_NEW_GROUP';

export const ACTION_UPDATE_SINGLE_SESSION = 'ACTION_UPDATE_SINGLE_SESSION';
export const ACTION_UPDATE_CURR_CLIENT_INFO = 'ACTION_UPDATE_CURR_CLIENT_INFO';
export const ACTION_UPDATE_MESSAGE_LIST_STATUS = 'ACTION_UPDATE_MESSAGE_LIST_STATUS';
export const ACTION_TOP_SINGLE_SESSION = 'ACTION_TOP_SINGLE_SESSION';
export const ACTION_SYNC_SEND_MESSAGE = 'ACTION_SYNC_SEND_MESSAGE';
export const ACTION_SYNC_DELETE_MESSAGE = 'ACTION_SYNC_DELETE_MESSAGE';
export const ACTION_REPLACE_SINGLE_SESSION = 'ACTION_REPLACE_SINGLE_SESSION';
export const ACTION_SET_ISLOADINGMESSAGE = 'ACTION_SET_ISLOADINGMESSAGE';

export const ACTION_GET_GROUPMEMBER_LIST = 'ACTION_GET_GROUPMEMBER_LIST';
export const ACTION_SET_GROUPMEMBER_LIST = 'ACTION_SET_GROUPMEMBER_LIST';
export const ACTION_MERGE_GROUPMEMBER_LIST = 'ACTION_MERGE_GROUPMEMBER_LIST';

export const ACTION_SET_CURRENT_SIDERTAB = 'ACTION_SET_CURRENT_SIDERTAB';
export const ACTION_SET_CURRENT_SESSION_LIST_FILTER_CONDITION =
  'ACTION_SET_CURRENT_SESSION_LIST_FILTER_CONDITION';
export const ACTION_SET_CURRENT_WINDOWTAB = 'ACTION_SET_CURRENT_WINDOWTAB';
export const ACTION_GET_MEDIALIBRARY_LIST = 'ACTION_GET_MEDIALIBRARY_LIST';
export const ACTION_GET_MEDIA_LIBRARY_LIST = 'ACTION_GET_MEDIA_LIBRARY_LIST';
export const ACTION_SET_MEDIA_LIBRARY_LIST = 'ACTION_SET_MEDIA_LIBRARY_LIST';

export const ACTION_GET_SESSION_IS_END = 'ACTION_GET_SESSION_IS_END';
export const ACTION_SET_SESSION_IS_END = 'ACTION_SET_SESSION_IS_END';

export const ACTION_SET_CURRENT_USER = 'ACTION_SET_CURRENT_USER';
export const ACTION_SET_TAB_UNREAD_COUNT = 'ACTION_SET_TAB_UNREAD_COUNT';
