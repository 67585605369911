import { put, takeEvery, call, all } from 'redux-saga/effects';
import {
  ACTION_GET_FRIEND_APPLY_LIST,
  ACTION_ALLOW_FRIEND_APPLY,
  ACTION_IGNORE_FRIEND_APPLY,
} from '../constant';
import { createSetWxFriendApplyListAction, createGetWxFriendApplyListAction } from '../action';
import { fetchFriendApplyList, sendAgreeFriendApply, sendIgnoreFriendApply } from '../../http';

export function* getFriendApplyList(action: any) {
  try {
    const data = yield call(fetchFriendApplyList, action.param);
    yield put(createSetWxFriendApplyListAction(data.list));
  } catch (error) {
    console.error(error);
  }
}

export function* replyFriendApply(action: any) {
  try {
    const data = yield call(sendAgreeFriendApply, action.param);
    yield put(createGetWxFriendApplyListAction({ limit: 5000 }));
  } catch (error) {
    console.error(error);
  }
}

export function* ignoreFriendApply(action: any) {
  try {
    const data = yield call(sendIgnoreFriendApply, action.param);
    yield put(createGetWxFriendApplyListAction({ limit: 5000 }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetFriendApplyList() {
  yield takeEvery(ACTION_GET_FRIEND_APPLY_LIST, getFriendApplyList);
}

export function* watchReplyFriendApply() {
  yield takeEvery(ACTION_ALLOW_FRIEND_APPLY, replyFriendApply);
}

export function* watchIgnoreFriendApply() {
  yield takeEvery(ACTION_IGNORE_FRIEND_APPLY, ignoreFriendApply);
}

export function* watchFriendApply() {
  yield all([watchGetFriendApplyList(), watchReplyFriendApply(), watchIgnoreFriendApply()]);
}
