import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Button, Modal, Input, List, Checkbox } from 'antd';
import request from '@/http/request';
import { State } from '@/store/types/state';

class GroupManager extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      list: [], // 添加dialog
      list1: [], // 删除dialog
      currManagerList: [], // 添加dialog 状态
      currManagerList1: [], // 删除dialog 状态
      showGroupManagerVisible: false,
      searchWord: '',
      editStatus: 1, // 1添加， -1删除
    };
  }

  isEnd: boolean = false;

  start: number = 0;

  componentDidMount() {
    this.getMemberList(true);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.groupId !== prevProps.groupId && this.props.target.chatType === 2) {
      this.clearCacheData();
    }
  }

  clearCacheData = () => {
    this.setState(
      {
        list: [],
        list1: [],
        currManagerList: [],
        currManagerList1: [],
        searchWord: '',
      },
      () => this.getMemberList(true),
    );
  };

  getMemberList = (isReload: boolean) => {
    if (isReload) {
      this.start = 0;
    }
    request
      .get('/v2/group/members', {
        groupId: this.props.groupId,
        start: this.start,
        q: this.state.searchWord,
      })
      .then((res: any) => {
        const { isEnd, nextIndex } = res.data;
        let { list } = res.data;
        this.isEnd = isEnd;
        this.start = nextIndex;
        const { currManagerList } = this.state;
        list = list.filter((item: any) => {
          return item.role !== 2;
        });
        for (let i = 0; i < list.length; i += 1) {
          const duplicateList = currManagerList.filter((item: any) => item.id === list[i].id);
          if (list[i].role === 1 && !duplicateList.length) {
            currManagerList.push(list[i]);
          }
        }
        this.setState({
          list: isReload ? list : [...this.state.list, ...list],
          currManagerList,
          list1: currManagerList,
          currManagerList1: [],
        });
      });
  };

  groupSearchHandle = (e: any) => {
    this.setState(
      {
        searchWord: e.target.value,
      },
      () => {
        debounce(() => {
          this.getMemberList(true);
        }, 500)();
      },
    );
  };

  editGroupManagerModal = (val: number) => {
    this.setState(
      {
        showGroupManagerVisible: true,
        editStatus: val,
      },
      () => {
        this.getMemberList(true);
      },
    );
  };

  onChangeCheckboxAddHandle = (e: any, index: number) => {
    let currManagerList = [...this.state.currManagerList];
    const currItem = this.state.list[index];
    if (e.target.checked) {
      currManagerList.push(currItem);
    } else {
      currManagerList = currManagerList.filter((item: any) => {
        return item.id !== currItem.id;
      });
    }
    this.setState({ currManagerList });
  };

  onChangeCheckboxDeleteHandle = (e: any, index: number) => {
    let currManagerList1 = [...this.state.currManagerList1];
    const currItem = this.state.list1[index];
    if (e.target.checked) {
      currManagerList1.push(currItem);
    } else {
      currManagerList1 = currManagerList1.filter((item: any) => {
        return item.id !== currItem.id;
      });
    }
    this.setState({ currManagerList1 });
  };

  hideGroupManagerModal = () => {
    this.setState(
      {
        showGroupManagerVisible: false,
        currManagerList: [],
        searchWord: '',
        currManagerList1: [],
      },
      () => {
        this.getMemberList(true);
        this.props.getMoreMemeberList({ groupId: this.props.groupId, start: 0 }, false);
      },
    );
  };

  submitGroupManager = () => {
    const { editStatus } = this.state;
    if (editStatus === 1) {
      const wxIds = this.state.currManagerList.map((item: any) => item.wxId);
      request
        .post(`im/v2/groups/${this.props.groupId}/admin`, {
          wxId: this.props.currentClient.wxId,
          groupId: this.props.groupId,
          wxIds: Array.from(new Set(wxIds)),
        })
        .then(() => {
          this.hideGroupManagerModal();
        });
    } else {
      const wxIds = this.state.currManagerList1.map((item: any) => item.wxId);
      request
        .delete(`im/v2/groups/${this.props.groupId}/admin`, {
          wxId: this.props.currentClient.wxId,
          groupId: this.props.groupId,
          wxIds: Array.from(new Set(wxIds)),
        })
        .then(() => {
          this.hideGroupManagerModal();
        });
    }
  };

  render() {
    const { currManagerList, currManagerList1, showGroupManagerVisible, searchWord } = this.state;
    const { list, list1, editStatus } = this.state;
    const listRole = list.filter((item: any) => item.role === 1);
    return (
      <div style={{ lineHeight: '38px' }}>
        <div className="im-title-font-small">当前管理员：</div>
        {listRole.length > 0 ? (
          <span>
            {listRole.map((item: any, index: number) => (
              <img
                alt="head"
                className="managerHead"
                key={index}
                src={item.smallHeadUrl || '/defaultHead.jpg'}
              />
            ))}
          </span>
        ) : (
          ''
        )}
        {listRole.length < 3 ? (
          <span>
            <img
              className="managerHead"
              alt="add"
              src="./add.png"
              onClick={() => this.editGroupManagerModal(1)}
            />
          </span>
        ) : (
          ''
        )}
        {listRole.length > 0 ? (
          <span>
            <img
              className="managerHead"
              alt="delete"
              src="./delete.png"
              onClick={() => this.editGroupManagerModal(-1)}
            />
          </span>
        ) : (
          ''
        )}
        <Modal
          visible={showGroupManagerVisible}
          onCancel={this.hideGroupManagerModal}
          title="选择群管理员"
          style={{ top: 60 }}
          footer={[
            <span key="text" style={{ padding: '0 20px' }}>
              最多只能添加3个群管理员
            </span>,
            <Button key="back" className="im-modal-cancel-btn" onClick={this.hideGroupManagerModal}>
              取消
            </Button>,
            <Button
              key="submit"
              onClick={this.submitGroupManager}
              type="primary"
              className="im-modal-confirm-btn"
            >
              确认
            </Button>,
          ]}
        >
          <div className="qun-manager-dialog">
            <div className="scroll-box" id="scroll-box-3">
              <List split={false} loading={false} size="small">
                {editStatus === 1 ? (
                  <List.Item>
                    <Input
                      style={{ width: '450px' }}
                      placeholder="搜索群成员"
                      allowClear
                      onChange={this.groupSearchHandle}
                      value={searchWord}
                    />
                  </List.Item>
                ) : (
                  ''
                )}

                {this.state.editStatus === 1 ? (
                  <>
                    {currManagerList.length ? (
                      <List.Item className="group-item">
                        {currManagerList.map((item: any, index: number) => {
                          if (item.role === 0) {
                            return (
                              <img
                                alt="head"
                                className="managerHead-dialog"
                                key={index}
                                src={item.smallHeadUrl || '/defaultHead.jpg'}
                              />
                            );
                          }
                          return '';
                        })}
                      </List.Item>
                    ) : (
                      ''
                    )}
                    {list.length
                      ? list.map((item: any, index: number) => {
                          const disabledStatus = (() => {
                            return currManagerList.filter((node: any) => {
                              return node.id === item.id;
                            });
                          })();
                          if (item.role === 0) {
                            return (
                              <List.Item key={index} className="group-item">
                                <Checkbox
                                  disabled={
                                    (currManagerList.length === 3 && !disabledStatus[0]) ||
                                    item.role === 2 ||
                                    item.role === 1
                                  }
                                  checked={!!disabledStatus[0]}
                                  onChange={(e: any) => this.onChangeCheckboxAddHandle(e, index)}
                                >
                                  <img
                                    alt="head"
                                    className="managerHead-dialog"
                                    key={index}
                                    src={item.smallHeadUrl || '/defaultHead.jpg'}
                                  />
                                  <span>{item.nickname}</span>
                                </Checkbox>
                              </List.Item>
                            );
                          }
                          return '';
                        })
                      : ''}
                    {!this.isEnd ? (
                      <div className="qun-member-loadmore">
                        <Button onClick={() => this.getMemberList(false)}>加载更多</Button>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    {currManagerList1.length ? (
                      <List.Item className="group-item">
                        {currManagerList1.map((item: any, index: number) => (
                          <img
                            alt="head"
                            className="managerHead-dialog"
                            key={index}
                            src={item.smallHeadUrl || '/defaultHead.jpg'}
                          />
                        ))}
                      </List.Item>
                    ) : (
                      ''
                    )}
                    {list1.length
                      ? list1.map((item: any, index: number) => {
                          const disabledStatus = (() => {
                            return currManagerList1.filter((node: any) => {
                              return node.id === item.id;
                            });
                          })();
                          return (
                            <List.Item key={index} className="group-item">
                              <Checkbox
                                // disabled={
                                //   (currManagerList.length === 3 && !disabledStatus[0]) ||
                                //   item.role === 2 ||
                                //   item.role === 1
                                // }
                                checked={!!disabledStatus[0]}
                                onChange={(e: any) => this.onChangeCheckboxDeleteHandle(e, index)}
                              >
                                <img
                                  alt="head"
                                  className="managerHead-dialog"
                                  key={index}
                                  src={item.smallHeadUrl || '/defaultHead.jpg'}
                                />
                                <span>{item.nickname}</span>
                              </Checkbox>
                            </List.Item>
                          );
                        })
                      : ''}
                  </>
                )}
              </List>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    currentClient: state.clients.current,
    target: state.currentTarget,
  };
}

export default connect(mapStateToProps)(GroupManager);
