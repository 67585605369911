import React, { useCallback, useState, useEffect } from 'react';
import { Popover, Modal, Input, message } from 'antd';
import IconFont from '@/components/IconFont';
import styles from './LuckMoneyIcon.module.less';
import request from '@/http/request';

const { confirm, destroyAll } = Modal;

const iconPos = {
  fontSize: '20px',
  marginRight: '16px',
  lineHeight: '16px',
};

interface IMomentItem {
  onClick: () => void;
  name: string;
  price: number;
}

export function numberFormat(
  number: any,
  decimals: any,
  decPoint: any,
  thousandsSep: any,
  roundtag?: any,
) {
  /*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * dec_point：小数点符号
   * thousands_sep：千分位符号
   * roundtag:舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
   * */
  const numberInner = number.toString().replace(/[^0-9+-Ee.]/g, '');
  const n = !Number.isFinite(+numberInner) ? 0 : +numberInner;
  const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint;
  let s: any = '';

  const toFixedFix = (num: any, precs: any) => {
    const k = 10 ** precs;
    return (
      parseFloat(Math.ceil(parseFloat((num * k).toFixed(precs * 2))).toFixed(precs * 2)) / k
    ).toString();
  };

  s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
  const re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, `$1${sep}$2`);
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

const MomentItem: React.FC<IMomentItem> = props => {
  const { onClick, name, price } = props;
  return (
    <li className={styles.item} onClick={onClick}>
      <div className={styles.title}>
        <IconFont
          style={{ fontSize: '20px', marginRight: '16px', cursor: 'poiner', lineHeight: '16px' }}
          iconfont="icondanchuangzhongdexiaohongbaoicon"
        ></IconFont>
        <span>{name}</span>
      </div>
      <div className={styles.action}>￥{numberFormat(price, 2, '.', ',')}</div>
    </li>
  );
};

interface LuckMoneyProps {
  onClick: (
    redPackId: number,
    price: number,
    kind: number,
    payDesc: string,
    mchId: number,
    event: any,
  ) => void;
}

interface ILuckyMoneyItem {
  amount: number;
  name: string;
  id: number;
  payDesc?: string;
  onHbFlag?: number;
}
interface CustomerMoneyItem {
  name: string;
  id: number;
  payDesc?: string;
  onHbFlag?: number;
}
const LuckMoneyIcon: React.FC<LuckMoneyProps> = props => {
  const [luckyMoneyList, setLuckyMomeny] = useState<ILuckyMoneyItem[]>([]);
  const [customerMoneyList, setCustomerMoney] = useState<CustomerMoneyItem[]>([]);
  const input: any = React.createRef();

  // 暂时不要模版红包了
  // useEffect(() => {
  //   setLuckyMomeny([
  //     { id: -1, name: '志愿者红包', amount: 33, payDesc: '志愿者红包', onHbFlag: 0 },
  //     { id: -1, name: '活动红包1', amount: 30, onHbFlag: 1 },
  //     { id: -1, name: '活动红包2', amount: 118, onHbFlag: 1 },
  //   ]);
  // }, []);

  function getRedPacks() {
    request.get('/im/v2/merchants/all').then((res: any) => {
      const { list } = res.data;
      if (!list.length) {
        message.error('暂无账户');
        return;
      }
      setCustomerMoney(
        list.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
          };
        }),
      );
    });
  }

  let closeFlag = false;

  // 活动红包true，其他false
  let kind = false;

  const onClose = (val: boolean) => {
    closeFlag = val;
  };

  const onHb = (val: boolean) => {
    kind = val;
  };

  const openConfirmModal = useCallback(
    (title: string, item: any, price: number, redPackId: number, payDesc?: string) => {
      confirm({
        className: 'confirm-modal',
        icon: null,
        title: '发送红包',
        content: (
          <div className={styles['modal-content']}>
            <p>
              您确定要给当前好友发送<span className={styles['high-light']}> {title} </span>红包吗
            </p>
            <p>
              发送后，好友打开红包可获得
              <span className={styles['high-light']}>
                {numberFormat(price / 100, 2, '.', ',')}{' '}
              </span>
              元
            </p>
            <p>
              红包账户：
              <span style={{ color: 'red' }}>{item.name}</span>
            </p>
          </div>
        ),
        okText: '发送',
        cancelText: '取消',
        onOk() {
          destroyAll();
          if (!closeFlag) {
            onClose(true);
            props.onClick(redPackId, price, kind ? 2 : 1, payDesc || '', item.id, () => {
              if (closeFlag) {
                onClose(false);
              }
            });
            setTimeout(() => {
              if (closeFlag) {
                onClose(false);
              }
            }, 2000);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    [props.onClick, closeFlag, onClose, kind],
  );

  const openCreateHongbao = useCallback(
    (item: any) => {
      confirm({
        className: 'confirm-modal',
        icon: null,
        title: '自定义红包',
        content: (
          <div>
            <p>
              红包金额：
              <Input
                style={{ margin: '8px 0 0 8px', width: '66%' }}
                type="text"
                placeholder="请输入红包金额，最少0.3，最多200"
                ref={input}
              />
            </p>
            <p>
              红包账户：
              <span className={styles['modal-account-text']}>{item.name}</span>
            </p>
          </div>
        ),
        okText: '发送',
        cancelText: '取消',
        onOk() {
          const val = parseFloat(input.current.state.value);
          if (Number.isNaN(val)) {
            message.warning('请输入有效金额！');
            return;
          }
          if (val < 0.3 || val > 200) {
            message.warning('红包金额超出限制！');
            return;
          }
          openConfirmModal('自定义红包', item, parseInt((val * 100).toFixed(0), 0), -1);
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    [onHb],
  );
  return (
    <Popover
      overlayClassName={styles['pop-style']}
      content={
        <ul className={styles['list-wrap']}>
          {luckyMoneyList.length > 0 &&
            luckyMoneyList.map((item, index) => (
              <MomentItem
                key={index}
                onClick={() => {
                  onHb(!!item.onHbFlag);
                  openConfirmModal(item.name, '账户', item.amount, item.id, item.payDesc);
                }}
                name={item.name}
                price={item.amount / 100}
              />
            ))}
          {customerMoneyList.length > 0 &&
            customerMoneyList.map((item, index) => (
              <li
                className={styles.item}
                key={index}
                onClick={() => {
                  onHb(false);
                  openCreateHongbao(item);
                }}
              >
                <div className={styles.title} style={{ cursor: 'pointer' }}>
                  <span style={{ textAlign: 'left' }}>{item.name}</span>
                  <span style={{ textAlign: 'right' }}>自定义红包</span>
                </div>
              </li>
            ))}
          {/* <li
            className={styles.item}
            onClick={() => {
              onHb(false);
              openCreateHongbao();
            }}
          >
            <div className={styles.title} style={{ cursor: 'pointer' }}>
              <IconFont style={iconPos} iconfont="iconhongbaolidetianjia"></IconFont>
              <span>自定义红包</span>
            </div>
          </li>
          <li
            className={styles.item}
            onClick={() => {
              onHb(true);
              openCreateHongbao();
            }}
          >
            <div className={styles.title} style={{ cursor: 'pointer' }}>
              <IconFont style={iconPos} iconfont="iconhongbaolidetianjia"></IconFont>
              <span>活动红包</span>
            </div>
          </li> */}
        </ul>
      }
      trigger="click"
    >
      <IconFont
        style={{ fontSize: '20px', marginLeft: '16px', cursor: 'poiner' }}
        iconfont="iconbianjiqihongbaoicon"
        onClick={getRedPacks}
      ></IconFont>
    </Popover>
  );
};

export default LuckMoneyIcon;
