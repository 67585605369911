import React, { useRef, useCallback } from 'react';

interface IImagePartialProps {
  fallbackURL: string;
  errorURL: string;
  className: string;
  style: React.CSSProperties;
  alt: string;
}
interface IImageProps extends Partial<IImagePartialProps> {
  src: string;
}

const Image: React.FC<IImageProps> = props => {
  const { className, src, fallbackURL, errorURL, alt, style } = props;
  const imgRef = useRef<HTMLImageElement>(null);
  const errorCallback = useCallback(() => {
    if (imgRef.current) {
      if (errorURL) {
        imgRef.current.src = errorURL;
      }
    }
  }, []);
  return (
    <img
      ref={imgRef}
      style={style}
      alt={alt}
      className={className}
      src={src || fallbackURL}
      onError={errorCallback}
    />
  );
};

export default Image;
