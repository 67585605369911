import { BaseAction } from '../types/action';
import {
  ACTION_GET_SESSION_LIST,
  ACTION_SET_SESSION_LIST,
  ACTION_CREATE_SESSION,
  ACTION_UPDATE_SINGLE_SESSION,
  ACTION_TOP_SINGLE_SESSION,
  ACTION_GET_MOCK_SESSION_LIST,
  ACTION_GET_FISSION_SESSION_LIST,
  ACTION_REPLACE_SINGLE_SESSION,
} from '../constant';
import { WxSession } from '../types/state';

export function createGetWxSessionListAction(param: any): BaseAction {
  return {
    type: ACTION_GET_SESSION_LIST,
    param,
  };
}

export function createSetWxSessionListAction(data: WxSession[]): BaseAction {
  return {
    type: ACTION_SET_SESSION_LIST,
    data,
  };
}

export function createGetMockSessionListAction(data: any) {
  return {
    type: ACTION_GET_MOCK_SESSION_LIST,
    data,
  };
}

export function createCreateWxSessionAction(param: any): BaseAction {
  return {
    type: ACTION_CREATE_SESSION,
    param,
  };
}

export function createUpdateSingleSessionAction(data: { sessionId: number }): BaseAction {
  return {
    type: ACTION_UPDATE_SINGLE_SESSION,
    data,
  };
}

export function createTopSingleSessionAction(data: any): BaseAction {
  return {
    type: ACTION_TOP_SINGLE_SESSION,
    data,
  };
}

export function createGetWxFissionSessionListAction(param: any): BaseAction {
  return {
    type: ACTION_GET_FISSION_SESSION_LIST,
    param,
  };
}

export function createReplaceSingleSessionAction(data: any): BaseAction {
  return {
    type: ACTION_REPLACE_SINGLE_SESSION,
    data,
  };
}
