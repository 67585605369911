import React, { memo } from 'react';
import { list_express } from '../../page/WechatIM/component/EmojiSuggestions/express';
import './index.less';

const filterText = (text: string, keyword: string) => {
  if (!text || Object.prototype.toString.call(text) !== '[object String]') return '';
  let ctext = text;
  try {
    list_express.forEach((item: any) => {
      ctext =
        ctext &&
        ctext.replace(
          item.reg,
          `<span class="emoji-icon" style="background-position:${item.x}px,${item.y}px"></span>`,
        );
    });
    ctext = ctext.replace(/\n|\r/g, '<br/>');
    if (keyword) {
      const reg = new RegExp(`${keyword}`, 'g');
      ctext = ctext.replace(reg, `<span class="keyword">${keyword}</span>`);
    }
  } catch (error) {
    console.log(error);
  }
  return ctext;
};

const EmojiText = memo((props: any) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: filterText(props.text, props.keyword) }}
      className="emoji-text"
    ></div>
  );
});
export default EmojiText;
