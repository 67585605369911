import React, { PureComponent } from 'react';
import { Button } from 'antd';
import './index.less';
import UserHeader from './UserHeader';
import Content from './Content';
import Communication from './Communication';

class MomentsContainer extends PureComponent<any> {
  render() {
    const { momentsList, target, currClientWxId, currClientNickname } = this.props;
    return (
      <div className="moments-layout">
        <div className="scroll-box" id="scroll-box-7">
          <div style={{ padding: '0 8px 0 8px' }}>
            {target ? (
              <UserHeader
                avatar={target.avatar || (momentsList[0] && momentsList[0].avatar)}
                nickname={target.nickname || (momentsList[0] && momentsList[0].nickname)}
              />
            ) : (
              ''
            )}
            {momentsList.length
              ? momentsList.map((item: any, index: number) => (
                  <div key={index} className="moments-list">
                    <Content content={item.content} />
                    <Communication
                      currClientWxId={currClientWxId}
                      currClientNickname={currClientNickname}
                      time={item.createTime}
                      commentList={item.commentList}
                      likeList={item.likeList}
                      target={target}
                      isLiked={item.isLiked}
                      snsId={item.id}
                      getMomentsList={(val: boolean) => this.props.getMomentsList(val)}
                    />
                  </div>
                ))
              : ''}
            {!this.props.isEnd ? (
              <div className="qun-member-loadmore">
                <Button type="primary" ghost onClick={() => this.props.getMomentsList(false)}>
                  加载更多
                </Button>
              </div>
            ) : (
              <>
                {this.props.visibleMessage ? (
                  <div
                    style={{ textAlign: 'center', margin: '12px auto' }}
                  >{`朋友圈${this.props.visibleMessage}可见`}</div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MomentsContainer;
