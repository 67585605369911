import React, { useState, useCallback, useEffect } from 'react';
import { Icon, Popover, Tabs } from 'antd';
import './index.less';
import { list_express } from './express';
import IconFont from '@/components/IconFont';
import Emoticon from './Emoticon';

let child: any;

interface IProps {
  onSelectChange: (emojiString: string) => void;
  syncMessage?: (data: any) => any;
  showEmogticon?: boolean;
  sendGamesMessage?: (data: any) => any;
}

// 提取自定义Hook
function useEmojiLogic(props: IProps) {
  const [visible, setVisible] = useState(false);

  const handleClick = useCallback(
    (itemPosText: string) => (_: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setVisible(false);
      props.onSelectChange(itemPosText);
    },
    [setVisible, props.onSelectChange],
  );

  const handleVisible = useCallback(
    (show: boolean) => {
      setVisible(show);
      if (child && child.getOriginData) {
        child.getOriginData();
      }
    },
    [setVisible, child && child.getOriginData],
  );

  return {
    visible,
    handleClick,
    handleVisible,
    setVisible,
  };
}

const EmojiSuggestions: React.FC<IProps> = props => {
  const { handleClick, handleVisible, visible, setVisible } = useEmojiLogic(props);
  const emojiDom = (
    <div style={{ height: '308px', overflow: 'auto', marginLeft: '11px', paddingTop: '22px' }}>
      {list_express.length &&
        list_express.map((itemPos, index) => {
          if (itemPos.x !== undefined) {
            return (
              <div
                className="symbol-express"
                key={index}
                onClick={handleClick(itemPos && itemPos.text)}
                style={{
                  backgroundPositionX: `${itemPos.x}px`,
                  backgroundPositionY: `${itemPos.y}px`,
                }}
              ></div>
            );
          }
          return '';
        })}
    </div>
  );
  return (
    <span className="chat-pannel-header-icon">
      <Popover
        autoAdjustOverflow
        arrowPointAtCenter
        content={
          <div className="express-modal">
            {props.showEmogticon ? (
              <Tabs tabPosition="bottom" defaultActiveKey="1">
                <Tabs.TabPane
                  tab={
                    <IconFont
                      style={{ fontSize: '28px', cursor: 'poiner' }}
                      iconfont="icon-biaoqing"
                    ></IconFont>
                  }
                  key="1"
                >
                  {emojiDom}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <IconFont
                      style={{ fontSize: '28px', cursor: 'poiner' }}
                      iconfont="icon-shoucang"
                    ></IconFont>
                  }
                  key="2"
                >
                  <div className="emoticon-layout">
                    <Emoticon
                      syncMessage={(data: any) => {
                        if (props.syncMessage) {
                          props.syncMessage(data);
                          setVisible(false);
                        }
                      }}
                      sendGamesMessage={(data: any) => {
                        if (props.sendGamesMessage) {
                          props.sendGamesMessage(data);
                          setVisible(false);
                        }
                      }}
                      onRef={(ref: any) => {
                        child = ref;
                      }}
                    />
                  </div>
                </Tabs.TabPane>
              </Tabs>
            ) : (
              emojiDom
            )}
          </div>
        }
        onVisibleChange={handleVisible}
        overlayStyle={{ width: '532px' }}
        placement="topLeft"
        trigger="click"
        visible={visible}
      >
        <IconFont style={{ fontSize: '20px', cursor: 'poiner' }} iconfont="iconbiaoqing"></IconFont>
      </Popover>
    </span>
  );
};

export default EmojiSuggestions;
