import React from 'react';
import moment from 'moment';
import { Table, Button, Popover, Tooltip } from 'antd';
import WaringPop from '@/components/WaringPop';
import './index.less';

interface Prop {
  data: any[];
  onClick: (id: any) => void;
  onIgnoreClick: (id: any) => void;
}
interface State {
  columns: any[];
}
class NewGroupItem extends React.Component<Prop, State> {
  constructor(props: Prop) {
    super(props);
    this.state = {
      columns: [
        {
          title: '群名称',
          dataIndex: 'groupName',
          key: 'groupName',
          className: 'table-th',
          render: (text: any, row: any) => (
            <div>
              <Tooltip placement="right" title={text}>
                <div className="group-apply-td1">{text}</div>
              </Tooltip>
              {row.auditReason ? (
                <WaringPop
                  content={row.auditReason}
                  styleTheme={{ color: '#8C8C8C', background: '#E9E9E9' }}
                ></WaringPop>
              ) : (
                ''
              )}
            </div>
          ),
        },
        {
          title: '成员',
          dataIndex: 'memberCount',
          key: 'memberCount',
          className: 'table-th th2',
          render: (text: any, row: any, index: any) => this.renderMember(text, row, index),
        },
        {
          title: '邀请人',
          dataIndex: 'inviterNickname',
          key: 'inviterNickname',
          className: 'table-th th3',
          render: (text: any, row: any) => (
            <Tooltip placement="right" title={text}>
              <div className={row.auditReason ? 'table-spe group-apply-td3' : 'group-apply-td3'}>
                {text}
              </div>
            </Tooltip>
          ),
        },
        {
          title: '验证信息',
          dataIndex: 'invitationReason',
          key: 'veriinvitationReasonfy',
          className: 'table-th th4',
          render: (text: any, row: any) => (
            <Tooltip placement="right" title={text}>
              <div className={row.auditReason ? 'table-spe group-apply-td4' : 'group-apply-td4'}>
                {text.length > 21 ? text.slice(0, 21).concat('...') : text}
              </div>
            </Tooltip>
          ),
        },
        {
          title: '时间',
          dataIndex: 'createAt',
          key: 'createAt',
          className: 'table-th th5',
          render: (time: any, row: any) => (
            <div className={row.auditReason ? 'table-spe group-apply-td5' : 'group-apply-td5'}>
              {moment(`${time}`).format('YYYY-MM-DD')}
              <br />
              {moment(`${time}`).format('HH:mm')}
            </div>
          ),
        },
        {
          title: '操作',
          dataIndex: 'status',
          key: 'status',
          className: 'table-th th6',
          render: (status: any, row: any) => this.renderContext(status, row),
        },
      ],
    };
  }

  renderMember = (text: any, row: any, index: any) => {
    const { memberList } = row;
    return (
      <Popover
        content={
          memberList.length &&
          memberList.map((item: any, idx: any) => (
            <div key={idx} style={{ display: 'inline-block', margin: '8px' }}>
              <img
                alt="头像"
                src={item.headimgurl || '/defaultHead.jpg'}
                style={{ width: '90px', height: '90px' }}
              />
              <div style={{ textAlign: 'center' }}>{item && item.nickname}</div>
            </div>
          ))
        }
      >
        <div className={row.auditReason ? 'table-spe group-apply-td2' : 'group-apply-td2'}>
          <a>{text}</a>
        </div>
      </Popover>
    );
  };

  renderContext = (status: any, row: any) => {
    const primaryBtn = {
      height: 24,
      borderRadius: 2,
      border: '1px solid #389E0D',
      color: '#389E0D',
      fontSize: '12px',
      lineHeight: '24px',
      marginLeft: 8,
    };
    switch (status) {
      case 0: // 未通过 0
        return (
          <div className={row.auditReason ? 'table-spe' : ''}>
            <Button
              onClick={() => this.props.onIgnoreClick(row.id)}
              size="small"
              style={{ ...primaryBtn, borderColor: '#D9D9D9', color: '#8C8C8C' }}
            >
              忽&#8195;略
            </Button>
            <Button onClick={() => this.props.onClick(row.id)} style={primaryBtn}>
              通&#8195;过
            </Button>
          </div>
        );
      case 1: // 已通过 1
        return (
          <div className={row.auditReason ? 'table-spe' : ''}>
            <Button
              disabled
              size="small"
              style={{ background: '#E9E9E9', color: '#8C8C8C' }}
              className="im-btn-default"
            >
              已通过
            </Button>
          </div>
        );
      case 2: // 已过期 2
        return (
          <div className={row.auditReason ? 'table-spe' : ''}>
            <Button disabled size="small" style={{ background: '#E9E9E9', color: '#8C8C8C' }}>
              已过期
            </Button>
          </div>
        );
      case 3: // 已忽略 3
        return (
          <div className={row.auditReason ? 'table-spe' : ''}>
            <Button disabled size="small" style={{ background: '#E9E9E9', color: '#8C8C8C' }}>
              已忽略
            </Button>
            <Button onClick={() => this.props.onClick(row.id)} style={primaryBtn}>
              通&#8195;过
            </Button>
          </div>
        );
      default:
        return <></>;
    }
  };

  render() {
    const { columns } = this.state;
    const { data } = this.props;
    return (
      <div className="group-line">
        <Table columns={columns} dataSource={data} />
      </div>
    );
  }
}

export default NewGroupItem;
