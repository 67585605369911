import React, { Component } from 'react';
import request from '@/http/request';

const UserHeader: React.FC<any> = props => {
  return (
    <div>
      <img
        alt="头像"
        style={{ width: '28px', height: '28px' }}
        src={props.avatar || './defaultHead.jpg'}
      />
      <span style={{ fontSize: '12px', padding: '8px', color: '#092B00' }}>
        {props.nickname || ''}
      </span>
    </div>
  );
};

export default UserHeader;
