import React, { memo } from 'react';
import { Avatar } from 'antd';
import moment from 'moment';
import './style.less';
import ShowModal from '@/components/ShowModal';
import Message from './Message';

const showPicture = (url: string) => {
  const img = () => (
    <div style={{ textAlign: 'center' }}>
      <img alt="" src={url} width="200px" />
    </div>
  );
  ShowModal({
    title: '图片',
    hasFooter: false,
    childrens: [img],
  });
};

const MessageItem = memo((props: any) => {
  const { item, id, highlight } = props;
  if (item.msgType >= 10000) {
    return (
      <div className="record-sys">
        <div style={{ padding: '4px 0' }}>
          <span className="message-content-author-time">
            {moment(item.createTime).format('MM-DD HH:mm')}
          </span>
        </div>
        <p className="sysMsg" id={id}>
          {item.msgContent.sysMsg}
        </p>
      </div>
    );
  }
  return (
    <div className="record-message">
      <div
        className={`${props.right ? 'messageRight' : 'message'}`}
        id={id}
        style={{ background: highlight ? '#e2e2e2' : '' }}
      >
        <div className="message-inner">
          <div className="message-avatar">
            <span className="message-avatar-image">
              <Avatar src={item.fromAvatar || '/defaultHead.jpg'} />
            </span>
          </div>
          <div className="message-content">
            <div className="message-content-author">
              <span className="message-author-name">{item.fromNickname}</span>
              <span className="message-content-author-time">
                {moment(item.createTime).format('MM-DD HH:mm')}
              </span>
            </div>
            <div className="message-content-detail">
              {Message(props)}
              <div className="message-coentetn-detail-promopt">
                {(() => {
                  if (item.status === 1) {
                    return '消息已撤回';
                  }
                  return item.comment || '';
                })()}
              </div>
            </div>
            {props.hover && <div className="message-footer">{props.hover}</div>}
          </div>
        </div>
      </div>
    </div>
  );
});

export default MessageItem;
