import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import * as reducers from './reducer';
import rootSaga from './saga';

const reducer = combineReducers(reducers);
const saga = createSagaMiddleware();

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(saga),
    // other store enhancers if any
  ),
);

saga.run(rootSaga);

export default store;
