/* *
 * 展示所有类型的聊天记录，格式类似聊天页面，支持定位到某一条消息高亮显示
 * * props
 * * * canUp: Boolean， 是否支持上拉加载
 * * * mid: String, 高亮显示的记录id
 * * * scrollImmediately: Boolean，是否在初始化的时候滚动到相应的位置
 * * * searchBar: Boolean， 是否有搜索栏
 * */

import React, { PureComponent } from 'react';
import { Spin, Empty } from 'antd';
import request from '@/http/request';
import MessageItem from '@/components/MessageItem';
import './index.less';

export default class RecordList extends PureComponent<any, any> {
  static defaultProps = {
    canUp: true,
    searchBar: false,
    scrollImmediately: false,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      list: [],
      downNextIndex: '',
      upNextIndex: '',
      isLoading: false,
      isUpEnd: false, // 向上滚动
      isDownEnd: false, // 向下滚动
      upDownFlag: 0,
    };
  }

  scroll: any = React.createRef();

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(perProps: any) {
    if (perProps.sessionId !== this.props.sessionId || perProps.mid !== this.props.mid) {
      this.reset();
    }
  }

  reset = () => {
    this.setState(
      {
        list: [],
        isLoading: false,
        isUpEnd: false,
        upNextIndex: '',
        downNextIndex: '',
        isDownEnd: false,
        upDownFlag: 0,
      },
      () => {
        this.init();
      },
    );
  };

  init = () => {
    if (!this.props.canUp) {
      this.loadMore(0);
      this.addScrollListener();
    }
    if (this.props.scrollImmediately && this.props.mid) {
      this.goToMessage({ mid: this.props.mid });
    }
  };

  addScrollListener = () => {
    if (this.scroll) {
      this.scroll.current.addEventListener('scroll', (e: any) => {
        if (
          e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight &&
          this.props.canUp
        ) {
          // 向下滚动，，加载最近时间的
          this.loadMore(1);
        }
        if (e.target.scrollTop <= 0) {
          this.loadMore(0);
        }
      });
    }
  };

  loadMore(upDownFlag: number) {
    if (this.state.isLoading || (upDownFlag && this.state.isDownEnd)) return; // 下拉没有了
    if (this.state.isLoading || (!upDownFlag && this.state.isUpEnd)) return; // 上拉没有了
    this.setState(
      (perState: any) => {
        return {
          isLoading: true,
          upDownFlag,
        };
      },

      () => {
        const start = upDownFlag ? this.state.downNextIndex : this.state.upNextIndex;
        this.fetchData(upDownFlag, start).then((res: any) => {
          if (res && res.data) {
            const mid = res.data.list && res.data.list[0] && res.data.list[0].mid;
            this.setState(
              (perState: any) => {
                if (upDownFlag) {
                  res.data.list.shift();
                  return {
                    list: [...perState.list, ...res.data.list],
                    isDownEnd: res.data.isEnd,
                    downNextIndex: res.data.nextIndex,
                    nextIndex: res.data.nextIndex,
                  };
                }
                return {
                  list: [...res.data.list.reverse(), ...perState.list],
                  isUpEnd: res.data.isEnd,
                  upNextIndex: res.data.nextIndex,
                  nextIndex: res.data.nextIndex,
                };
              },
              () => {
                if (!upDownFlag && mid) {
                  this.scrollToMid(mid, 0);
                }
                setTimeout(() => {
                  this.setState({
                    isLoading: false,
                  });
                }, 500);
              },
            );
          }
        });
      },
    );
  }

  fetchData = (upDownFlag: number, start: string) => {
    return request
      .get(`/im/v2/sessions/${this.props.sessionId}/message/archive`, {
        start,
        upDownFlag,
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          upDownFlag,
        });
      });
  };

  goToMessage = (message: any) => {
    this.setState(
      {
        list: [],
      },
      () => {
        // 以message.mid为中点，取前后的数据
        this.fetchData(0, message.mid).then((res: any) => {
          this.setState({
            upNextIndex: res.data.nextIndex,
            isUpEnd: res.data.isEnd,
          });

          this.fetchData(1, message.mid).then((res1: any) => {
            res1.data.list.shift();
            this.setState(
              {
                downNextIndex: res1.data.nextIndex,
                list: [...res.data.list.reverse(), ...res1.data.list],
                isDownEnd: res1.data.isEnd,
              },
              () => {
                this.scrollToMid(message.mid);
                this.addScrollListener();
              },
            );
          });
        });
      },
    );
  };

  scrollToMid = (mid: string, offset = 200) => {
    // 滚动到中间的位置
    const midElement: any = document.getElementById(`message${mid}`);
    try {
      const height = midElement.offsetTop - offset;
      if (this.scroll) {
        this.scroll.current.scrollTop = height;
      }
    } catch (error) {
      console.log(error);
    }
  };

  refresh = () => {
    this.setState(
      (perState: any) => {
        return {
          isDownEnd: false,
          nextIndex: perState.list[perState.list.length - 1].mid,
        };
      },
      () => {
        this.loadMore(1);
      },
    );
  };

  render() {
    const { list, isLoading, upDownFlag, isUpEnd } = this.state;
    const { currClientWxId, mid } = this.props;
    return (
      <div className="record-list">
        <div
          className={this.props.searchBar ? 'scroll-box' : 'scroll-box-search'}
          // className="scroll-box"
          ref={this.scroll}
        >
          {/* {isUpEnd && <Empty description="没有消息啦" image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>} */}
          <Spin
            tip="Loading..."
            spinning={isLoading && upDownFlag === 0}
            style={{ margin: ' 10px auto', textAlign: 'center', width: '100%' }}
          ></Spin>
          {list.map((item: any, index: number) => (
            <MessageItem
              right={item.fromWxid === currClientWxId}
              item={item}
              key={item.mid}
              id={`message${item.mid}`}
              highlight={mid === item.mid}
            ></MessageItem>
          ))}
          <Spin
            tip="Loading..."
            spinning={isLoading && upDownFlag === 1}
            style={{ margin: ' 10px auto', textAlign: 'center', width: '100%' }}
          ></Spin>
        </div>
      </div>
    );
  }
}
