import { BaseAction } from '../types/action';
import { ACTION_SET_CURRENT_WINDOWTAB } from '../constant';

export default (state: any = '1', action: BaseAction) => {
  switch (action.type) {
    case ACTION_SET_CURRENT_WINDOWTAB:
      return action.data;
    default:
      return state;
  }
};
