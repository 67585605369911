import { WxFriend } from '../types/state';
import { BaseAction } from '../types/action';
import { ACTION_SET_FRIEND_LIST, ACTION_MERGE_FRIEND_LIST } from '../constant/index';

export default (state: WxFriend[] = [], action: BaseAction) => {
  switch (action.type) {
    case ACTION_SET_FRIEND_LIST:
      return action.data;
    case ACTION_MERGE_FRIEND_LIST:
      return [...state, ...action.data];
    default:
      return state;
  }
};
