import React, { useCallback } from 'react';
import { Tooltip } from 'antd';
import LazyLoad from 'react-lazyload';
import styles from './UserItem.module.less';

function PlaceHolderComponent() {
  return (
    <img
      className={styles['chat-item-avatar-placeholder']}
      src="/defaultHead.jpg"
      alt="placeHolader"
    />
  );
}

interface Props {
  id: string;
  name: string;
  avatar: string;
  onClick: () => void;
  type: string;
  wxAlias: any;
}

const UserItem: React.FC<Partial<Props>> = props => {
  const { name, avatar, onClick, wxAlias } = props;
  return (
    <Tooltip placement="right" title={`${name || '未命名'}`}>
      <div className={styles['user-item']} onClick={onClick}>
        <LazyLoad height={38} resize overflow placeholder={<PlaceHolderComponent />}>
          <img
            className={styles['user-item-avatar']}
            src={avatar || '/defaultHead.jpg'}
            alt="head"
          />
        </LazyLoad>
        <div className={styles['user-item-info']}>
          <div className={styles['user-item-name']}>
            {name || '未命名'}
            <br />
          </div>
          {wxAlias ? <div className={styles['user-item-wxid']}>微信号：{wxAlias}</div> : ''}
        </div>
      </div>
    </Tooltip>
  );
};

export default UserItem;
