import React, { Component } from 'react';
import { Button, Popconfirm } from 'antd';
import moment from 'moment';
import request from '@/http/request';
import IconFont from '@/components/IconFont';
import MessageSender from '../../../../component/MessageSender';
import EmojiSuggestions from '../../../../component/EmojiSuggestions';

interface State {
  pos: any;
  editorValue: string;
  showEditor: boolean;
  replyIndex: any;
  showIconIndex: any;
  showReplyIndex: any;
  commentList: any[];
  likeList: any[];
  isLiked: boolean;
}

class Communication extends Component<any, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      pos: { start: 0, end: 0 },
      editorValue: '',
      showEditor: false,
      replyIndex: '',
      showIconIndex: '',
      showReplyIndex: '',
      commentList: this.props.commentList,
      likeList: this.props.likeList,
      isLiked: this.props.isLiked,
    };
  }

  likeItemClick = () => {
    const { isLiked, likeList } = this.state;
    const { target } = this.props;
    const data = {
      wxId: this.props.currClientWxId,
      snsId: this.props.snsId,
      friendWxId: target.type === 'TARGET_TYPE_FRIEND' ? target.id : target.chatId,
    };
    if (!isLiked) {
      request.post('im/v2/sns/like', data).then((res: any) => {
        if (res.code === 200) {
          this.setState({
            isLiked: true,
            likeList: [
              ...likeList,
              { nickname: this.props.currClientNickname, wxId: this.props.currClientWxId },
            ],
          });
        }
      });
    } else {
      request.delete('im/v2/sns/like', data).then((res: any) => {
        if (res.code === 200) {
          const list = likeList.filter((like: any) => like.wxId !== this.props.currClientWxId);
          this.setState({
            isLiked: false,
            likeList: list,
          });
        }
      });
    }
  };

  confirmComment = (index?: number) => {
    const { commentList } = this.state;
    const currComment = commentList[index || 0];
    const { target } = this.props;
    request
      .post('im/v2/sns/comment', {
        wxId: this.props.currClientWxId,
        snsId: this.props.snsId,
        content: this.state.editorValue,
        replyWxId: index !== undefined ? currComment.wxId : target.chatId,
        commentId: index !== undefined ? currComment.id : '',
      })
      .then((res: any) => {
        if (res.code === 200) {
          let dataObj = {
            content: this.state.editorValue,
            createTime: new Date(),
            id: res.data.commentId,
            nickname: this.props.currClientNickname,
            wxId: this.props.currClientWxId,
          };
          dataObj = !this.state.showEditor
            ? {
                ...dataObj,
                ...{
                  isDeleteable: index !== undefined,
                  replyCommentId: currComment.id,
                  replyNickname: currComment.nickname,
                  replyWxId: currComment.wxId,
                },
              }
            : { ...dataObj, ...{ isDeleteable: true } };
          this.setState({
            commentList: [...commentList, dataObj],
            showEditor: false,
            replyIndex: '',
            editorValue: '',
          });
        }
      });
  };

  deleteComment = (index: number) => {
    const { commentList } = this.state;
    request
      .delete('im/v2/sns/comment', {
        wxId: this.props.currClientWxId,
        snsId: this.props.snsId,
        commentId: commentList[index].id,
      })
      .then((res: any) => {
        if (res.code === 200) {
          const list = commentList.filter((comment: any) => comment.id !== commentList[index].id);
          this.setState({
            commentList: list,
          });
        }
      });
  };

  handlerImgTextChange = (value: string) => {
    const { editorValue, pos } = this.state;
    const newString =
      editorValue.substring(0, pos.start) +
      value +
      editorValue.substring(pos.end, editorValue.length);
    this.setState({
      editorValue: newString,
      pos: {
        start: pos.start + value.length,
        end: pos.start + value.length,
      },
    });
  };

  handleChange = (e: any) => {
    const s = this.state;
    this.setState({
      ...s,
      ...e,
    });
  };

  render() {
    const { time } = this.props;
    const {
      pos,
      editorValue,
      showEditor,
      replyIndex,
      showIconIndex,
      commentList,
      likeList,
      isLiked,
    } = this.state;
    const editor = (index?: number) => (
      <div className="chat-pannel2">
        <div className="chat-pannel-header2">
          <div className="chat-panel-header-left">
            <EmojiSuggestions onSelectChange={this.handlerImgTextChange} />
          </div>
        </div>
        <div className="chat-pannel-content2">
          <MessageSender
            pos={pos}
            editorValue={editorValue}
            onChange={this.handleChange}
            placeholder="限制200个字"
          ></MessageSender>
        </div>
        <div className="chat-pannel-bootom2">
          <Button
            size="small"
            className="send-btn-default"
            onClick={() => {
              this.setState({ showEditor: false, replyIndex: '' });
            }}
          >
            取消
          </Button>
          <Button
            size="small"
            ghost
            type="primary"
            className="send-btn-default"
            style={{ margin: '0 10px' }}
            onClick={() => this.confirmComment(index)}
          >
            确认
          </Button>
        </div>
      </div>
    );

    return (
      <div>
        <div className="moments-infos">
          <span className="time">{moment(`${time}`).format('MM-DD HH:mm')}</span>
          <div className="icons">
            <span style={{ color: isLiked ? '#389E0D' : '' }}>
              <IconFont
                style={{ fontSize: '14px', padding: '0 8px' }}
                iconfont="iconpengyouquandianzan"
                onClick={this.likeItemClick}
              ></IconFont>
              <span>{likeList.length}</span>
            </span>
            <IconFont
              style={{ fontSize: '14px', padding: '0 8px' }}
              iconfont="iconpengyouquanpinglun"
              onClick={() => {
                this.setState({ showEditor: true, replyIndex: '' });
              }}
            ></IconFont>
            <span>{commentList.length}</span>
          </div>
        </div>
        {likeList.length ? (
          <div className="moments-likes">
            <IconFont
              style={{
                fontSize: '18px',
                position: 'relative',
                left: '6px',
                top: '3px',
                paddingRight: '12px',
              }}
              iconfont="iconpengyouquandianzan"
            ></IconFont>
            {likeList.map((like: any, index: number) => (
              <span key={index}>
                {like.nickname}
                {index + 1 !== likeList.length ? '、' : ''}
              </span>
            ))}
          </div>
        ) : (
          ''
        )}

        {commentList.length || showEditor ? (
          <div className="moments-comments">
            {commentList.map((comment: any, index: number) => (
              <div
                key={index}
                className="comments-block"
                onMouseEnter={() => {
                  this.setState({ showIconIndex: index });
                }}
                onMouseLeave={() => {
                  this.setState({ showIconIndex: '' });
                }}
              >
                <div>
                  <span style={{ color: '#092B00', fontWeight: 500 }}>{comment.nickname}</span>
                  <span style={{ color: '#BFBFBF', paddingLeft: '8px' }}>
                    {moment(`${comment.createTime}`).format('MM-DD HH:mm')}
                  </span>
                  {showIconIndex === index && comment.isDeleteable ? (
                    <Popconfirm
                      placement="topRight"
                      title="确定要删除该评论?"
                      onConfirm={() => this.deleteComment(index)}
                      okText="确定"
                      cancelText="取消"
                    >
                      <span className="moments-oper">删除</span>
                    </Popconfirm>
                  ) : (
                    ''
                  )}
                  {showIconIndex === index && !comment.isDeleteable ? (
                    <span
                      className="moments-oper"
                      onClick={() => this.setState({ replyIndex: index, showEditor: false })}
                    >
                      回复
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {comment.replyWxId ? (
                    <span style={{ color: '#BFBFBF' }}>
                      回复 <span style={{ color: '#092B00' }}>{comment.replyNickname} </span>
                    </span>
                  ) : (
                    ''
                  )}
                  <span style={{ color: '#262626' }}>{comment.content}</span>
                  {replyIndex === index ? editor(index) : ''}
                </div>
              </div>
            ))}
            {showEditor ? <div className="comments-block">{editor()}</div> : ''}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default Communication;
