import { BaseAction } from '../types/action';
import { ACTION_GET_SESSION_IS_END, ACTION_SET_SESSION_IS_END } from '../constant';

export function createGetSessionIsEndAction(param: any): BaseAction {
  return {
    type: ACTION_GET_SESSION_IS_END,
    param,
  };
}

export function createSetSessionIsEndAction(data: any): BaseAction {
  return {
    type: ACTION_SET_SESSION_IS_END,
    data,
  };
}
