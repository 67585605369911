import React from 'react';
import { Tabs, Select, Divider } from 'antd';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';
import { State, WxSession, Target } from '@/store/types/state';
import {
  createGetWxSessionListAction,
  createSetCurrentTargetAction,
  createUpdateSingleSessionAction,
  deleteSyncSendMessageAction,
  createUpdateWxMessageListStatus,
  setCurrentTabAction,
  setCurrentSessionListFilterCondition,
  setCurrentWindowTabAction,
  createGetMockSessionListAction,
  createUpdateCurrClientInfoAction,
} from '@/store/action';
import eventBus from '@/ws/event';
import { getParamValue } from '@/util';

import { TARGET_TYPE_SESSION } from '@/store/constant';
import ChatItem from '../../../../component/ChatItem';
import FriendList from './components/FriendList';
import GroupList from './components/GroupList';
import './index.less';

const { Option } = Select;

function compareSessionList(list: any[], nextList: any[]) {
  if (list.length !== nextList.length) {
    return false;
  }
  for (let i = 0; i < list.length; i += 1) {
    if (list[i].id !== nextList[i].id) {
      return false;
    }
  }
  return true;
}

const tabFontSize = {
  fontSize: '14px',
};

interface Prop {
  clients: any;
  currentTarget: Target;
  currentSiderTab: string;
  currentSessionListFilterCondition: any;
  sessionList: WxSession[];
  sessionIsEnd: boolean | number;
  getSessionList: (options?: any) => void;
  setCurrentTarget: (data: Target | null) => any;
  updateSingleSession: (data: any) => any;
  deleteSyncMessage: () => any;
  updateMessageListStatus: (data: any, sessionid: number) => any;
  setCurrentTab: (data: string) => void;
  setCurrentSessionListFilterConditionFn: (data: any) => void;
  setCurrentWindowTab: (data: string) => void;
  setMockSessionlist: (data: any) => any;
  updateSingleClient: (data: any) => any;
}

class SiderTab extends React.Component<Prop> {
  componentDidMount() {
    // 会话列表初始轮询
    this.pollingFn();
    eventBus.on('updateSessionList', () => this.hackScrollMove());
    if (this.props.sessionList.length) {
      this.initSession();
    }
  }

  isLoading: boolean = false;

  componentDidUpdate(prevProps: Prop) {
    const {
      clients: { current },
      currentSiderTab,
    } = this.props;
    const prevTarget = prevProps.clients && prevProps.clients.current;
    if (current && prevTarget) {
      // 切换账号时的逻辑判断
      if (current.wxId !== prevTarget.wxId && currentSiderTab === '1') {
        this.pollingClearFn();
        this.pollingFn();
      }
    }
    if (!prevProps.sessionList.length && this.props.sessionList.length) {
      this.initSession();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Prop) {
    // 建群后初始化会话列表选中状态
    if (
      this.props.currentTarget &&
      nextProps.sessionList &&
      compareSessionList(this.props.sessionList, nextProps.sessionList) &&
      (nextProps.sessionList[0] && nextProps.sessionList[0].id) !==
        (this.props.sessionList[0] && this.props.sessionList[0].id)
    ) {
      const chatItem = nextProps.sessionList.filter(
        item => item.id === this.props.currentTarget.id,
      );
      if (chatItem.length) {
        this.handlerChatItemClick(Object.assign(chatItem[0], { isLoading: true }));
      }
    }
  }

  componentWillUnmount() {
    this.pollingClearFn();
  }

  pollingTimer: any = '';

  scrollBoxRef: any = '';

  // 根据url参数，打开相应窗口
  initSession = () => {
    const chatId = getParamValue('chatId');
    if (chatId) {
      const target = this.props.sessionList.find((session: WxSession) => session.chatId === chatId);
      if (target) {
        this.handlerChatItemClick(target);
      }
    }
  };

  setCacheSessionList = () => {
    const {
      setMockSessionlist,
      clients: { current: currentClient },
    } = this.props;
    const currClientWxId = currentClient && currentClient.wxId;
    if (!currClientWxId && localStorage.getItem(`${currClientWxId}-sessionList`)) return;
    const storageData = localStorage.getItem(`${currClientWxId}-sessionList`) || '';
    let cache: any;
    try {
      cache = JSON.parse(storageData);
    } catch (e) {
      throw new Error(e);
    }
    if (cache) {
      setMockSessionlist(cache);
    }
  };

  // hack方法，滚动1px解决lazy-load组件带来的渲染问题
  hackScrollMove = () => {
    if (this.scrollBoxRef) {
      this.scrollBoxRef.scrollTop = this.scrollBoxRef.scrollTop + 1;
    }
  };

  handlerChatItemClick = (chatItem: any) => {
    if (!chatItem) return;
    let number = chatItem.newUnread || 0;
    const { id, chatType, status, newUnread } = chatItem;
    if (newUnread > 99) {
      number = 99;
    }
    if (this.props.currentTarget && this.props.currentTarget.id === id) return;
    // 重置请求messageList的控制参数
    this.props.updateMessageListStatus(
      {
        messageIsEnd: false,
        messageNextIndex: '',
      },
      id,
    );
    this.props.setCurrentTarget({
      id,
      type: TARGET_TYPE_SESSION,
      isSingle: chatType === 1,
      chatId: chatItem.chatId,
      currSessionUnreadNum: number,
      isLoading: chatItem && chatItem.isLoading,
      chatType,
      nickname: chatItem && chatItem.nickname,
      avatar: chatItem && chatItem.headImg,
    });
    // 若当前会话的消息数清0，更新对应账号消息数-1
    const { clients, updateSingleClient } = this.props;
    const currClient = clients && clients.current;
    const currClientNewUnread = currClient.newUnread;
    if (!chatItem.silence && newUnread) {
      updateSingleClient({
        ...currClient,
        newUnread: currClientNewUnread - 1,
      });
    }
    this.props.deleteSyncMessage();
    if (status) {
      this.props.updateSingleSession({
        sessionId: id,
      });
    }
    // this.props.setCurrentWindowTab('1');
  };

  handlerUserItemClick = (id: any, type: string) => {
    this.props.setCurrentTarget({ id, type });
  };

  handlerTabChange = (key: any) => {
    this.props.setCurrentTab(key);
  };

  pollingFn = () => {
    this.pollingTimer = setInterval(() => {
      this.props.getSessionList({ limit: 30, polling: true });
    }, 19000);
  };

  /*
   * 会话列表轮询
   */
  pollingClearFn = () => {
    clearInterval(this.pollingTimer);
  };

  // 会话筛选；0：全部；1：只看未读；2：只看好友；3：只看群聊
  sessionFilter = (val: any) => {
    const { setCurrentSessionListFilterConditionFn, getSessionList, setCurrentTarget } = this.props;
    setCurrentSessionListFilterConditionFn(val);
    getSessionList({ limit: 30 });
    setCurrentTarget(null);
  };

  // tab切换时请求对应列表数据
  tabChangeRequest() {
    const { getSessionList, currentSiderTab } = this.props;
    if (currentSiderTab === '1') {
      getSessionList();
      // 会话列表轮询
      this.pollingFn();
      return;
    }
    this.pollingClearFn();
  }

  // 新增会话列表下拉加载更多
  scrollHandler = (e: any) => {
    if (this.isLoading) return;
    const { scrollTop, scrollHeight, offsetHeight } = e.target;
    if (scrollTop + 1000 >= scrollHeight - offsetHeight) {
      if (this.props.sessionIsEnd === true) return;
      this.isLoading = true;
      this.props.getSessionList({
        start: this.props.sessionIsEnd,
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }
  };

  render() {
    const {
      sessionList,
      currentTarget,
      currentSiderTab,
      currentSessionListFilterCondition,
      clients,
    } = this.props;
    // const obj: any = {};
    return (
      <Tabs
        defaultActiveKey="1"
        animated={false}
        activeKey={currentSiderTab}
        className="sider-tab"
        onChange={this.handlerTabChange}
        tabBarStyle={tabFontSize}
      >
        <Tabs.TabPane key="1" tab={<span style={tabFontSize}>会话</span>}>
          <div>
            <Select
              style={{ display: 'block', margin: 10, width: 120 }}
              onChange={this.sessionFilter}
              value={
                currentSessionListFilterCondition !== '0'
                  ? currentSessionListFilterCondition
                  : '全部'
              }
            >
              <Option value="0">全部</Option>
              <Option value="1">只看未读</Option>
              <Option value="2">只看好友</Option>
              <Option value="3">只看群聊</Option>
            </Select>
          </div>
          <LazyLoad resize overflow>
            <div
              className="scroll-box"
              ref={node => {
                this.scrollBoxRef = node;
              }}
              id="scroll-box-0"
              onScroll={e => this.scrollHandler(e)}
            >
              {sessionList
                // .reduce((cur: any[], next: WxSession) => {
                //   if (!next || !next.id) {
                //     console.log('next:::::::::::::::::::::', next);
                //   }
                //   obj[next.id] ? '' : (obj[next.id] = true && cur.push(next));
                //   return cur;
                // }, [])
                .map((item: WxSession) => (
                  <div
                    key={item.id}
                    data-session-id={item.id}
                    data-last-message-id={item.lastMsg && item.lastMsg.mid}
                    style={{
                      position: 'relative',
                    }}
                    className={`${item.status ? 'chatitem-ignore' : ''}${
                      currentTarget && item.id === currentTarget.id ? ' chatitem-active' : ''
                    }`}
                  >
                    {/* <Link to={`#${item.id}`}> */}
                    <ChatItem
                      item={item}
                      key={item.id}
                      clients={clients}
                      currentTarget={currentTarget}
                      chatItemClick={() => this.handlerChatItemClick(item)}
                    />
                    {/* </Link> */}
                    <Divider
                      style={{
                        margin: '0px 16px',
                        width: '90%',
                        minWidth: 'unset',
                        position: 'absolute',
                      }}
                    />
                  </div>
                ))}
            </div>
          </LazyLoad>
        </Tabs.TabPane>
        <Tabs.TabPane key="2" tab={<span style={tabFontSize}>好友</span>}>
          <FriendList />
        </Tabs.TabPane>
        <Tabs.TabPane key="3" tab={<span style={tabFontSize}>群</span>}>
          <GroupList />
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

function mapStateToProps(state: State) {
  const {
    currentTarget,
    clients,
    currentSiderTab,
    currentSessionListFilterCondition,
    sessionList,
    sessionIsEnd,
  } = state;
  return {
    currentTarget,
    clients,
    currentSiderTab,
    currentSessionListFilterCondition,
    sessionList,
    sessionIsEnd,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getSessionList: (options: any = {}) => dispatch(createGetWxSessionListAction(options)),
    setCurrentTarget: (data: Target | null) => dispatch(createSetCurrentTargetAction(data)),
    updateSingleSession: (data: any) => dispatch(createUpdateSingleSessionAction(data)),
    deleteSyncMessage: () => dispatch(deleteSyncSendMessageAction()),
    updateMessageListStatus: (params: any, sessionId: number) =>
      dispatch(createUpdateWxMessageListStatus(params, sessionId)),
    setCurrentTab: (data: string) => dispatch(setCurrentTabAction(data)),
    setCurrentSessionListFilterConditionFn: (data: any) =>
      dispatch(setCurrentSessionListFilterCondition(data)),
    setCurrentWindowTab: (data: string) => dispatch(setCurrentWindowTabAction(data)),
    setMockSessionlist: (data: any) => dispatch(createGetMockSessionListAction(data)),
    updateSingleClient: (data: any) => dispatch(createUpdateCurrClientInfoAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SiderTab);
