import React from 'react';
import { Tabs, Empty } from 'antd';
import './index.less';
import moment from 'moment';
import NewGroupItem from './NewGroupItem';
import request from '@/http/request';
import store from '@/store';

moment.locale('zh-cn');

const { TabPane } = Tabs;

interface Prop {
  friendApplyList: any[];
}
interface State {
  activeKey: string;
  groupApplyList: any[];
}
class NewGroupApply extends React.Component<Prop, State> {
  constructor(props: Prop) {
    super(props);
    this.state = {
      activeKey: '1',
      groupApplyList: [],
    };
  }

  componentDidMount() {
    this.getGroupList();
  }

  getGroupList = () => {
    const { clients } = store.getState();
    request
      .get('/im/v2/group/apply/list', {
        wx_id: clients && clients.current.wxId,
        page: 0,
        perpage: 5000,
        status: 0,
      })
      .then((res: any) => {
        this.setState({
          // groupApplyList: this.combineDataList(res.data.list),
          groupApplyList: res.data.list,
        });
      });
  };

  replyAllowGroupApply(id: any) {
    request.post(`/v2/group/apply/${id}`).then((res: any) => {
      this.getGroupList();
    });
  }

  replyIgnoreGroupApply(id: any) {
    request.post(`/im/v2/group/apply/${id}/ignore`).then((res: any) => {
      this.getGroupList();
    });
  }

  combineDataList = (list: any[]) => {
    let result = [];
    result = list.map((item, index) => {
      const { apply } = item;
      return {
        key: index,
        id: apply.id,
        memberCount: apply.memberCount,
        memberList: apply.memberList,
      };
    });
    return result;
  };

  filterGroups = () => {
    const { activeKey } = this.state;
    const list = this.state.groupApplyList.filter((group: any) => {
      if (activeKey === '2' && group.status === 0) {
        // 待处理
        return true;
      }
      if (activeKey === '3' && group.status === 1) {
        // 已通过
        return true;
      }
      if (activeKey === '4' && group.status === 3) {
        // 已忽略
        return true;
      }
      if (activeKey === '5' && group.status === 2) {
        // 已过期
        return true;
      }
      if (activeKey === '1') {
        return true;
      }
      return false;
    });
    return list;
  };

  render() {
    const { activeKey } = this.state;
    // const dataSource = this.combineData(currentList);
    return (
      <Tabs
        defaultActiveKey={activeKey}
        onChange={(e: any) => {
          this.setState({
            activeKey: e,
          });
        }}
        tabBarStyle={{ padding: '0 16px', border: 0 }}
      >
        {['全部', '需处理', '已通过', '已忽略', '已过期'].map((name, index) => (
          <TabPane
            key={`${index + 1}`}
            tab={<span style={{ fontSize: '12px', color: 'color:rgba(0,0,0,0.4)' }}>{name}</span>}
          >
            {this.filterGroups().length ? (
              // this.filterGroups().map((item: any, index: number) => (
              <NewGroupItem
                data={this.filterGroups()}
                onClick={(id: any) => {
                  this.replyAllowGroupApply(id);
                }}
                onIgnoreClick={(id: any) => {
                  this.replyIgnoreGroupApply(id);
                }}
              />
            ) : (
              // ))
              <Empty className="im-empty-status" image="/noinvite.png" description="没有群申请" />
            )}
          </TabPane>
        ))}
      </Tabs>
    );
  }
}

export default NewGroupApply;
