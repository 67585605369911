import React, { memo } from 'react';
import './Audio.less';

let timer: any = null;

const setAudio = (url: string, current: any, duration: number) => {
  if (timer) clearTimeout(timer);
  const currentElement = current;
  const classString = 'audio-icon';
  const activeClass = `${classString}-active`;
  let audio = document.querySelector('audio');
  currentElement.className = activeClass;
  if (!audio) {
    const element = document.createElement('audio');
    element.src = url;
    document.body.append(element);
    audio = document.querySelector('audio');
    if (audio) audio.play();
  } else if (audio.src === url) {
    if (audio.paused) {
      audio.play(); // 播放
    } else {
      audio.pause(); // 暂停
    }
  } else {
    audio.src = url;
    audio.play(); // 播放
  }
  timer = setTimeout(() => {
    currentElement.className = classString;
    if (audio) audio.pause(); // 暂停
    clearTimeout(timer);
  }, duration);
};

const Audio = memo((props: any) => {
  let current: any = null;
  return (
    <div className="records-audio">
      <div
        className={`${props.right ? 'wrapperRight' : 'wrapper'}`}
        onClick={() => {
          setAudio(props.audioUrl, current, props.duration);
        }}
      >
        <div
          className="audio"
          style={{ borderRadius: props.right ? '0 18px 18px 18px' : '0 18px 18px 18px' }}
        >
          <div
            className="audio-icon"
            ref={node => {
              current = node;
            }}
          ></div>
        </div>
        <span className="duration">
          {Math.ceil(props.duration / 1000)}
          {'″'}
        </span>
      </div>
    </div>
  );
});

export default Audio;
