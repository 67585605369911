import React from 'react';
import { Icon } from 'antd';
import Image from '../../../../../../../components/Image';

interface IShareMessageItem {
  msgContent: any;
  mid?: any;
  getChatInvitationInfo: (e: string) => any;
}
type IShareMessageItemProps = Readonly<IShareMessageItem>;

function subStringContent(str: string, max: number) {
  if (str && str.length > max) {
    return `${str.substring(0, max)}...`;
  }
  return str;
}
const ShareMessageItem: React.FC<IShareMessageItemProps> = props => {
  return (
    <div
      className="message-text-wrapper"
      style={{
        background: 'white',
        textAlign: 'left',
      }}
    >
      {(() => {
        if (typeof props.mid === 'number') {
          if (props.mid > 0) {
            return (
              <Icon
                type="loading-3-quarters"
                className="msgloading"
                spin
                style={{ zoom: '100%', color: 'rgba(0, 0, 0, 0.25)' }}
              />
            );
          }
          return (
            <Icon
              type="exclamation-circle"
              className="msgloading"
              theme="filled"
              style={{ zoom: '100%', color: 'red' }}
            />
          );
        }
        return '';
      })()}
      <div className="message-share">
        <span
          className="app"
          onClick={() => {
            const url = props.msgContent && props.msgContent.url;
            if (
              url &&
              url.indexOf('support.weixin.qq.com/cgi-bin/mmsupport-bin/addchatroombyinvite') > 0
            ) {
              props.getChatInvitationInfo(url);
            } else {
              window.open(props.msgContent && props.msgContent.url, 'blank');
            }
          }}
        >
          <div className="title">
            {subStringContent((props.msgContent && props.msgContent.title) || '-', 25)}
          </div>
          <div style={{ display: 'flex', color: '#8C8C8C', fontSize: 12 }}>
            <div style={{ width: 180 }}>{subStringContent(props.msgContent.desc, 28)}</div>
            <Image
              src={props.msgContent && props.msgContent.imageUrl}
              fallbackURL="./null.png"
              className="cover"
              errorURL="./imgError.png"
            />
          </div>
        </span>
      </div>
    </div>
  );
};

export default ShareMessageItem;
