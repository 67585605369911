import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

interface TimerPickerProps {
  startTime: string;
  endTime: string;
  onOk: (endTime: string, startTime: string) => void;
  allowClear?: boolean;
}

const onTimeChange = (e: any, onOk: any, isSearch = true) => {
  if (e && e[1]) {
    const endTime = e[1].format('YYYY-MM-DD HH:mm:ss');
    const startTime = e[0].format('YYYY-MM-DD HH:mm:ss');
    onOk(startTime, endTime, isSearch);
  } else {
    onOk('', '', isSearch);
  }
};

const style = {
  width: '100%',
  textAlign: 'left',
};

const TimerPicker = (props: TimerPickerProps) => {
  const { startTime, endTime, onOk, allowClear } = props;
  return startTime && endTime ? (
    <DatePicker.RangePicker
      key="232"
      onOk={(e: any) => onTimeChange(e, onOk)}
      format="YYYY-MM-DD HH:mm"
      value={[moment(startTime, 'YYYY-MM-DD HH:mm'), moment(endTime, 'YYYY-MM-DD HH:mm')]}
      showTime
      onChange={(e: any) => onTimeChange(e, onOk, false)}
      placeholder={['开始时间', '结束时间']}
      style={{ width: '100%', textAlign: 'left' }}
      allowClear={allowClear || false}
    />
  ) : (
    <DatePicker.RangePicker
      key="2222"
      onOk={(e: any) => onTimeChange(e, onOk)}
      onChange={(e: any) => onTimeChange(e, onOk, false)}
      format="YYYY-MM-DD HH:mm"
      showTime
      style={{ width: '100%', textAlign: 'left' }}
      allowClear={allowClear || false}
      placeholder={['开始时间', '结束时间']}
    />
  );
};

export default TimerPicker;
