import React, { Component } from 'react';
import { is, fromJS } from 'immutable';
import ReactDOM from 'react-dom';
import { Modal, Button } from 'antd';

const defaultState: any = {
  title: '',
  childs: '',
  width: 520,
  cancelText: '取消',
  okText: '确定',
  visible: false,
  onOk: () => {},
  onCancel: () => {},
  hasFooter: true,
};
class Dialog extends Component<any> {
  state = {
    ...defaultState,
  };

  shouldComponentUpdate(nextProps: any, nextState: any): any {
    return !is(fromJS(this.props), fromJS(nextProps)) || !is(fromJS(this.state), fromJS(nextState));
  }

  // 打开弹窗
  open = (options: any = {}): void => {
    const childs = this.renderChildren(options.props || {}, options.childrens) || '';
    this.setState({
      ...defaultState,
      ...options,
      visible: true,
      childs,
    });
  };

  close = (): void => {
    this.setState({
      visible: false,
    });
  };

  onOk = (): void => {
    this.state.onOk();
    this.close();
  };

  onCancel = (): void => {
    this.state.onCancel();
    this.close();
  };

  renderChildren(props: any, childrens: any = []) {
    // 遍历所有子组件
    const childs: any[] = [];
    const ps: any = {
      ...props, // 给子组件绑定props
      _close: this.close, // 给子组件也绑定一个关闭弹窗的事件
    };
    childrens.forEach((currentItem: any, index: number) => {
      childs.push(
        React.createElement(currentItem, {
          ...ps,
          key: index,
        }),
      );
    });
    return childs;
  }

  render() {
    return this.state.hasFooter ? (
      <Modal {...this.state} onOk={this.onOk} onCancel={this.onCancel}>
        {this.state.childs}
      </Modal>
    ) : (
      <Modal {...this.state} onOk={this.onOk} onCancel={this.onCancel} footer={null}>
        {this.state.childs}
      </Modal>
    );
  }
}

const div = document.createElement('div');
document.body.appendChild(div);
const Box: any = React.createRef();
ReactDOM.render(<Dialog ref={Box} />, div);

const ShowModal = (props: any) => {
  Box.current.open(props);
};

ShowModal.close = () => {
  Box.current.close();
};

export default ShowModal;
