import React from 'react';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import WaringPop from '@/components/WaringPop';
import './index.less';

moment.locale('zh-cn');

interface Prop {
  serverTitle: string;
  onClick: () => void;
  onIgnoreClick: () => void;
  userInfo: {
    fromWxId: string;
    ticket: string;
    nickname: string;
    headImg: string;
    content: string;
    status: number;
    time: number;
    auditReason: string;
    location: string;
    sex: number;
  };
}

const primaryBtn = {
  height: 24,
  borderRadius: 2,
  border: '1px solid #389E0D',
  color: '#389E0D',
  fontSize: '12px',
  lineHeight: '24px',
  background: 'transparent',
  marginLeft: 8,
};

const NewFriendItem: React.FC<Prop> = props => {
  const { nickname, content, headImg, status, time, auditReason, location, sex } = props.userInfo;
  return (
    <div className="user-line">
      <div className="user-info">
        <img className="img" src={headImg || '/defaultHead.jpg'} alt="head" />
        <div className="friend-apply-block">
          <div className="block1">
            <div className="nickname">
              <Tooltip placement="right" title={nickname}>
                <span>{nickname.length > 5 ? nickname.slice(0, 5).concat('...') : nickname}</span>
              </Tooltip>
              <img alt="sex" className="sex-img" src={sex === 1 ? './man.png' : './fale.png'} />
              <span className="origin-text">{location}</span>
            </div>
            <Tooltip placement="top" title={content}>
              <div className="message">留言：{content}</div>
            </Tooltip>
          </div>
          <div className="block2">
            <div className="time-day">{moment(parseFloat(`${time}000`)).format('YYYY-MM-DD')}</div>
            <div className="time-hour">{moment(parseFloat(`${time}000`)).format('HH:mm')}</div>
          </div>
          <div className="block3">
            <div className="btn">
              {(() => {
                switch (status) {
                  case 0: // 待处理 0
                  case 3: // 超出限制 3
                    return (
                      <>
                        <Button
                          onClick={() => props.onIgnoreClick()}
                          size="small"
                          style={{ ...primaryBtn, borderColor: '#D9D9D9', color: '#8C8C8C' }}
                        >
                          忽&#8195;略
                        </Button>
                        <Button size="small" onClick={() => props.onClick()} style={primaryBtn}>
                          通&#8195;过
                        </Button>
                      </>
                    );
                  case 1: // 已通过 1
                    return (
                      <Button
                        disabled
                        size="small"
                        style={{ background: '#E9E9E9', color: '#8C8C8C' }}
                        className="im-btn-default"
                      >
                        已通过
                      </Button>
                    );
                  case 2: // 已忽略 2
                    return (
                      <>
                        <Button
                          disabled
                          size="small"
                          style={{ background: '#E9E9E9', color: '#8C8C8C' }}
                        >
                          已忽略
                        </Button>
                        <Button size="small" onClick={() => props.onClick()} style={primaryBtn}>
                          通&#8195;过
                        </Button>
                      </>
                    );
                  case 4: // 已过期 4
                    return (
                      <Button
                        disabled
                        size="small"
                        style={{ background: '#E9E9E9', color: '#8C8C8C' }}
                      >
                        已过期
                      </Button>
                    );
                  default:
                    return <></>;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
      {auditReason ? (
        <WaringPop
          content={auditReason}
          styleTheme={{ color: '#8C8C8C', background: '#E9E9E9' }}
        ></WaringPop>
      ) : (
        ''
      )}
    </div>
  );
};

export default NewFriendItem;
