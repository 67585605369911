import React, { Fragment } from 'react';
import './index.less';
import { Divider } from 'antd';
import {
  TARGET_TYPE_SESSION,
  TARGET_TYPE_NEW_FRIEND,
  TARGET_TYPE_NEW_GROUP,
} from '../../../../../../store/constant';

interface Iprops {
  currentClient: any;
  targetName: string;
  targetHeadImg: string;
  type: string;
  owner: any;
}
const miniHeadImage = (headImg: string = '') => {
  const size = headImg.indexOf('wework') > -1 ? 100 : 132;
  return headImg && headImg.substr(-2) === '/0'
    ? `${headImg.substr(0, headImg.length - 1)}${size}`
    : headImg;
};

const ChatPanelHeader: React.FC<Iprops> = props => (
  <Fragment>
    {(() => {
      switch (props.type) {
        case TARGET_TYPE_SESSION:
          return (
            <div style={{ padding: '0 16px' }}>
              <div className="main-header session">
                <div>
                  <img alt="头像" className="target-img" src={props.targetHeadImg} />
                  <span>{props.targetName}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#8C8C8C' }}>
                    {(props.currentClient && props.currentClient.nickname) ||
                      (props.owner && props.owner.nickname)}
                  </span>
                  <img
                    alt="头像"
                    className="client-img"
                    src={miniHeadImage(
                      (props.currentClient && props.currentClient.headImg) ||
                        (props.owner && props.owner.headImg),
                    )}
                  />
                </div>
              </div>
              <Divider style={{ margin: 0 }} />
            </div>
          );
        case TARGET_TYPE_NEW_FRIEND:
          return (
            <div className="main-header apply">
              <div className="apply-title">新好友申请</div>
              <div className="apply-text">可以在后台（个人号-账号管理）设置好友自动通过规则</div>
            </div>
          );
        case TARGET_TYPE_NEW_GROUP:
          return (
            <div className="main-header apply">
              <div className="apply-title">新入群申请</div>
              <div className="apply-text">可以在后台（社群-自动通过）设置群自动通过</div>
            </div>
          );
        default:
          return null;
      }
    })()}
  </Fragment>
);

export default ChatPanelHeader;
