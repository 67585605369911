import { BaseAction } from '../types/action';
import {
  ACTION_GET_MESSAGE_LIST,
  ACTION_SET_MESSAGE_LIST,
  ACTION_SEND_TEXT_MESSAGE,
  ACTION_MERGE_MESSAGE_LIST,
  ACTION_SEND_IMAGE_MESSAGE,
  ACTION_SYNC_SEND_MESSAGE,
  ACTION_SYNC_DELETE_MESSAGE,
  ACTION_UPDATE_MESSAGE_LIST_STATUS,
  ACTION_SEND_LUCKY_MONEY_MESSAGE,
  ACTION_SEND_LINK_MESSAGE,
  ACTION_SEND_GAMES_MESSAGE,
} from '../constant';

export function createGetWxMessageListAction(param: any, replace: boolean): BaseAction {
  return {
    type: ACTION_GET_MESSAGE_LIST,
    param,
    replace,
  };
}

export function createSetWxMessageListAction(data: any, sessionId: number): BaseAction {
  return {
    type: ACTION_SET_MESSAGE_LIST,
    data,
    sessionId,
  };
}

export function createUpdateWxMessageListStatus(data: any, sessionId: number): any {
  return {
    type: ACTION_UPDATE_MESSAGE_LIST_STATUS,
    data,
    sessionId,
  };
}

export function createMergeWxMessageListAction(
  data: any,
  sessionId: number,
  isNew: boolean,
  isUpdate?: boolean,
  isReplace?: boolean,
): BaseAction {
  return {
    type: ACTION_MERGE_MESSAGE_LIST,
    data,
    sessionId,
    isNew,
    isUpdate,
    isReplace,
  };
}

export function createSendTextMessageAction(param: any) {
  return {
    type: ACTION_SEND_TEXT_MESSAGE,
    param,
  };
}

export function createSendImageMessageAction(param: any) {
  return {
    type: ACTION_SEND_IMAGE_MESSAGE,
    param,
  };
}

export function createSendLuckyMoneyMessageAction(param: any) {
  return {
    type: ACTION_SEND_LUCKY_MONEY_MESSAGE,
    param,
  };
}

export function createSendLinkMessageAction(param: any) {
  return {
    type: ACTION_SEND_LINK_MESSAGE,
    param,
  };
}

export function createSyncSendMessage(data: any) {
  return {
    type: ACTION_SYNC_SEND_MESSAGE,
    data,
  };
}

export function deleteSyncSendMessageAction() {
  return {
    type: ACTION_SYNC_DELETE_MESSAGE,
  };
}

export function createSendGamesMessage(param: any) {
  return {
    type: ACTION_SEND_GAMES_MESSAGE,
    param,
  };
}
