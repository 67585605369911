import React from 'react';
import { Layout, Empty } from 'antd';
import { connect } from 'react-redux';
import ChatPanelHeader from './components/ChatPanelHeader';
import ChatWindow from './components/ChatWindow';
import UserDetail from './components/UserDetail';
import GroupDetail from './components/GroupDetail';
import NewFriendApply from '../NewApplyPanel/components/NewFriendApply';
import NewGroupApply from '../NewApplyPanel/components/NewGroupApply';
import { State, Target } from '@/store/types/state';
import {
  TARGET_TYPE_SESSION,
  TARGET_TYPE_FRIEND,
  TARGET_TYPE_GROUP,
  TARGET_TYPE_NEW_FRIEND,
  TARGET_TYPE_NEW_GROUP,
} from '@/store/constant';
import './index.less';
import {
  createSetCurrentTargetAction,
  createGetWxMessageListAction,
  createCreateWxSessionAction,
  createMergeWxMessageListAction,
  createTopSingleSessionAction,
  createAllowWxFriendApplyAction,
  createIgnoreWxFriendApplyAction,
  createUpdateCurrClientInfoAction,
  createGetGroupMemberListAction,
  createSetTabUnReadCountAction,
  createReplaceSingleSessionAction,
} from '@/store/action';
import createWebSocket from '@/ws';
import { WebSocketType } from '../../../../ws/types';
import httpConfig from '@/http/config';

let throttleSessionList = false;
function filterArrayObj(unRead: any) {
  const obj: any = {};
  if (unRead) {
    if (unRead.chatType === 1) {
      obj.subUnReadCount = unRead.unReadCount;
    } else if (unRead.chatType === 2) {
      obj.qunUnReadCount = unRead.unReadCount;
    }
  }
  return obj;
}

interface Prop {
  target: any;
  clients: any;
  setCurrentTarget: (data: Target) => any;
  getMessageList: (param: any, replace: boolean) => any;
  getMemeberList: (param: any, merge: boolean) => void;
  sessionList: any;
  createSession: (chatId: any) => any;
  mergeMessage: (
    data: any,
    sessionId: any,
    isNew: boolean,
    isUpdate: boolean,
    isReplace: boolean,
  ) => any;
  topSession: (data: any) => any;
  replaceSession: (data: any) => any;
  message: any;
  replyAllowFriendApply: (toWxId: any, ticket: any) => any;
  ignoreAllowFriendApply: (toWxId: any, reason: any) => any;
  updateSingleClient: (data: any) => any;
  currentSessionListFilterCondition: string;
  currentSiderTab: string;
  setTabUnReadCount: (data: any) => any;
}

class ChatPanel extends React.Component<Prop> {
  componentDidMount() {
    this.init();
  }

  componentDidUpdate(preProps: Prop) {
    // 初始化条件
    // 1. 前后账号信息存在的前提下，切换账号
    const condition1 =
      this.props.clients.current &&
      preProps.clients.current &&
      this.props.clients.current.wxId &&
      !this.props.clients.current.isFission &&
      this.props.clients.current.wxId !== preProps.clients.current.wxId;
    // 2. 初始打开页面，上一个账号信息不存在，当前账号信息能正确获取时
    const condition2 =
      !preProps.clients.current && this.props.clients.current && this.props.clients.current.wxId;
    // 3. 当切换到裂变下时，用userId 重新连接
    const condition3 =
      (preProps.currentSiderTab &&
        this.props.currentSiderTab &&
        preProps.currentSiderTab[0] !== '0' &&
        this.props.currentSiderTab[0] === '0' &&
        this.props.clients.currentUserId) ||
      (!preProps.clients.currentUserId && this.props.clients.currentUserId);
    if (condition1 || condition2 || condition3) {
      this.init();
    }
    this.messageListUpdate(preProps);
  }

  ws: WebSocketType | null = null;

  initLoading: boolean = true;

  throttleSessionListData: any = [];

  init() {
    if (
      (!this.props.clients.current ||
        (this.props.clients.current && !this.props.clients.current.wxId)) &&
      this.props.currentSiderTab[0] !== '0'
    ) {
      return;
    }
    this.WSInit();
  }

  getSessionMessageList = (replace: boolean = true, isNew: boolean = true, limit: number = 30) => {
    const {
      target,
      getMessageList,
      message: { messageNextIndex },
    } = this.props;
    if (target && target.type === TARGET_TYPE_SESSION) {
      const param = {
        limit,
        isNew,
        sessionId: target.id,
        nextIndex: messageNextIndex,
      };
      getMessageList(param, replace);
    }
  };

  messageListUpdate = (preProps: any) => {
    const { target } = this.props;
    const preTarget = preProps.target;
    // 添加判断逻辑, 发消息时不触发获取会话列表
    // if (!!preTarget && !!target && preTarget.id === target.id) {
    //   return;
    // }
    if (!preTarget || !target) {
      this.getSessionMessageList(true, false);
    } else if (preTarget.id !== target.id) {
      const LIMIT = 30;
      // messageList一次加载的数量条件
      // 若当前会话
      this.getSessionMessageList(
        true,
        false,
        target.currSessionUnreadNum &&
          target.currSessionUnreadNum > LIMIT &&
          target.currSessionUnreadNum < LIMIT * 2
          ? target.currSessionUnreadNum
          : LIMIT,
      );
    }
  };

  handlerDetailClick = (id: string) => {
    this.props.createSession(id);
  };

  handlerScrollToTop = () => {
    if (this.props.message && this.props.message.messageIsEnd) return;
    this.getSessionMessageList(false, false);
  };

  // 处理当前会话信息
  handlerSession = (receiveData: any) => {
    const { target, topSession, replaceSession, currentSiderTab } = this.props;
    const tempData: any = receiveData;
    // 新的render逻辑, 建立队列, 存储会话, 每350毫秒渲染一次队列

    // 如果处于当前会话，则推送的消息newUnread置为0
    if (target && target.id === receiveData.id) {
      tempData.newUnread = 0;
      tempData.newUnreadAt = 0;
    }

    // 消息位置重排
    // 这里会导致页面卡顿, 添加时间锁及队列来批量更新会话
    this.throttleSessionListData = {
      ...this.throttleSessionListData,
      [tempData.id]: tempData,
    };
    if (!throttleSessionList) {
      throttleSessionList = true;
      setTimeout(() => {
        throttleSessionList = false;
        const list = Object.keys(this.throttleSessionListData).map(
          key => this.throttleSessionListData[key],
        );
        if (currentSiderTab[0] !== '0') {
          topSession(list);
        } else {
          replaceSession(list);
        }
        this.throttleSessionListData = {};
      }, 1000);
    }
  };

  // 处理更新当前账号信息
  handlerCurrClientInfo = (receiveData: any) => {
    const { target, updateSingleClient } = this.props;
    const { newUnread, silence, id } = receiveData;
    // 账号未读消息数更新：
    // 当所推送消息为第一条（即newUnread为1时，表示非当前会话的当前账号的新增未读消息）账号未读消息数+1
    if (newUnread && newUnread === 1 && !silence && (!target || target.id !== id)) {
      const currClient = this.props.clients && this.props.clients.current;
      const currClientNewUnread = currClient.newUnread || 0;
      // 更新账号未读消息数
      updateSingleClient({
        ...currClient,
        newUnread: currClientNewUnread + 1,
      });
    }
  };

  handlerWsMsg = (msg: any): any => {
    const { data, wxId: msgClientWxId } = msg;
    const {
      target,
      clients: {
        current: { wxId: currClientWxId },
      },
      currentSessionListFilterCondition,
      currentSiderTab,
    } = this.props;
    if (currentSiderTab[0] !== '0' && msgClientWxId !== currClientWxId) return;
    if (Array.isArray(data)) {
      this.props.setTabUnReadCount({
        ...filterArrayObj(data[0]),
        ...filterArrayObj(data[1]),
        ...filterArrayObj(data[2]),
      });
    }
    switch (msg.cmdId) {
      case 2: {
        if (currentSiderTab[0] !== '0') {
          // 若接收到的消息为当前账号发送的（微信客户端发送，或者系统控制自动回复），则会话未读数不进行更新
          if (data.lastMsg && data.lastMsg.fromWxid === currClientWxId) {
            delete data.newUnread;
          }
          this.handlerCurrClientInfo(data);
          // 不更新移动新消息的条件
          // 1. 新会话为单聊，而当前筛选条件为群聊
          const condition1 = data.chatType === 1 && currentSessionListFilterCondition === '3';
          // 2. 新会话为群聊，而当前筛选条件为单聊
          const condition2 = data.chatType === 2 && currentSessionListFilterCondition === '2';
          if (condition1 || condition2) {
            return;
          }
          this.handlerSession(data);
          if (target && target.type === TARGET_TYPE_SESSION && target.id === data.id) {
            this.getSessionMessageList(false, true);
          }
        } else {
          if (parseInt(currentSiderTab[1], 10) === data.chatType) {
            this.handlerSession(data);
          }
          // 新消息隶属当前聊天窗口，拉取message即可
          if (target && target.type === TARGET_TYPE_SESSION && target.id === data.id) {
            this.getSessionMessageList(false, true);
          }
        }
        break;
      }
      case 6: {
        if (currentSiderTab[0] !== '0') {
          this.props.mergeMessage(data, data.sessionId, false, false, true);
        } else {
          this.updateMessageList(data);
        }
        break;
      }
      case 7: {
        // 更新右侧功能区的群成员列表, 需要判断是不是当前的group
        if (target && target.data && target.data.chatId === data.chatId) {
          this.props.getMemeberList(
            {
              groupId: data.chatId,
              start: 0,
            },
            false,
          );
        }
        break;
      }
      default:
        break;
    }
  };

  handlerFriendApplyAcceptClick = (id: any, ticket: any) => {
    this.props.replyAllowFriendApply(id, ticket);
  };

  updateMessageList = (data: any) => {
    this.props.mergeMessage(data, data.sessionId, false, false, true);
  };

  WSInit = () => {
    if (this.ws) {
      // 账号切换时，传递code 4444，不对ws关闭进行重连操作，避免同时维护多个ws导致的消息串号
      if (this.ws.wsObj) {
        this.ws.wsObj.close(4444);
        this.ws.wsObj = null;
      }
      this.ws = null;
    }
    const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
    if (this.props.currentSiderTab[0] === '0') {
      this.ws = createWebSocket(
        `${protocol}://${httpConfig.websocket}/websocket/bee/users/${this.props.clients.currentUserId}?category=1`,
        false,
      );
    } else {
      const { wxId } = this.props.clients.current;
      this.ws = createWebSocket(
        `${protocol}://${httpConfig.websocket}/websocket/bee/accounts/${wxId}`,
        false,
      );
    }
    if (this.initLoading) {
      if (this.ws) {
        this.ws.onMessage(this.handlerWsMsg);
      }
      this.initLoading = false;
    }
  };

  handlerFriendApplyIgnoreClick = (id: any, reason: any) => {
    this.props.ignoreAllowFriendApply(id, reason);
  };

  render() {
    const {
      target,
      clients,
      message: { messageIsEnd },
    } = this.props;
    const targetName =
      target &&
      target.data &&
      (target.data.remarkName || target.data.groupName || target.data.nickname || '未命名');
    const targetHeadImg = target && target.data && (target.data.headImg || './defaultHead.jpg');
    return (
      <Layout.Content className="main-layout">
        {target && target.type ? (
          <ChatPanelHeader
            currentClient={clients.current}
            targetName={targetName}
            targetHeadImg={targetHeadImg}
            type={target.type}
            owner={target.data && target.data.owner}
          />
        ) : (
          ''
        )}
        {(() => {
          if (!target || (target.type === 'TARGET_TYPE_SESSION' && !target.id)) {
            return (
              <Empty
                className="no-choosed-session-container im-empty-status"
                image="/nosession.png"
                description="没有选择聊天"
              />
            );
          }

          switch (target.type) {
            case TARGET_TYPE_SESSION:
              if (!target.data) return null;
              return (
                <ChatWindow
                  messageList={target.data.messageList}
                  messageIsEnd={messageIsEnd}
                  onScrollToTop={this.handlerScrollToTop}
                  target={target}
                  wxId={clients.current.wxId}
                />
              );
            case TARGET_TYPE_FRIEND:
              if (!target.data) return null;
              return <UserDetail onClick={this.handlerDetailClick} userInfo={target.data} />;
            case TARGET_TYPE_GROUP:
              if (!target.data) return null;
              return <GroupDetail groupInfo={target.data} onClick={this.handlerDetailClick} />;
            case TARGET_TYPE_NEW_FRIEND:
              if (!target.data) return null;
              if (target.data.length > 0) {
                return (
                  <NewFriendApply
                    friendApplyList={target.data}
                    onClick={this.handlerFriendApplyAcceptClick}
                    serverTitle={clients.current.nickname}
                    onIgnoreClick={this.handlerFriendApplyIgnoreClick}
                  />
                );
              }
              return (
                <Empty
                  className="im-empty-status"
                  image="/noinvite.png"
                  description="没有好友申请"
                />
              );

            case TARGET_TYPE_NEW_GROUP:
              return <NewGroupApply friendApplyList={target.data} />;
            default:
              return null;
          }
        })()}
      </Layout.Content>
    );
  }
}

function getTarget(state: State) {
  if (!state.currentTarget || !state.currentTarget.id) return null;
  let data;
  const { type, id, currSessionUnreadNum } = state.currentTarget;
  switch (type) {
    case TARGET_TYPE_SESSION:
      data = {
        ...state.sessionList.find(session => session.id === id),
        messageList: state.messageMap[id as number],
      };
      // 此处session 含有账号信息，可以用于会话框头部
      break;
    case TARGET_TYPE_FRIEND:
      data = state.friendList.find(friend => friend.friendId === id);
      break;
    case TARGET_TYPE_GROUP:
      data = state.groupList.find(group => group.roomId === id);
      break;
    case TARGET_TYPE_NEW_FRIEND:
      data = state.friendApplyList;
      break;
    case TARGET_TYPE_NEW_GROUP:
      // data = state.groupApplyList;
      break;
    default:
      data = null;
      break;
  }
  return { type, id, data, currSessionUnreadNum };
}

function mapStateToProps(state: State) {
  return {
    clients: state.clients,
    target: getTarget(state),
    sessionList: state.sessionList,
    message: state.messageMap,
    currentSessionListFilterCondition: state.currentSessionListFilterCondition,
    currentGroupInfo: state.currentGroupInfo,
    currentSiderTab: state.currentSiderTab,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentTarget: (data: Target) => dispatch(createSetCurrentTargetAction(data)),
    getMessageList: (param: any, replace: boolean) =>
      dispatch(createGetWxMessageListAction(param, replace)),
    createSession: (chatId: any) => dispatch(createCreateWxSessionAction({ chatId })),
    mergeMessage: (
      data: any,
      sessionId: any,
      isNew: boolean,
      isUpdate: boolean,
      isReplace: boolean,
    ) => dispatch(createMergeWxMessageListAction(data, sessionId, isNew, isUpdate, isReplace)),
    topSession: (data: any) => dispatch(createTopSingleSessionAction(data)),
    replaceSession: (data: any) => dispatch(createReplaceSingleSessionAction(data)),
    replyAllowFriendApply: (fromWxId: any, ticket: any) =>
      dispatch(createAllowWxFriendApplyAction({ fromWxId, ticket })),
    ignoreAllowFriendApply: (fromWxId: any, reason: any) =>
      dispatch(createIgnoreWxFriendApplyAction({ fromWxId, reason })),
    updateSingleClient: (data: any) => dispatch(createUpdateCurrClientInfoAction(data)),
    getMemeberList: (param: any, merge: boolean) =>
      dispatch(createGetGroupMemberListAction(param, merge)),
    setTabUnReadCount: (data: Target) => dispatch(createSetTabUnReadCountAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatPanel);
