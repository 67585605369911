import { BaseAction } from '../types/action';
import {
  ACTION_GET_GROUPMEMBER_LIST,
  ACTION_SET_GROUPMEMBER_LIST,
  ACTION_MERGE_GROUPMEMBER_LIST,
} from '../constant';

export function createGetGroupMemberListAction(param: any, merge: any): BaseAction {
  return {
    type: ACTION_GET_GROUPMEMBER_LIST,
    merge,
    param,
  };
}

export function createSetGroupMemberListAction(data: any[]): BaseAction {
  return {
    type: ACTION_SET_GROUPMEMBER_LIST,
    data,
  };
}

export function createMergeGroupMemberListAction(data: any[]): BaseAction {
  return {
    type: ACTION_MERGE_GROUPMEMBER_LIST,
    data,
  };
}
