enum MsgType {
  TEXT = 1,
  IMG = 3,
  // gif表情消息
  EMOJI = 4,
  AUDIO = 34,
  // 个人卡片消息
  PERSONALCARD = 42,
  VIDEO = 43,
  // 链接卡片消息
  SHARE = 49,
  // 转账消息
  TRANSFER = 2000,
  // 红包
  MONEY = 2001,
  // APP小程序
  APP = 4901,
  // 文件消息
  FILE = 4903,
  SYS = 10000,
  // 撤回消息
  REVOKE = 10002,
}

export default MsgType;
