import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import request from '@/http/request';

interface AddedOtherWxProps {
  chatId: string;
}

const AddedOtherWx: React.FC<Readonly<AddedOtherWxProps>> = props => {
  const { chatId } = props;
  const [wxListTotal, setWxListTotal] = useState(0);
  const [wxList, setwxList] = useState([]);

  useEffect(() => {
    function getWxList() {
      request.get(`/im/v2/customers/${chatId}/friendship`).then((res: any) => {
        const {
          data: { list, total },
        } = res;
        setWxListTotal(total);
        setwxList(list);
      });
    }
    getWxList();
  }, [chatId, setwxList, setwxList]);

  const handleShowModal = useCallback(() => {
    const modalContent = (
      <div className="addedotherwx-modal">
        <ul className="addedotherwx-modal-list">
          {wxList.map((item: any) => {
            return (
              <li>
                <p>
                  <span>微信</span>
                  <span className="text">
                    {item.accountRemark || item.accountNickname}（{item.accountWxAlias}）
                  </span>
                </p>
                <p>
                  <span>所属员工</span>
                  <span className="text">
                    {item.userNickname}（{item.userPhone}）
                  </span>
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    );
    Modal.info({
      title: <h3 className="addedotherwx-modal-title">添加到其他微信号:</h3>,
      content: modalContent,
      maskClosable: true,
      icon: '',
      width: 360,
      okButtonProps: <Button type="primary">知道了</Button>,
    });
  }, [wxList]);

  return (
    <Row type="flex" gutter={20}>
      <Col>已添加到其他微信</Col>
      <Col>{wxListTotal > 0 ? <a onClick={handleShowModal}>{wxListTotal}</a> : '无'}</Col>
    </Row>
  );
};

export default React.memo(AddedOtherWx);
