import React, { Component } from 'react';
import { Layout, Tabs, Button, Modal, Empty } from 'antd';
import './index.less';
import { connect } from 'react-redux';
import { State, Target } from '@/store/types/state';
import {
  createSetCurrentTargetAction,
  createGetWxFriendListAction,
  createGetWxSessionListAction,
  setCurrentWindowTabAction,
  createGetMediaLibraryListAction,
  createSyncSendMessage,
} from '@/store/action';
import request from '@/http/request';
import MessageRecords from '@/components/MessageRecords';
import eventBus from '@/ws/event';
import IconFont from '@/components/IconFont';
import FriendPanel from './components/FriendPanel';
import GroupPanel from './components/GroupPanel';
import QuickReplaySetting from './components/QuickReplaySetting';
import QuickReplayInfo from './components/QuickReplayInfo';
import MaterialLibrary from './components/MaterialLibrary';
import FissionInfo from './components/FissionInfo';
import MomentsContainer from './components/Moments/MomentsContainer';

const { TabPane } = Tabs;

interface Props {
  mediaList: any[];
  target: Target;
  clients: any;
  currentSiderTab: string;
  currentWindowTab: string;
  setCurrentTarget: (data: Target) => any;
  getFriendList: () => any;
  getSessionList: (extraData: any) => any;
  setCurrentWindowTab: (data: string) => void;
  getMediaList: (e: {}) => void;
  syncMessage: (e: {}) => void;
}
interface OwnState {
  tabPosition: any;
  detailData: any;
  originData: any;
  detailSignal: boolean;
  singleChat: number;
  role: number;
  chatId: string;
  showQuickReplyModal: boolean;
  isDeleteFriend: boolean;
  tabIndex: string;
  windowWidth: number;
  messageRecordLocationMid: string;
  momentsList: any[];
  refresh: number;
  visibleMessage: string;
  momentsIsEnd: boolean;
  activityInfo: any;
}
class OperationPanel extends Component<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabPosition: 'right',
      detailData: null,
      originData: null,
      detailSignal: true,
      chatId: (this.props.target && this.props.target.chatId) || '',
      singleChat: (this.props.target && this.props.target.chatType) || 0,
      role: 0,
      showQuickReplyModal: false,
      isDeleteFriend: false,
      tabIndex: '0',
      windowWidth: 386,
      messageRecordLocationMid: '',
      momentsList: [],
      refresh: 0,
      visibleMessage: '',
      momentsIsEnd: false,
      activityInfo: null,
    };
  }

  qunChild: any;

  quickReplyChild: any;

  lastId: number = 0;

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    const status =
      nextProps.target &&
      nextProps.target.chatId &&
      nextProps.target.chatId !== undefined &&
      prevState.chatId !== nextProps.target.chatId;

    let windowWidth = 386;
    if (nextProps.currentWindowTab === '5') {
      windowWidth = 493;
    }
    if (status) {
      return {
        ...prevState,
        singleChat: nextProps.target.chatType,
        chatId: nextProps.target.chatId,
        windowWidth,
      };
    }
    return prevState;
  }

  componentDidMount() {
    eventBus.on('messageRecordLocation', async (mid: string) => {
      await this.props.setCurrentWindowTab('5');
      this.setState({
        windowWidth: 493,
        messageRecordLocationMid: mid,
      });
    });
  }

  componentDidUpdate(prevProps: Props) {
    const currTargetId = this.props.target && this.props.target.id;
    const prevTargetId = prevProps.target && prevProps.target.id;
    if (
      currTargetId &&
      (this.props.currentWindowTab[0] !== prevProps.currentWindowTab[0] ||
        currTargetId !== prevTargetId)
    ) {
      if (this.props.currentWindowTab[0] === '4') {
        this.props.getMediaList({ mediaType: 1 });
      }
      if (this.props.currentWindowTab[0] === '7') {
        this.getMomentsList(true);
      }
      if (this.props.currentWindowTab[0] === '8') {
        this.getActivityInfo();
      }
    } else if (!currTargetId && prevTargetId) {
      this.resetStateData();
    }
  }

  resetStateData = () => {
    this.setState({
      momentsList: [],
      activityInfo: null,
    });
  };

  tabChange = (key: any) => {
    this.setState({
      tabIndex: key,
      windowWidth: key === '5' ? 493 : 386,
    });
    if (key === '4') {
      this.setWindowTab('1');
    } else {
      this.props.setCurrentWindowTab(key);
    }
  };

  setWindowTab = (e: string) => {
    this.props.setCurrentWindowTab('4'.concat(e));
    this.props.getMediaList({ mediaType: e === '1' ? 1 : e === '2' ? 3 : 49 }); // eslint-disable-line
  };

  getMomentsList = (isReload: boolean) => {
    const { clients, target } = this.props;
    const { refresh } = this.state;
    if (target.type === 'TARGET_TYPE_GROUP') {
      this.setState({
        momentsList: [],
      });
      return;
    }
    // 获取朋友圈列表，不用写redux
    if (isReload) {
      this.lastId = 0;
    }
    const random = Math.random();
    request
      .get('im/v2/sns', {
        wxId: clients.current && clients.current.wxId,
        friendWxId: target.type === 'TARGET_TYPE_FRIEND' ? target.id : target.chatId,
        lastId: this.lastId,
        random,
      })
      .then((res: any) => {
        const { list } = res.data;
        this.setState({
          momentsList: isReload ? list : [...this.state.momentsList, ...list], // eslint-disable-line
          refresh: isReload ? random : refresh,
          visibleMessage: res.data.visibleMessage,
          momentsIsEnd: res.data.isEnd,
        });
        this.lastId = res.data.lastId;
      });
  };

  getActivityInfo = () => {
    const { target } = this.props;
    request.get(`bee/v2/sessions/${target.id}/group_invitation`).then((res: any) => {
      this.setState({ activityInfo: { ...res.data, chatType: target.chatType } });
    });
  };

  render() {
    const {
      tabPosition,
      showQuickReplyModal,
      chatId,
      singleChat,
      windowWidth,
      messageRecordLocationMid,
      momentsList,
      activityInfo,
    } = this.state;
    const { currentWindowTab, mediaList, target, clients } = this.props;
    return (
      <Layout.Sider
        className="functionWindow"
        width={windowWidth}
        breakpoint="xl"
        collapsedWidth={windowWidth}
      >
        <div className="side-right-bar-tabs">
          <Tabs
            className="functionWindow-tabs"
            tabPosition={tabPosition}
            onChange={this.tabChange}
            activeKey={currentWindowTab[0]}
            tabBarStyle={{ height: '100vh' }}
          >
            <TabPane
              key="1"
              className="functionWindow-tabs-item"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="iconyou-kehuziliao"></IconFont>
                  <p>客户资料</p>
                </span>
              }
            >
              {chatId !== '' &&
              this.props.target !== null &&
              this.props.target.type === 'TARGET_TYPE_SESSION' ? (
                <>
                  {singleChat === 1 ? <FriendPanel /> : null}
                  {singleChat === 2 ? (
                    <GroupPanel
                      currSessionId={(this.props.target && this.props.target.id) || 0}
                      currGroupId={chatId}
                      onRef={(ref: any) => {
                        this.qunChild = ref;
                      }}
                    />
                  ) : null}
                </>
              ) : null}
            </TabPane>
            <TabPane
              key="8"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="icontuijianshangpin"></IconFont>
                  <p>裂变信息</p>
                </span>
              }
            >
              <div className="functionWindow-tabs-header">
                <span className="im-title-font-large">裂变信息</span>
              </div>
              {target &&
              activityInfo &&
              activityInfo.invitationInfo &&
              Object.keys(activityInfo.invitationInfo).length ? (
                <FissionInfo {...activityInfo} />
              ) : (
                <Empty
                  image="/null.png"
                  description="无可查看的信息"
                  style={{ color: 'rgba(89, 89, 89, 0.4)', height: 220 }}
                />
              )}
            </TabPane>
            <TabPane
              key="2"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="iconyou-kuaijiehuifu"></IconFont>
                  <p>快捷回复</p>
                </span>
              }
            >
              <div className="functionWindow-tabs-header">
                <span className="im-title-font-large">快捷回复</span>
                <Button
                  className="im-btn-default im-btn-icon"
                  onClick={() => {
                    this.setState({ showQuickReplyModal: true });
                  }}
                  icon="plus"
                >
                  添加快捷回复
                </Button>
              </div>
              <Modal
                visible={showQuickReplyModal}
                width={1040}
                style={{ top: 40 }}
                title="快捷回复模版"
                onCancel={() => {
                  this.setState({ showQuickReplyModal: false });
                  this.quickReplyChild.init();
                }}
                footer={null}
              >
                <QuickReplaySetting />
              </Modal>
              <QuickReplayInfo
                {...this.props.target}
                onRef={(ref: any) => {
                  this.quickReplyChild = ref;
                }}
              />
            </TabPane>
            <TabPane
              key="4"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="iconsucaiku"></IconFont>
                  <p>素材库</p>
                </span>
              }
            >
              <div className="functionWindow-tabs-header">
                <span className="im-title-font-large">素材库</span>
              </div>
              <MaterialLibrary
                mediaList={mediaList}
                getMediaList={this.setWindowTab}
                target={target}
                activeKey={currentWindowTab[1]}
                syncMessage={this.props.syncMessage}
              />
            </TabPane>
            <TabPane
              key="7"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="iconyoupengyouquan"></IconFont>
                  <p>朋友圈</p>
                </span>
              }
            >
              <div className="functionWindow-tabs-header">
                <span className="im-title-font-large">朋友圈</span>
              </div>
              {momentsList.length ? (
                <MomentsContainer
                  momentsList={momentsList}
                  target={target}
                  currClientWxId={clients.current && clients.current.wxId}
                  currClientNickname={clients.current && clients.current.nickname}
                  key={this.state.refresh}
                  getMomentsList={this.getMomentsList}
                  isEnd={this.state.momentsIsEnd}
                  visibleMessage={this.state.visibleMessage}
                />
              ) : (
                <Empty
                  image="/nosession.png"
                  description={
                    target && (target.chatType === 1 || target.type === 'TARGET_TYPE_FRIEND')
                      ? '没有可查看的朋友圈'
                      : '请选择好友'
                  }
                  style={{ color: 'rgba(89, 89, 89, 0.4)', height: 220 }}
                />
              )}
            </TabPane>
            <TabPane
              key="5"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="iconliaotianjilu"></IconFont>
                  <p>聊天记录</p>
                </span>
              }
            >
              {this.props.target && this.props.target.id ? (
                <>
                  <div className="functionWindow-tabs-header">
                    <span className="im-title-font-large">聊天记录</span>
                  </div>
                  <MessageRecords
                    currClientWxId={clients.current && clients.current.wxId}
                    friendId={chatId}
                    sessionId={this.props.target && this.props.target.id}
                    singleChat={this.state.singleChat}
                    messageRecordLocationMid={messageRecordLocationMid}
                  />
                </>
              ) : (
                ''
              )}
            </TabPane>
            <TabPane
              key="6"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="iconyou-xitongshezhi"></IconFont>
                  <p>系统设置</p>
                </span>
              }
            >
              <div className="functionWindow-tabs-header">
                <span className="im-title-font-large">系统设置</span>
              </div>
              <div className="functionWindow-tabs-card">
                <div className="functionWindow-tabs-card-actions">
                  <Button
                    type="primary"
                    onClick={() => {
                      const host = window.location.host.split('.');
                      host[0] = 'op';
                      window.location.replace(`//${host.join('.')}`);
                    }}
                  >
                    进入后台
                  </Button>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Layout.Sider>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
    currentSiderTab: state.currentSiderTab,
    currentWindowTab: state.currentWindowTab,
    mediaList: state.mediaList,
    clients: state.clients,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentTarget: (data: Target) => dispatch(createSetCurrentTargetAction(data)),
    getSessionList: (extraData: any = {}) =>
      dispatch(createGetWxSessionListAction({ limit: 30, ...extraData })),
    getFriendList: () => dispatch(createGetWxFriendListAction({})),
    setCurrentWindowTab: (data: string) => dispatch(setCurrentWindowTabAction(data)),
    getMediaList: (data: any = {}) =>
      dispatch(createGetMediaLibraryListAction({ ...data, perpage: 5000 })),
    syncMessage: (param: any) => dispatch(createSyncSendMessage(param)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationPanel);
