import React, { Component } from 'react';
import { Modal } from 'antd';
import './index.less';

interface State {
  showImgModal: boolean;
  currImg: string;
}

class Content extends Component<any, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      showImgModal: false,
      currImg: '',
    };
  }

  clickImgHandle = (url: string) => {
    this.setState({
      showImgModal: true,
      currImg: url,
    });
  };

  render() {
    const { content } = this.props;
    const { description, mediaList, linkTitle, linkUrl } = content;
    const { showImgModal, currImg } = this.state;
    return (
      <div>
        {(() => {
          switch (this.props.content.type) {
            case 1: // 1文本
              return <div className="moments-text">{description}</div>;
            case 3: // 3图文
              return (
                <div className="moments-text">
                  <div>{description}</div>
                  <div>
                    {mediaList && mediaList.length
                      ? mediaList.map((item: any, index: number) => {
                          const insertStyle =
                            ((mediaList.length === 3 || mediaList.length === 5) && index === 2) ||
                            ((mediaList.length === 6 ||
                              mediaList.length === 7 ||
                              mediaList.length === 8) &&
                              (index === 5 || index === 2)) ||
                            (mediaList.length === 9 && (index === 8 || index === 5 || index === 5));
                          if (mediaList.length === 1) {
                            return (
                              <img
                                alt="图片"
                                key={index}
                                className="moments-img1"
                                style={{
                                  margin: insertStyle ? '' : '6px 0',
                                }}
                                src={item.mediaUrl}
                                onClick={() => this.clickImgHandle(item.mediaUrl)}
                              />
                            );
                          } else {
                            return (
                              <div
                                className="moments-img"
                                style={{
                                  margin: insertStyle ? '6px 0' : '6px 13px 6px 0',
                                }}
                              >
                                <div
                                  className="img-insert"
                                  style={{
                                    backgroundImage: `url(${item.mediaUrl})`,
                                  }}
                                  onClick={() => this.clickImgHandle(item.mediaUrl)}
                                ></div>
                              </div>
                            );
                          }
                        })
                      : ''}
                    <Modal
                      visible={showImgModal}
                      onCancel={() => this.setState({ showImgModal: false })}
                      footer={null}
                      width={760}
                      style={{ textAlign: 'center' }}
                      centered
                      title={<h3 className="send-title">图片预览</h3>}
                    >
                      <div style={{ textAlign: 'center' }}>
                        <img src={currImg} alt="图片" className="see-img-modal" />
                      </div>
                    </Modal>
                  </div>
                </div>
              );
            case 43: // 43视频
              return <div className="moments-text">该条朋友圈不支持显示，请到手机微信查看</div>;
            case 49: // 49链接
              return (
                <div className="moments-text">
                  <div>{description}</div>
                  <a href={linkUrl} rel="noopener noreferrer" target="_blank">
                    <div className="moments-card">
                      {!mediaList ? (
                        ''
                      ) : (
                        <img
                          alt="图片"
                          className="card-img"
                          src={mediaList[0] && mediaList[0].thumbUrl}
                        />
                      )}
                      <div className="card-text" style={{ width: !mediaList ? '' : '220px' }}>
                        {linkTitle}
                      </div>
                    </div>
                  </a>
                </div>
              );
            default:
              return <div className="moments-text">该条朋友圈不支持显示，请到手机微信查看</div>;
          }
        })()}
      </div>
    );
  }
}

export default Content;
