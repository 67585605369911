interface EventBus {
  [propName: string]: any;
}
const eventBus: EventBus = {
  $events: {},
  on(eventName: string, callback: () => any) {
    if (this.$events[eventName]) {
      this.$events[eventName].push(callback);
    } else {
      this.$events[eventName] = [callback];
    }
  },
  emit(eventName: string, e: any) {
    if (this.$events[eventName]) {
      this.$events[eventName].forEach((event: any) => {
        event(e);
      });
    }
  },
};

export default eventBus;
