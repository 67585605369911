import { BaseAction } from '../types/action';
import {
  ACTION_GET_CLIENT_LIST,
  ACTION_SET_CLIENT_LIST,
  ACTION_SET_CURRENT_CLIENT,
  ACTION_UPDATE_CURR_CLIENT_INFO,
  ACTION_SET_SYNC_CLIENT,
  ACTION_SET_CURRENT_USER,
} from '../constant';
import { WxClient } from '../types/state';

export function createGetClientListAction(reqType?: string): BaseAction {
  return {
    type: ACTION_GET_CLIENT_LIST,
    reqType,
  };
}

export function createSetClientListAction(data: WxClient[]): BaseAction {
  return {
    type: ACTION_SET_CLIENT_LIST,
    data,
  };
}

export function createSetCurrentClientAction(data: WxClient): BaseAction {
  return {
    type: ACTION_SET_CURRENT_CLIENT,
    data,
  };
}

// 更新当前账号信息
export function createUpdateCurrClientInfoAction(data: WxClient): BaseAction {
  return {
    type: ACTION_UPDATE_CURR_CLIENT_INFO,
    data,
  };
}

// 在账号设置时的处理
export function createSetSyncClientAction(data: any) {
  return {
    type: ACTION_SET_SYNC_CLIENT,
    data,
  };
}

export function setCurrentUserAction(data: any) {
  return {
    type: ACTION_SET_CURRENT_USER,
    data,
  };
}
