import root from '../protobuf/protobuf';

export function Uint8ArryToString(uint8: Uint8Array): string {
  let str = '';
  for (let i = 0, len = uint8.length; i < len; i += 1) {
    str += String.fromCharCode(uint8[i]);
  }
  return str;
}

export function StringToUint8Array(str: string): Uint8Array {
  const bytes = [];
  for (let i = 0, len = str.length; i < len; i += 1) {
    bytes[i] = str.charCodeAt(i);
  }
  return new Uint8Array(bytes);
}

export function getUrlPath(url: string): string {
  const regex = /(?:^|\w)(\/[\w/$]*)/;
  const result = regex.exec(url);
  if (result) {
    return result[1];
  }
  return '';
}

export function pbEncode(pb: string, data: Record<string, any>): any {
  const pbMsg: any = root.lookup(pb);
  const pbObj = pbMsg.create(data);
  return pbMsg.encode(pbObj).finish();
}

export function pbDecode(pb: string, data: Uint8Array): any {
  const pbMsg: any = root.lookup(pb);
  return pbMsg.decode(data);
}
