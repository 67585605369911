import { BaseAction } from '../types/action';
import { ACTION_GET_SESSION_IS_END, ACTION_SET_SESSION_IS_END } from '../constant/index';

export default (state: boolean | number = false, action: BaseAction) => {
  const { data } = action;
  switch (action.type) {
    case ACTION_GET_SESSION_IS_END:
      return data;
    case ACTION_SET_SESSION_IS_END:
      if (data.isEnd) {
        return true;
        // eslint-disable-next-line
      } else if (data.polling) {
        return state;
      }
      return data.nextIndex;
    default:
      return state;
  }
};
