import React from 'react';
import { Divider } from 'antd';
import { connect } from 'react-redux';
import UserItem from '../../../UserItem';
import { State, WxFriend, Target } from '@/store/types/state';
import {
  createGetWxFriendApplyListAction,
  createGetWxFriendListAction,
  createSetCurrentTargetAction,
  setCurrentWindowTabAction,
} from '@/store/action';

import { TARGET_TYPE_FRIEND, TARGET_TYPE_NEW_FRIEND } from '@/store/constant';
import eventBus from '@/ws/event';

interface IProp {
  clients: any;
  currentSiderTab: string;
  friendList: WxFriend[];
  getFriendList: (param?: any) => any;
  getFriendApplyList: () => any;
  setCurrentTarget: (data: Target | null) => any;
  setCurrentWindowTab: (data: string) => void;
}

interface IState {
  isEnd: boolean;
  start: number;
  count: number;
}

class SiderTab extends React.Component<IProp, IState> {
  constructor(props: IProp) {
    super(props);
    this.state = {
      isEnd: false,
      start: 0,
      count: 30,
    };
  }

  componentDidMount() {
    this.props.getFriendList();
    // 更新请求list的参数
    eventBus.on('updateRequestFriendListParam', (param: any) => {
      this.setState(param, () => {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      });
    });
    eventBus.on('resetRequestFriendListParam', () => this.resetRequestListParam());
  }

  componentDidUpdate(prevProps: any) {
    const currClientWxId = this.props.clients.current && this.props.clients.current.wxId;
    const PrevClientWxId = prevProps.clients.current && prevProps.clients.current.wxId;
    const { currentSiderTab } = this.props;
    const { currentSiderTab: prevSiderTab } = prevProps;
    const condition1 = currentSiderTab === '2';
    const condition2 = prevProps && prevSiderTab !== currentSiderTab && condition1;
    const condition3 = !prevProps && this.props;
    const condition4 = currClientWxId !== PrevClientWxId;
    if (condition1 && (condition2 || condition3 || condition4)) {
      this.resetRequestListParam();
      this.props.getFriendList();
    }
  }

  isLoading: boolean = false;

  handlerNewFriendClick = () => {
    this.props.setCurrentTarget({ id: '0', type: TARGET_TYPE_NEW_FRIEND });
    this.props.getFriendApplyList();
  };

  handlerUserItemClick = (id: any, type: string) => () => {
    this.props.setCurrentWindowTab('1');
    this.props.setCurrentTarget({ id, type });
  };

  // 重置list请求参数
  resetRequestListParam = () => {
    this.setState({
      isEnd: false,
      start: 0,
      count: 30,
    });
  };

  scrollHandler = (e: any) => {
    if (this.isLoading) return;

    const { start, count, isEnd } = this.state;
    const { scrollTop, scrollHeight, offsetHeight } = e.target;

    if (scrollTop + 100 >= scrollHeight - offsetHeight) {
      if (isEnd) return;
      this.isLoading = true;
      this.props.getFriendList({
        start: start > 0 ? start : count,
        count,
        isScrollLoading: true,
      });
    }
  };

  render() {
    const { friendList } = this.props;
    return (
      <div className="scroll-box" id="scroll-box-1" onScroll={e => this.scrollHandler(e)}>
        <UserItem avatar="./newFriend.png" name="新好友申请" onClick={this.handlerNewFriendClick} />
        {friendList && friendList.length > 0
          ? friendList.map((item: any, index: number) => (
              <div key={index} style={{ position: 'relative' }}>
                <UserItem
                  avatar={item.avatar}
                  name={item.remarkName || item.nickname}
                  onClick={this.handlerUserItemClick(item.friendId, TARGET_TYPE_FRIEND)}
                />
                <Divider
                  style={{
                    margin: '0px 16px',
                    width: '90%',
                    minWidth: 'unset',
                    position: 'absolute',
                  }}
                />
              </div>
            ))
          : null}
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  const { currentSiderTab, friendList, clients } = state;
  return { currentSiderTab, friendList, clients };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getFriendList: (param?: any) => dispatch(createGetWxFriendListAction(param)),
    getFriendApplyList: () => dispatch(createGetWxFriendApplyListAction({ limit: 5000 })),
    setCurrentTarget: (data: Target | null) => dispatch(createSetCurrentTargetAction(data)),
    setCurrentWindowTab: (data: string) => dispatch(setCurrentWindowTabAction(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiderTab);
