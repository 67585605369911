import React, { memo } from 'react';
import { Row, Col, Button, Input, message } from 'antd';
import TimerPicker from '@/components/TimePicker';

const SearchBar = memo((props: any) => {
  const { query, hasReset } = props;
  const onTimerChange = (startTime: string, endTime: string) => {
    props.onChange({
      ...props.query,
      startTime,
      endTime,
    });
  };

  const onChange = (e: string) => {
    props.onChange({
      ...props.query,
      q: e,
    });
  };

  return (
    <Row style={{ width: 'calc(100% - 22px)', margin: '0 auto' }} gutter={8}>
      <Col style={{ width: '100%' }}>
        <Input
          placeholder="           输入关键字"
          // enterButton="搜索"
          value={query.q}
          onChange={e => onChange(e.target.value)}
          // onSearch={props.onSearch}
        />
      </Col>
      <Col style={{ width: '100%', margin: '8px 0 12px' }}>
        <TimerPicker
          startTime={query.startTime}
          endTime={query.endTime}
          onOk={onTimerChange}
        ></TimerPicker>
      </Col>
      <Col style={{ textAlign: 'center' }}>
        {/* <Button onClick={props.onSearch}>搜索</Button> */}
        <Button onClick={props.onSearch} ghost type="primary" size="large">
          搜索
        </Button>
      </Col>
      {/* {hasReset && (
        <Col>
          <Button onClick={props.reset}>重置</Button>
        </Col>
      )} */}
    </Row>
  );
});

export default SearchBar;
