function computedAudioMessageLength(sec: number): number {
  if (sec <= 1) {
    return 90;
  }
  if (sec >= 60) {
    return 240;
  }
  return 90 + 2.5 * sec;
}

function secondToDate(sec: number): string {
  if (sec < 60) {
    return `${sec.toString()}''`;
  }
  const m = Math.floor((sec / 60) % 60);
  const s = Math.floor(sec % 60);

  const patchM = m <= 10 ? `0${m}` : m;
  const patchS = s <= 10 ? `0${s}` : s;
  return `${patchM}:${patchS}`;
}

export { secondToDate, computedAudioMessageLength };
