import React, { memo } from 'react';
import './LinkCard.less';

const LinkCard = memo((props: any) => {
  return (
    <a href={props.url} className="link" target="__blank">
      <div className="title">{props.content}</div>
      <div className="content">
        <img src={props.imageUrl} alt="" className="img" />
        <div className="des">{props.desc}</div>
      </div>
    </a>
  );
});

export default LinkCard;
