import { WxGroup } from '../types/state';
import { BaseAction } from '../types/action';
import { ACTION_SET_GROUP_LIST, ACTION_MERGE_GROUP_LIST } from '../constant/index';

export default (state: WxGroup[] = [], action: BaseAction) => {
  switch (action.type) {
    case ACTION_SET_GROUP_LIST:
      return action.data;
    case ACTION_MERGE_GROUP_LIST:
      return [...state, ...action.data];
    default:
      return state;
  }
};
