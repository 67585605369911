import axios from 'axios';
import map from '../protobuf/map';
import { Uint8ArryToString, pbEncode, pbDecode, getUrlPath } from '../util';
import { getParamValue } from '@/util';
import store from '@/store';

const wxAlias = getParamValue('alias');
const wxId = '';
const cmdId = 1;
const http = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  responseType: 'arraybuffer',
});
function Log(data: any, r: any, type: string) {
  const enableLog = getParamValue('enable_log');
  if (enableLog !== 'true') return;
  try {
    if (type === 'req') {
      const api = (r && r.url.split('api')[1]) || null;
      console.log(`[${new Date().toString().substr(16, 8)}] req %c ${api} : `, 'color:#3a3;', data);
    }
    if (type === 'res') {
      const api =
        (r && r.request && r.request.responseURL && r.request.responseURL.split('api')[1]) || null;
      if (api !== null) {
        console.log(`[${new Date().toString().substr(16, 8)}] res %c ${api} : `, 'color:#f00;', {
          ...data,
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
}

http.interceptors.request.use(newConfig => {
  const path = getUrlPath(newConfig.url as string);
  const urlConfig = map[path];
  const pb = urlConfig && urlConfig.request;
  const wrap = urlConfig && urlConfig.wrap;
  let baseReq: any;
  if (wrap) {
    baseReq = {
      wxAlias,
      wxId,
      cmdId,
    };
    if (store) {
      const { clients } = store.getState() as any;

      if (clients.current) {
        baseReq.wxAlias = clients.current.wxAlias;
        baseReq.wxId = clients.current.wxId;
      }
    }
    baseReq.data = pbEncode(pb, newConfig.data);
  } else {
    baseReq = newConfig.data;
  }
  Log(JSON.stringify(newConfig.data), newConfig, 'req');
  let reqData = pbEncode('wpb.BaseRequest', baseReq);
  reqData = btoa(Uint8ArryToString(reqData));
  const form = new FormData();
  form.append('body', reqData);
  newConfig.data = form; // eslint-disable-line
  return newConfig;
});

http.interceptors.response.use(response => {
  const res = pbDecode('wpb.BaseResponse', new Uint8Array(response.data));
  if (res.ret === 200) {
    if (res.data) {
      const path = getUrlPath(response.config.url as string);
      const pb = map[path].response;
      const data = pbDecode(pb, res.data);
      Log(data, response, 'res');
      return data;
    }
    return response;
  }
  if (res.ret === 401) {
    const host = window.location.host.split('.');
    host[0] = 'op';
    window.location.href = `//${host.join('.')}/user/login`;
  } else {
    console.log(new Error(res.errMsg));
    return res;
  }
  return response;
});

export default http;
