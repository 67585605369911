import React from 'react';
// import LazyLoad from 'react-lazyload';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, Input } from 'antd';
import debounce from 'lodash/debounce';
import { State, WxClient } from '@/store/types/state';
import {
  createGetClientListAction,
  createSetCurrentClientAction,
  createSetCurrentTargetAction,
  createGetMockSessionListAction,
  createGetWxSessionListAction,
} from '@/store/action';
import { requestSetInterval } from '@/util';
import ClientItem from '../../component/ClientItem';
import styles from './AccountPanel.module.less';

interface Prop {
  clientList: WxClient[];
  currentClient?: WxClient;
  getClientList: (reqType: string) => any;
  setCurrentClient: (e: WxClient) => any;
  setCurrentTarget: (data: any) => any;
  setMockSessionlist: (data: any) => any;
  getSessionList: () => void;
}

class AccountPanel extends React.Component<Prop> {
  state = {
    searchText: '',
    searchList: [],
    visible: false, // 下拉菜单是否展示
  };

  componentDidMount() {
    this.props.getClientList('init');
    requestSetInterval(() => {
      this.props.getClientList('interval');
    }, 15000);
  }

  handleChange = (e: any) => {
    e.persist();
    this.setState(
      {
        searchText: e.target.value,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleSearch = debounce(() => {
    const { searchText } = this.state;
    if (!searchText) {
      this.setState({
        searchList: [],
      });
      return;
    }

    const { clientList } = this.props;
    const list = clientList.filter(
      (client: WxClient) =>
        (client.nickname && client.nickname.indexOf(searchText) >= 0) ||
        (client.remarkName && client.remarkName.indexOf(searchText) >= 0),
    );

    this.setState({
      searchList: list,
    });
  }, 500);

  handleReset = () => {
    this.setState({
      searchText: '',
      searchList: [],
      visible: false,
    });
  };

  handlerClientItemClick = (e: any) => {
    // if (!e.statusOnline) {
    //   message.warning('该账号已离线！');
    //   return;
    // }
    this.props.setCurrentClient(e);
    this.props.setCurrentTarget(null);
    this.handleReset();
  };

  render() {
    const { searchText, searchList, visible } = this.state;
    const { currentClient, clientList } = this.props;
    const renderList = searchText ? searchList : clientList;
    return (
      <Dropdown
        placement="bottomLeft"
        overlayClassName={styles['client-list']}
        trigger={['click']}
        visible={visible}
        overlay={
          <>
            <Input
              className={styles['account-search']}
              onChange={this.handleChange}
              placeholder="输入备注/昵称搜索"
              spellCheck={false}
              value={searchText}
              allowClear
            />
            <div className={styles['scroll-list']}>
              {renderList.length ? (
                renderList.map((client, index) => (
                  <Link key={client.wxAlias + index} to={`/${client.wxAlias}`}>
                    <ClientItem
                      client={client}
                      currentClient={currentClient}
                      onClick={this.handlerClientItemClick}
                    />
                  </Link>
                ))
              ) : (
                <p className={styles['empty-tip']}>无匹配的账号</p>
              )}
            </div>
          </>
        }
      >
        <div>
          {currentClient ? (
            <ClientItem
              client={currentClient}
              currentClient={currentClient}
              onClick={() => {
                this.setState({
                  visible: !visible,
                });
              }}
            />
          ) : null}
        </div>
      </Dropdown>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    clientList: state.clients.list,
    currentClient: state.clients.current,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getClientList: (type?: string) => dispatch(createGetClientListAction(type)),
    setCurrentClient: (e: WxClient) => dispatch(createSetCurrentClientAction(e)),
    setCurrentTarget: (data: any) => dispatch(createSetCurrentTargetAction(data)),
    setMockSessionlist: (data: any) => dispatch(createGetMockSessionListAction(data)),
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 30 })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPanel);
