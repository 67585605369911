import React from 'react';
import { Button } from 'antd';
import './index.less';

interface Prop {
  groupInfo: {
    roomId: string;
    name: string;
    avatar: string;
    id: string;
  };
  onClick: (roomId: string) => any;
}

function GroupDetail(props: Prop) {
  const { roomId, name, avatar } = props.groupInfo;
  return (
    <div className="group-detail">
      <img className="group-detail-avarar" src={avatar} alt="head" />
      <div className="group-detail-nickname">
        <span>{name}</span>
      </div>
      <div className="group-detail-btn" onClick={() => props.onClick(roomId)}>
        发消息
      </div>
    </div>
  );
}

export default GroupDetail;
