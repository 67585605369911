import React from 'react';
import { Button, Icon, Popover, List, Input, message, Upload, Empty } from 'antd';
import moment from 'moment';
import './index.less';
import { connect } from 'react-redux';
import {
  createSendTextMessageAction,
  createSendImageMessageAction,
  createSendLuckyMoneyMessageAction,
  createSendLinkMessageAction,
  setCurrentWindowTabAction,
} from '@/store/action';
import { State, Target } from '@/store/types/state';
import { StringToUint8Array } from '@/util';
import ChatSub from './ChatSub';
import request from '@/http/request';
import eventBus from '@/ws/event';
import IconFont from '@/components/IconFont';

interface Prop {
  m1: any;
  m2: any;
  target: Target;
  current: any;
  sendTextMsg: (param: any) => any;
  sendImageMsg: (param: any) => any;
  sendLuckMoneyMsg: (param: any) => any;
  sendLinkMsg: (param: any) => any;
  setCurrentWindowTab: (data: string) => void;
}
interface OwnState {
  callmeList: any[];
}

class ChatBox extends React.Component<Prop, OwnState> {
  constructor(props: Prop) {
    super(props);
    this.state = {
      callmeList: [],
    };
  }

  child: any;

  chatListRef: any;

  callmeListRef: any;

  // componentDidMount() {
  //   this.setOldMessage(this.props);
  // }

  // componentWillReceiveProps(nextProps: any) {
  //   this.setOldMessage(nextProps);
  // }

  // setOldMessage(props: any) {
  //   const saveTargetId = `${window.localStorage.getItem('saveTargetId')}`;
  //   const saveMessage = window.localStorage.getItem('saveMessage');
  //   const saveSenderMessage = window.localStorage.getItem('saveSenderMessage');
  //   if (saveTargetId === `${props.target.id}`) {
  //     this.child.setState({
  //       message: saveMessage,
  //       senderMessage: saveSenderMessage,
  //     });
  //   } else {
  //     this.child.setState({
  //       message: props.m1,
  //       senderMessage: props.m2,
  //     });
  //   }
  // }

  atMeItemClick = (item: any) => {
    // 定位到聊天记录
    eventBus.emit('messageRecordLocation', item.mid);
  };

  handlerFileChange = (e: any) => {
    const RULE = /image/gi;
    if (!RULE.test(e.file.type)) {
      message.warning('仅支持发送图片');
      return;
    }
    const URLObj = window.URL;
    const source = URLObj && URLObj.createObjectURL(e.file);
    this.handlerSendImage(e.file, source);
  };

  handlerSendImage = (file: any, source?: any) => {
    if (typeof file === 'undefined') return;
    if (!file || typeof file === 'string') {
      this.props.sendImageMsg({
        sessionId: this.props.target.id,
        image_url: source || '',
        imgSource: source || '',
        msgType: 3,
      });
    } else {
      const FILE = new FormData();
      FILE.append('content-type', 'multipart/form-data');
      FILE.append('file', file, file.name);
      this.props.sendImageMsg({
        sessionId: this.props.target.id,
        imgName: file.name,
        imgData: FILE,
        imgSource: source || '',
        msgType: 3,
      });
    }
    this.offlineNotification();
  };

  handlerSendText = (m: any, mentions?: any[]) => {
    this.props.sendTextMsg({
      sessionId: this.props.target.id,
      content: m,
      atList: mentions && mentions.length > 0 ? mentions.map((item: any) => item.id) : [],
      msgType: 1,
    });
    this.offlineNotification();
  };

  handleSendLuckMoney = (
    redPackId: number,
    amount: number,
    kind: number,
    payDesc: string,
    total: number,
    mchId: number,
    event: any,
  ) => {
    this.props.sendLuckMoneyMsg({
      sessionId: this.props.target.id,
      redPackId,
      amount,
      msgType: 49,
      imageUrl: 'https://hibaru.oss-cn-hangzhou.aliyuncs.com/static/img/红包利是.png',
      title: '点我领红包',
      desc: '我已等候多时，还不把我领走~',
      payDesc,
      total,
      kind,
      mchId,
      event,
    });
  };

  handlerSendLink = (linkSourceObj: any) => {
    this.props.sendLinkMsg({
      sessionId: this.props.target.id,
      linkUrl: linkSourceObj.linkUrl,
      mediaUrl: linkSourceObj.mediaUrl,
      title: linkSourceObj.title,
      description: linkSourceObj.description,
      msgType: 49,
    });
  };

  offlineNotification = () => {
    if (!this.props.current.statusOnline) {
      message.error('当前帐号已离线');
    }
  };

  setRef = (ref: any) => {
    this.child = ref;
  };

  getCallMeList() {
    request.get(`/im/v2/sessions/${this.props.target.id}/at_messages`).then((res: any) => {
      const { list } = res.data;
      this.setState(
        {
          callmeList: list
            .map((item: any) => {
              const {
                fromNickname,
                fromAvatar,
                atMe,
                mid: itemMid,
                msgContent: { content },
                createTime,
              } = item;
              return {
                nickName: fromNickname || '',
                text: atMe === 2 ? `@所有人 ${content}` : content,
                imgUrl: fromAvatar || '',
                mid: itemMid,
                createTime,
              };
            })
            .reverse(),
        },
        () => {
          if (!this.callmeListRef) return;
          this.callmeListRef.scrollTop = 99999;
        },
      );
    });
  }

  render() {
    const leftDomProps = {
      name: 'file',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onChange: this.handlerFileChange,
      beforeUpload: (file: any) => {
        return false;
      },
      accept: '.png, .jpg, .jpeg, .gif',
    };
    const leftDom = (
      <Upload {...leftDomProps}>
        <IconFont
          style={{ fontSize: 20, marginLeft: 5, cursor: 'poiner' }}
          iconfont="icontupian"
        ></IconFont>
      </Upload>
    );
    const rightDom =
      this.props.target.chatType !== 1 ? (
        <span
          className="chat-pannel-header-callme"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.getCallMeList();
          }}
        >
          <Popover
            // style={{
            // }}
            overlayClassName="at-me-overlay"
            content={
              this.state.callmeList.length <= 0 ? (
                <Empty
                  className="no-at-container im-empty-status"
                  description="没有@"
                  image="/noat.png"
                ></Empty>
              ) : (
                <div
                  className="at-me-style chat-pannel-header-callme-list scroll-box"
                  ref={node => {
                    this.callmeListRef = node;
                  }}
                >
                  <List
                    dataSource={this.state.callmeList}
                    renderItem={item => (
                      <List.Item
                        className="chat-pannel-header-callme-list-item"
                        onClick={() => this.atMeItemClick(item)}
                      >
                        <img
                          alt="header"
                          className="chat-pannel-header-callme-header"
                          src={item.imgUrl || '/defaultHead.jpg'}
                        />
                        <p>
                          <strong>{item.nickName}</strong>
                          {item.text}
                        </p>
                        <span>{moment(item.createTime).format('MM-DD')}</span>
                      </List.Item>
                    )}
                    size="large"
                  ></List>
                </div>
              )
            }
            placement="top"
            trigger="click"
          >
            @我
          </Popover>
        </span>
      ) : (
        <span
          className="chat-pannel-header-callme"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.props.setCurrentWindowTab('7');
          }}
        >
          朋友圈
        </span>
      );
    const bottomDom = (
      <div className="chat-pannel-bottom">
        <span className="send-tips">按Shift+Enter换行</span>
        <Button
          size="large"
          className="send-btn-default"
          onClick={() => {
            this.child.handlerSendBtnClick();
          }}
        >
          发送
        </Button>
        {/* <Button onClick={() => {
          console.log('红包')
          this.props.sendLuckMoneyMsg({
            sessionId: this.props.target.id,
            msgType: 49,
          })
        }}>红包</Button> */}
      </div>
    );
    return (
      <ChatSub
        leftHeader={leftDom}
        rightHeader={rightDom}
        bottomBlock={bottomDom}
        // saveStorage={this.handlerSaveStorage}
        sendImage={this.handlerSendImage}
        sendText={this.handlerSendText}
        sendLink={this.handlerSendLink}
        sendLuckMoney={this.handleSendLuckMoney}
        onRef={this.setRef}
        target={this.props.target}
        current={this.props.current}
      ></ChatSub>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
    current: state.clients && state.clients.current,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    sendTextMsg: (param: any) => {
      dispatch(createSendTextMessageAction(param));
    },
    sendImageMsg: (param: any) => {
      dispatch(createSendImageMessageAction(param));
    },
    sendLuckMoneyMsg: (param: any) => {
      dispatch(createSendLuckyMoneyMessageAction(param));
    },
    sendLinkMsg: (param: any) => {
      dispatch(createSendLinkMessageAction(param));
    },
    setCurrentWindowTab: (data: string) => dispatch(setCurrentWindowTabAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBox);
