import React, { Fragment, PureComponent } from 'react';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { Row, Col, Button, Input, DatePicker, Form, Select, Cascader } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { CascaderOptionType } from 'antd/es/cascader';
import request from '@/http/request';

const { TextArea } = Input;
const { Option } = Select;
const formItemLayoutForm = {
  labelCol: {
    xs: { span: 18 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const rowStyle = {
  marginBottom: 16,
};

const SEXS = ['未知', '男', '女'];

class UserCustomerData extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isModify: false,
      provinceList: [],
      cityList: [],
      userInfo: {
        name: '',
        phone: 0,
        sex: '0',
        country: 'CN',
        province: '',
        city: '',
        birthday: this.props.birthday,
        comment: '',
      },
    };
  }

  componentDidUpdate(prevProps: any) {
    if ((prevProps && prevProps.wxId !== this.props.wxId) || (!prevProps && this.props.wxId)) {
      this.setModify(false);
    }
  }

  submit = () => {
    const data = {
      ...this.state.userInfo,
    };
    data.birthday = moment(
      this.state.userInfo.birthday ? this.state.userInfo.birthday : this.props.birthday,
    ).format('YYYY-MM-DD');

    request
      .put(`/im/v2/customers/${this.props.wxId}/info`, {
        ...data,
      })
      .then(() => {
        this.setModify(false);
        this.props.initFn();
      });
  };

  // status:切换修改资料面板；updateAreaInfo:是否更新地区列表数据
  setModify = (status: boolean, updateAreaInfo: boolean = false) => {
    this.setState(
      {
        isModify: status,
      },
      () => {
        if (updateAreaInfo) {
          this.getAreasInfo();
        }
      },
    );
  };

  formChange(caseName: string, val: any) {
    this.setState((prevState: any) => {
      return {
        userInfo: {
          ...prevState.userInfo,
          [caseName]: val,
        },
      };
    });
  }

  changeFn = (val: any, caseName: string) => {
    if (caseName === 'province') {
      this.getAreasInfo(3, val);
    }
    this.formChange(caseName, val);
  };

  // ant-design日期选择器，默认传入两个参数
  // date为moment格式，不能直接使用；dateString为字符串xxxx-xx-xx
  dateChange = (date: any, dateString: any) => {
    this.formChange('birthday', dateString);
  };

  // 地区列表；1：国家；2：省份；3：市区；
  getAreasInfo = (type: number = 2, parentType: string = 'CN') => {
    request
      .get('/im/v2/wx_areas', {
        parent: parentType,
        category: type,
      })
      .then((res: any) => {
        if (type === 2) {
          this.setState({ provinceList: res.data });
          this.setState({
            userInfo: { ...this.props },
          });
        } else if (type === 3) {
          this.setState({ cityList: res.data });
        }
      });
  };

  render() {
    const { name, country, province, city, sex: propsSex, phone, birthday, comment } = this.props;
    // const initValue = { name, country, province, city, sex, phone, birthday, comment }
    const {
      isModify,
      provinceList,
      cityList,
      userInfo: { sex },
    } = this.state;
    return (
      <Fragment>
        <div className="panel-userdata-action" style={{ marginRight: '10px' }}>
          {isModify ? (
            <Fragment>
              <div style={{ background: '#e9e9e9' }}>
                <Button
                  size="small"
                  onClick={() => this.setModify(false)}
                  ghost
                  className="im-btn-default"
                  style={{ marginRight: '8px' }}
                >
                  取消
                </Button>
                <Button size="small" type="primary" ghost onClick={this.submit}>
                  保存
                </Button>
              </div>
            </Fragment>
          ) : (
            <Button
              onClick={() => this.setModify(true, true)}
              size="small"
              className="im-btn-default"
            >
              修改资料
            </Button>
          )}
        </div>
        <Row className="user-data">
          <Col span={24}>
            {!isModify ? (
              <Fragment>
                <Row style={rowStyle} className="im-card im-body-font-small">
                  <Col span={6}>姓名</Col>
                  <Col span={18} className="im-title-font-small">
                    {name || ''}
                  </Col>
                </Row>
                <Row style={rowStyle} className="im-card im-body-font-small">
                  <Col span={6}>手机</Col>
                  <Col span={18} className="im-title-font-small">
                    {phone || ''}
                  </Col>
                </Row>
                <Row style={rowStyle} className="im-card im-body-font-small">
                  <Col span={6}>性别</Col>
                  <Col span={18} className="im-title-font-small">
                    {SEXS[propsSex] || ''}
                  </Col>
                </Row>
                <Row style={rowStyle} className="im-card im-body-font-small">
                  <Col span={6}>地区</Col>
                  <Col span={18} className="im-title-font-small">
                    {`${(country && country.name) || ''} ${(province && province.name) ||
                      ''} ${(city && city.name) || ''}`}
                  </Col>
                </Row>
                <Row style={rowStyle} className="im-card im-body-font-small">
                  <Col span={6}>生日</Col>
                  <Col span={18} className="im-title-font-small">
                    {birthday === '0001-01-01T00:00:00Z'
                      ? ''
                      : new Date(birthday).toLocaleDateString()}
                  </Col>
                </Row>
                <Row style={rowStyle} className="im-card im-body-font-small">
                  <Col span={6}>备注</Col>
                  <Col span={18} className="im-title-font-small">
                    {comment || ''}
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <Fragment>
                <Form {...formItemLayoutForm} labelAlign="left" layout="inline" className="im-form">
                  <Form.Item label="姓名">
                    <Input
                      className="im-input"
                      defaultValue={name}
                      onChange={e => this.changeFn(e.target.value, 'name')}
                      id="name"
                      name="name"
                      placeholder="请输入客户姓名"
                    />
                  </Form.Item>
                  <Form.Item label="手机">
                    <Input
                      className="im-input"
                      defaultValue={phone}
                      onChange={e => this.changeFn(e.target.value, 'phone')}
                      id="phone"
                      name="phone"
                      placeholder="请输入客户联系电话"
                    />
                  </Form.Item>
                  <Form.Item label="性别">
                    <Select
                      onChange={(val: any) => this.changeFn(val, 'sex')}
                      defaultValue={SEXS[propsSex]}
                      style={{ fontSize: '12px' }}
                    >
                      <Option value="0" key="sex0">
                        未知
                      </Option>
                      <Option value="1" key="sex1">
                        男
                      </Option>
                      <Option value="2" key="sex2">
                        女
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="地区">
                    <Select
                      onChange={val => this.changeFn(val, 'country')}
                      id="country"
                      style={{ width: '31.2%', marginRight: 5, fontSize: '12px' }}
                    >
                      <Option value="CN">中国</Option>
                    </Select>
                    <Select
                      onChange={val => this.changeFn(val, 'province')}
                      id="province"
                      style={{ width: '32%', marginRight: 5, fontSize: '12px' }}
                    >
                      <Option key="initProvince" value="">
                        请选择
                      </Option>
                      {provinceList.map((item: any, index: number) => (
                        <Option key={item.regionCode + index} value={item.regionCode}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    <Select
                      onChange={val => this.changeFn(val, 'city')}
                      id="city"
                      style={{ width: '32%', fontSize: '12px' }}
                    >
                      <Option key="initCity" value="">
                        请选择
                      </Option>
                      {cityList.map((item: any, index: number) => (
                        <Option key={item.regionCode + index} value={item.regionCode}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="生日">
                    <DatePicker
                      id="birthday"
                      style={{ width: '100%' }}
                      placeholder="请选择生日"
                      locale={locale}
                      name="birthday"
                      defaultValue={moment(
                        birthday && birthday !== '0001-01-01T00:00:00Z'
                          ? birthday
                          : new Date('2010-01-01').toLocaleDateString(),
                      )}
                      defaultPickerValue={moment(
                        birthday && birthday !== '0001-01-01T00:00:00Z'
                          ? birthday
                          : new Date('2010-01-01').toLocaleDateString(),
                      )}
                      onChange={this.dateChange}
                    />
                  </Form.Item>
                  <Form.Item label="备注">
                    <TextArea
                      name="comment"
                      id="comment"
                      onChange={e => this.changeFn(e.target.value, 'comment')}
                      placeholder="请输入客户备注，200个字以内"
                      defaultValue={comment}
                    />
                  </Form.Item>
                </Form>
              </Fragment>
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

// interface IOptions {
//   value: string;
//   label: string;
//   isLeaf: boolean;
// }

// interface IFormBoxInnerState {
//   formLayout: 'inline' | 'horizontal' | 'vertical' | undefined;
//   options: IOptions[];
// }

// interface IFormBoxProps extends FormComponentProps {
//   name: string;
//   country: string;
//   province: string;
//   city: string;
//   sex: number;
//   phone: number;
//   birthday: string;
//   comment: string;
//   wxId: any;
// }

// const optionsPre = [
//   {
//     value: 'CN,CN,2',
//     label: '中国',
//     isLeaf: false,
//   },
// ];

// class FormBoxInner extends React.Component<IFormBoxProps, IFormBoxInnerState> {
//   constructor(props: any) {
//     super(props);
//     this.state = {
//       formLayout: 'horizontal',
//       options: optionsPre,
//     };
//   }

//   handleSubmit = () => {
//     this.props.form.validateFields((err, values) => {
//       const userInfo = {
//         name: values.name,
//         phone: Number(values.phone),
//         sex: Number(values.sex),
//         country: 'CN',
//         province: values.area.length > 1 ? values.area[1].split(',')[0] : undefined,
//         city: values.area.length > 2 ? values.area[2].split(',')[0] : undefined,
//         birthday: values.birthday,
//       };
//       request
//         .put(`/im/v2/customers/${this.props.wxId}/info`, {
//           ...userInfo,
//         })
//         .then(() => {
//           // this.setModify(false);
//           // this.props.initFn();
//         });
//     });
//   };

//   onChange = (value: string[], selectedOptions?: CascaderOptionType[] | undefined) => {};

//   loadData = (selectedOptions: CascaderOptionType[] | undefined) => {
//     if (selectedOptions) {
//       const targetOption = selectedOptions[selectedOptions.length - 1];
//       targetOption.loading = true;
//       request
//         .get('/im/v2/wx_areas', {
//           parent: targetOption.value!.split(',')[0],
//           category: targetOption.value!.split(',')[2],
//         })
//         .then((res: any) => {
//           targetOption.loading = false;
//           targetOption.children = res.data.map((item: any) => ({
//             label: item.name,
//             value: `${item.regionCode},${item['parent:omitempty']},${
//               item['parent:omitempty'] === 'CN' ? 3 : 2
//             }`,
//             isLeaf: item.category === 3,
//           }));
//           // 重置请打开注释，pass lint
//           // this.setState(prevState => {
//           //   return {
//           //     options: [...this.state.options],
//           //   };
//           // });
//         });
//     }
//   };

//   render() {
//     const { formLayout, options } = this.state;
//     const { getFieldDecorator } = this.props.form;
//     const { phone, name, comment, country, province, city } = this.props;
//     const formItemLayout =
//       formLayout === 'horizontal'
//         ? {
//             labelCol: { span: 4 },
//             wrapperCol: { span: 20 },
//           }
//         : null;
//     const buttonItemLayout =
//       formLayout === 'horizontal'
//         ? {
//             wrapperCol: { span: 14, offset: 4 },
//           }
//         : null;
//     return (
//       <div>
//         <Form layout={formLayout}>
//           <Form.Item label="姓名：" {...formItemLayout}>
//             {getFieldDecorator('name', {
//               initialValue: name,
//             })(<Input placeholder="请输入姓名" />)}
//           </Form.Item>
//           <Form.Item label="手机：" {...formItemLayout}>
//             {getFieldDecorator('phone', {
//               initialValue: phone,
//             })(<Input placeholder="请输入手机号" />)}
//           </Form.Item>
//           <Form.Item label="性别：" {...formItemLayout}>
//             {getFieldDecorator('sex', {
//               initialValue: 1,
//             })(
//               <Select>
//                 <Option value={1}>男</Option>
//                 <Option value={2}>女</Option>
//               </Select>,
//             )}
//           </Form.Item>
//           <Form.Item label="地区" {...formItemLayout}>
//             {getFieldDecorator('area', {
//               initialValue: [],
//               rules: [{ type: 'array', message: '请选择地区' }],
//             })(
//               <Cascader
//                 options={options}
//                 loadData={this.loadData}
//                 onChange={this.onChange}
//                 changeOnSelect
//               />,
//             )}
//           </Form.Item>
//           <Form.Item label="生日：" {...formItemLayout}>
//             <DatePicker id="birthday" name="birthday" />
//           </Form.Item>
//           <Form.Item label="备注：" {...formItemLayout}>
//             {getFieldDecorator('comment', {
//               initialValue: comment,
//             })(<TextArea placeholder="请输入手机号" />)}
//           </Form.Item>
//           <Form.Item {...buttonItemLayout}>
//             <Button type="primary" onClick={this.handleSubmit}>
//               Submit
//             </Button>
//           </Form.Item>
//         </Form>
//       </div>
//     );
//   }
// }

// const FormBox = Form.create({ name: 'register' })(FormBoxInner);

export default UserCustomerData;
