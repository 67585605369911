import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  Avatar,
  List,
  Dropdown,
  Button,
  Menu,
  Popconfirm,
  Modal,
  Input,
  Icon,
  Popover,
  message,
} from 'antd';
import { State } from '@/store/types/state';
import UserCard from '@/components/UserCard';

import request from '@/http/request';
import AddQunMember from '../AddQunMember';
import { createGetWxFriendListAction, createGetGroupMemberListAction } from '@/store/action';

const DELETEMEMBERAPI = '/v1/group/member/remove';
const QunIdentity = (props: any) => {
  const { smallHeadUrl, nickname, role } = props;
  const IsQunMaster = role ? (
    <span className="isQunMaster">{role === 2 ? '群主' : '管理员'}</span>
  ) : (
    ''
  );
  return (
    <List.Item.Meta
      className="im-list-item-meta"
      avatar={
        <Avatar
          src={smallHeadUrl || '/defaultHead.jpg'}
          style={{ width: '38px', height: '38px' }}
        />
      }
      title={
        <span style={{ userSelect: 'none' }}>
          {nickname}
          {IsQunMaster}
        </span>
      }
    />
  );
};

class GroupMembers extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      addQunModalVisible: false,
      validateVisible: false,
      reason: '',
      qunListSearchWord: '',
    };
  }

  nextIndex: number = 0;

  loading: boolean = false;

  qunName: any = '';

  addQunMemberChildCopyData: any[] = [];

  child: any;

  // componentDidMount() {
  //   this.props.newGetMemeberList({ groupId: this.props.groupId, start: 0 });
  // }

  componentDidUpdate(prevProps: any) {
    if (
      (this.props.target && this.props.target.sessionId) !==
      (prevProps.target && prevProps.target.sessionId)
    ) {
      this.switchGroupHandler();
      this.props.newGetMemeberList({
        groupId: this.props.target.chatId || this.props.target.id,
        start: 0,
      });
    }
  }

  onLoadMore = () => {
    const { currentGroupInfo, groupId } = this.props;
    this.props.getMoreMemeberList(
      {
        groupId,
        start: currentGroupInfo.nextIndex,
      },
      true,
    );
  };

  // 移除群成员
  deleteMember = (wxId: number, currentClient: any) => {
    request
      .post(DELETEMEMBERAPI, {
        group_id: this.props.groupId,
        member_list: `${wxId}`,
        from_wx_id: currentClient,
      })
      .then(() => {
        this.props.newGetMemeberList(
          {
            groupId: this.props.groupId,
            start: 0,
          },
          false,
        );
      });
  };

  // 加入黑名单
  addToBlackList = (wxId: number) => {
    request
      .post('/v2/wx_user/black/list', {
        groupId: this.props.groupId,
        wxId,
      })
      .then(() => {
        setTimeout(
          this.props.newGetMemeberList(
            {
              groupId: this.props.groupId,
              start: 0,
              count: 1000,
            },
            false,
          ),
          1500,
        );
      });
  };

  switchGroupHandler = () => {
    this.setState({
      qunListSearchWord: '',
    });
  };

  openAddQunMember = () => {
    this.props.getFriendList();
    this.setState({
      addQunModalVisible: true,
    });
  };

  confirmAddQunMember = () => {
    if (this.props.auditStatus === 2 && this.props.currRole === 0) {
      // auditStatus == 2 表示群主开启了进群审核
      // role:0
    } else {
      this.addToQun();
    }
  };

  confirmAddQunValidate = () => {
    this.addToQun();
  };

  // 群添加新成员
  addToQun = () => {
    const wxIds = this.addQunMemberChildCopyData.map((item: any) => item.wxId);

    request
      .post('v2/group/members', {
        wxId: this.props.currentClient.wxId,
        groupId: this.props.groupId,
        wxIds,
        reason: this.state.reason,
      })
      .then((res: any) => {
        if (res.code === 200 && res.msg === '操作成功') {
          this.child.clearQunData();
          setTimeout(
            this.props.newGetMemeberList(
              {
                groupId: this.props.groupId,
                start: 0,
              },
              false,
            ),
            1000,
          );
        }
      });
    this.setState({
      addQunModalVisible: false,
    });
  };

  qunMemberSearch = debounce(() => {
    const { groupId } = this.props;
    this.props.getMoreMemeberList(
      {
        groupId,
        start: 0,
        q: this.state.qunListSearchWord,
      },
      false,
    );
  }, 500);

  qunMemberSearchInput = (e: any) => {
    this.setState(
      {
        qunListSearchWord: e.target.value,
      },
      () => {
        this.qunMemberSearch();
      },
    );
  };

  render() {
    const {
      addQunModalVisible,
      qunListSearchWord,
      btnStatus,
      validateVisible,
      reason,
    } = this.state;
    const { currentGroupInfo, friendList, currentClient, currRole } = this.props;
    const { list, isEnd, group } = currentGroupInfo;
    const loadMore =
      !this.loading && !isEnd ? (
        <div className="qun-member-loadmore">
          <Button onClick={this.onLoadMore}>加载更多</Button>
        </div>
      ) : null;

    return (
      <>
        <Modal
          visible={addQunModalVisible}
          width={1220}
          closable={false}
          destroyOnClose
          style={{ top: 40 }}
          className="add-modal"
          onCancel={() => {
            this.setState({ addQunModalVisible: false });
          }}
          footer={[
            <Button
              key="back"
              className="im-modal-cancel-btn"
              onClick={() => this.setState({ addQunModalVisible: false })}
            >
              取消
            </Button>,
            <Button
              key="submit"
              className="im-modal-confirm-btn"
              onClick={this.confirmAddQunMember}
              type="primary"
              disabled={!btnStatus}
            >
              确认
            </Button>,
          ]}
        >
          <AddQunMember
            friendList={friendList}
            memberlist={list}
            currClientNickname={currentClient.nickname}
            onRef={(ref: any) => {
              this.child = ref;
            }}
            onRefCopyData={(data: any) => {
              if (!this.addQunMemberChildCopyData.length && data.length) {
                this.setState({
                  btnStatus: true,
                });
              } else if (this.addQunMemberChildCopyData.length && !data.length) {
                this.setState({
                  btnStatus: false,
                });
              }
              this.addQunMemberChildCopyData = [...data];
            }}
          />
        </Modal>
        <Modal
          visible={validateVisible}
          onCancel={() => {
            this.setState({ validateVisible: false });
          }}
          onOk={this.confirmAddQunValidate}
        >
          <div>
            <div>群主或管理员已启用“群聊邀请确认”，邀请朋友进群可向群主或管理员描述原因。</div>
            <Input
              placeholder="说明邀请理由"
              allowClear
              value={reason}
              onChange={(e: any) => {
                this.setState({ reason: e.target.value });
              }}
            />
          </div>
        </Modal>
        <List
          className="qun-member-list"
          split={false}
          header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className="im-title-font im-bold">{`群成员：${group.memberCount}`}</span>
              <Button
                className="im-btn-icon"
                onClick={this.openAddQunMember}
                ghost
                type="primary"
                size="small"
              >
                <Icon type="plus" />
                添加成员
              </Button>
            </div>
          }
          loading={false}
          loadMore={loadMore}
          size="small"
        >
          <List.Item className="qun-search">
            <Input
              style={{ marginRight: '10px', width: '300px' }}
              placeholder="搜索群成员"
              onChange={this.qunMemberSearchInput}
              value={qunListSearchWord}
              allowClear
            />
          </List.Item>
          {list.map((item: any) => {
            if (currentClient.wxId !== item.wxId) {
              if ((currRole === 1 && item.role === 0) || (currRole === 2 && item.role !== 2)) {
                return (
                  <Dropdown
                    key={item.id}
                    overlay={
                      <Menu>
                        <Menu.Item key="1">
                          <Popconfirm
                            title={`确认将 ${item.nickname} 从 ${group.groupName ||
                              '未命名的群'} 中移除？`}
                            okText="确认"
                            cancelText="取消"
                            onConfirm={() => {
                              this.deleteMember(item.wxId, currentClient.wxId);
                            }}
                          >
                            <div>移除该成员</div>
                          </Popconfirm>
                        </Menu.Item>
                        <Menu.Item key="2">
                          <Popconfirm
                            title={`确认将 ${item.nickname} 加入黑名单？`}
                            okText="确认"
                            cancelText="取消"
                            onConfirm={() => {
                              this.addToBlackList(item.wxId);
                            }}
                          >
                            <div>加入黑名单</div>
                          </Popconfirm>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['contextMenu']}
                  >
                    <Popover
                      placement="leftBottom"
                      trigger="click"
                      content={
                        <UserCard
                          groupId={item.groupId}
                          memberWxId={item.wxId}
                          wxId={currentClient.wxId}
                        ></UserCard>
                      }
                    >
                      <List.Item className="group-item">
                        <QunIdentity {...item} />
                      </List.Item>
                    </Popover>
                  </Dropdown>
                );
              }
              return (
                <Dropdown
                  key={item.id}
                  overlay={
                    <Menu>
                      <Menu.Item key="2">
                        <Popconfirm
                          title={`确认将 ${item.nickname} 加入黑名单？`}
                          okText="确认"
                          cancelText="取消"
                          onConfirm={() => {
                            this.addToBlackList(item.wxId);
                          }}
                        >
                          <div>加入黑名单</div>
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['contextMenu']}
                >
                  <Popover
                    key={item.id}
                    placement="leftBottom"
                    trigger="click"
                    content={
                      <UserCard
                        groupId={item.groupId}
                        memberWxId={item.wxId}
                        wxId={currentClient.wxId}
                      ></UserCard>
                    }
                  >
                    <List.Item className="group-item">
                      <QunIdentity {...item} />
                    </List.Item>
                  </Popover>
                </Dropdown>
              );
            }
            return (
              <List.Item key={item.wxId} className="group-item">
                <QunIdentity {...item} />
              </List.Item>
            );
          })}
        </List>
      </>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
    friendList: state.friendList,
    currentClient: state.clients.current,
    currentGroupInfo: state.currentGroupInfo,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getFriendList: () => dispatch(createGetWxFriendListAction({ count: 5000 })),
    newGetMemeberList: (param: any, merge: boolean) =>
      dispatch(createGetGroupMemberListAction(param, merge)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupMembers);
