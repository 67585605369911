/*
  eslint-disable block-scoped-var,
  id-length,
  no-control-regex,
  no-magic-numbers,
  no-prototype-builtins,
  no-redeclare,
  no-shadow,
  no-var,
  sort-vars
*/
import * as $protobuf from 'protobufjs/light';

const $root = ($protobuf.roots.default || ($protobuf.roots.default = new $protobuf.Root())).addJSON(
  {
    wpb: {
      nested: {
        BaseRequest: {
          fields: {
            cmdId: {
              type: 'int32',
              id: 1,
            },
            wxAlias: {
              type: 'string',
              id: 2,
            },
            wxId: {
              type: 'string',
              id: 3,
            },
            seq: {
              type: 'int64',
              id: 4,
            },
            data: {
              type: 'bytes',
              id: 5,
            },
            deviceId: {
              type: 'string',
              id: 6,
            },
          },
        },
        BaseResponse: {
          fields: {
            ret: {
              type: 'int32',
              id: 1,
            },
            errMsg: {
              type: 'string',
              id: 2,
            },
            cmdId: {
              type: 'int32',
              id: 3,
            },
            seq: {
              type: 'int64',
              id: 4,
            },
            data: {
              type: 'bytes',
              id: 5,
            },
          },
        },
        ModUserInfo: {
          fields: {
            userName: {
              type: 'string',
              id: 1,
            },
            alias: {
              type: 'string',
              id: 2,
            },
            nickName: {
              type: 'string',
              id: 3,
            },
            remarkName: {
              type: 'string',
              id: 4,
            },
            headImg: {
              type: 'string',
              id: 5,
            },
            sex: {
              type: 'int32',
              id: 6,
            },
            country: {
              type: 'string',
              id: 7,
            },
            province: {
              type: 'string',
              id: 8,
            },
            city: {
              type: 'string',
              id: 9,
            },
            label: {
              type: 'string',
              id: 10,
            },
          },
        },
        WxSession: {
          fields: {
            sessionId: {
              type: 'int32',
              id: 1,
            },
            wxId: {
              type: 'string',
              id: 2,
            },
            chatId: {
              type: 'string',
              id: 3,
            },
            headImg: {
              type: 'string',
              id: 4,
            },
            nickname: {
              type: 'string',
              id: 5,
            },
            status: {
              type: 'int32',
              id: 6,
            },
            newUnread: {
              type: 'int32',
              id: 7,
            },
            newUnreadAt: {
              type: 'int32',
              id: 8,
            },
            lastMsg: {
              type: 'WxMessage',
              id: 9,
            },
            single: {
              type: 'bool',
              id: 10,
            },
            silence: {
              type: 'int32',
              id: 11,
            },
            chatType: {
              type: 'int32',
              id: 12,
            },
          },
        },
        WxMessage: {
          fields: {
            sessionId: {
              type: 'int32',
              id: 1,
            },
            fromWxId: {
              type: 'string',
              id: 2,
            },
            toWxId: {
              type: 'string',
              id: 3,
            },
            msgType: {
              type: 'int32',
              id: 4,
            },
            rawMsg: {
              type: 'string',
              id: 5,
            },
            id: {
              type: 'int32',
              id: 6,
            },
            time: {
              type: 'int64',
              id: 7,
            },
          },
        },
        WxAccount: {
          fields: {
            wxId: {
              type: 'string',
              id: 1,
            },
            wxAlias: {
              type: 'string',
              id: 2,
            },
            nickname: {
              type: 'string',
              id: 3,
            },
            remark: {
              type: 'string',
              id: 4,
            },
            headImg: {
              type: 'string',
              id: 5,
            },
            sex: {
              type: 'int32',
              id: 6,
            },
            country: {
              type: 'string',
              id: 7,
            },
            province: {
              type: 'string',
              id: 8,
            },
            city: {
              type: 'string',
              id: 9,
            },
            newUnread: {
              type: 'int32',
              id: 10,
            },
            statusOnline: {
              type: 'int32',
              id: 11,
            },
            label: {
              type: 'string',
              id: 12,
            },
            phones: {
              type: 'string',
              id: 13,
            },
            description: {
              type: 'string',
              id: 14,
            },
            source: {
              type: 'string',
              id: 15,
            },
            sysRemark: {
              type: 'string',
              id: 16,
            },
          },
        },
        WxClient: {
          fields: {
            wxId: {
              type: 'string',
              id: 1,
            },
            wxAlias: {
              type: 'string',
              id: 2,
            },
            ipAddr: {
              type: 'string',
              id: 3,
            },
            version: {
              type: 'string',
              id: 4,
            },
            status: {
              type: 'int32',
              id: 5,
            },
            nickname: {
              type: 'string',
              id: 6,
            },
            headImg: {
              type: 'string',
              id: 7,
            },
            newUnread: {
              type: 'int32',
              id: 8,
            },
            statusOnline: {
              type: 'int32',
              id: 9,
            },
            label: {
              type: 'string',
              id: 10,
            },
            phones: {
              type: 'string',
              id: 11,
            },
            description: {
              type: 'string',
              id: 12,
            },
            source: {
              type: 'string',
              id: 13,
            },
            sysRemark: {
              type: 'string',
              id: 14,
            },
            remark: {
              type: 'string',
              id: 15,
            },
          },
        },
        WxFriend: {
          fields: {
            wxId: {
              type: 'string',
              id: 1,
            },
            wxAlias: {
              type: 'string',
              id: 2,
            },
            nickname: {
              type: 'string',
              id: 3,
            },
            remarkName: {
              type: 'string',
              id: 4,
            },
            headImg: {
              type: 'string',
              id: 5,
            },
            sex: {
              type: 'int32',
              id: 6,
            },
            country: {
              type: 'string',
              id: 7,
            },
            province: {
              type: 'string',
              id: 8,
            },
            city: {
              type: 'string',
              id: 9,
            },
            labelId: {
              type: 'string',
              id: 10,
            },
          },
        },
        WxGroup: {
          fields: {
            groupId: {
              type: 'string',
              id: 1,
            },
            groupName: {
              type: 'string',
              id: 2,
            },
            groupHeadImg: {
              type: 'string',
              id: 3,
            },
            ownerId: {
              type: 'string',
              id: 4,
            },
          },
        },
        WxFriendApply: {
          fields: {
            fromWxId: {
              type: 'string',
              id: 1,
            },
            content: {
              type: 'string',
              id: 2,
            },
            ticket: {
              type: 'string',
              id: 3,
            },
            status: {
              type: 'int32',
              id: 4,
            },
            nickname: {
              type: 'string',
              id: 5,
            },
            headImg: {
              type: 'string',
              id: 6,
            },
            auditReason: {
              type: 'string',
              id: 7,
            },
            sex: {
              type: 'int32',
              id: 8,
            },
            location: {
              type: 'string',
              id: 9,
            },
            time: {
              type: 'int64',
              id: 10,
            },
          },
        },
        ReportNewMsg: {
          fields: {
            session: {
              type: 'WxSession',
              id: 1,
            },
          },
        },
        ReportSysMsg: {
          fields: {
            sessionId: {
              type: 'int32',
              id: 1,
            },
            content: {
              type: 'string',
              id: 2,
            },
            createTime: {
              type: 'int64',
              id: 3,
            },
          },
        },
        WxAccountDetail: {
          fields: {
            account: {
              type: 'WxAccount',
              id: 1,
            },
            client: {
              type: 'WxClient',
              id: 2,
            },
          },
        },
        WxClients: {
          fields: {
            ipAddr: {
              type: 'string',
              id: 1,
            },
            clients: {
              rule: 'repeated',
              type: 'WxClient',
              id: 2,
            },
          },
        },
        GetWxAccountListRequest: {
          fields: {
            start: {
              type: 'int32',
              id: 1,
            },
            limit: {
              type: 'int32',
              id: 2,
            },
          },
        },
        GetWxAccountListResponse: {
          fields: {
            list: {
              rule: 'repeated',
              type: 'WxAccountDetail',
              id: 1,
            },
            nextId: {
              type: 'int32',
              id: 2,
            },
          },
        },
        GetWxFriendListRequest: {
          fields: {
            start: {
              type: 'int32',
              id: 1,
            },
            limit: {
              type: 'int32',
              id: 2,
            },
          },
        },
        GetWxFriendListResponse: {
          fields: {
            list: {
              rule: 'repeated',
              type: 'WxFriend',
              id: 1,
            },
            nextId: {
              type: 'int32',
              id: 2,
            },
            isEnd: {
              type: 'bool',
              id: 3,
            },
          },
        },
        GetWxGroupListRequest: {
          fields: {
            start: {
              type: 'int32',
              id: 1,
            },
            limit: {
              type: 'int32',
              id: 2,
            },
          },
        },
        GetWxGroupListResponse: {
          fields: {
            list: {
              rule: 'repeated',
              type: 'WxGroup',
              id: 1,
            },
            nextId: {
              type: 'int32',
              id: 2,
            },
            isEnd: {
              type: 'bool',
              id: 3,
            },
          },
        },
        GetWxFriendApplyListRequest: {
          fields: {
            start: {
              type: 'int32',
              id: 1,
            },
            limit: {
              type: 'int32',
              id: 2,
            },
          },
        },
        GetWxFriendApplyListResponse: {
          fields: {
            list: {
              rule: 'repeated',
              type: 'WxFriendApply',
              id: 1,
            },
            nextId: {
              type: 'int32',
              id: 2,
            },
          },
        },
        AgreeFriendApplyRequest: {
          fields: {
            fromWxId: {
              type: 'string',
              id: 1,
            },
            ticket: {
              type: 'string',
              id: 2,
            },
          },
        },
        AgreeFriendApplyResponse: {
          fields: {},
        },
        IgnoreFriendApplyRequest: {
          fields: {
            fromWxId: {
              type: 'string',
              id: 1,
            },
            reason: {
              type: 'string',
              id: 2,
            },
          },
        },
        IgnoreFriendApplyResponse: {
          fields: {},
        },
        GetAccountDetailRequest: {
          fields: {},
        },
        GetAccountDetailResponse: {
          fields: {
            detail: {
              type: 'WxAccountDetail',
              id: 1,
            },
          },
        },
        SearchWxFriendListRequest: {
          fields: {
            start: {
              type: 'int32',
              id: 1,
            },
            limit: {
              type: 'int32',
              id: 2,
            },
            q: {
              type: 'string',
              id: 3,
            },
          },
        },
        SearchWxFriendListResponse: {
          fields: {
            list: {
              rule: 'repeated',
              type: 'WxFriend',
              id: 1,
            },
            nextId: {
              type: 'int32',
              id: 2,
            },
          },
        },
        GetWxClientListRequest: {
          fields: {
            start: {
              type: 'int32',
              id: 1,
            },
            limit: {
              type: 'int32',
              id: 2,
            },
          },
        },
        GetWxClientListResponse: {
          fields: {
            list: {
              rule: 'repeated',
              type: 'WxClients',
              id: 1,
            },
            nextId: {
              type: 'int32',
              id: 2,
            },
          },
        },
        GetOnlineWxClientsRequest: {
          fields: {},
        },
        GetOnlineWxClientsResponse: {
          fields: {
            list: {
              rule: 'repeated',
              type: 'WxClient',
              id: 1,
            },
          },
        },
        RestartWxClientRequest: {
          fields: {},
        },
        RestartWxClientResponse: {
          fields: {},
        },
        GetLoginQrcodeRequest: {
          fields: {},
        },
        GetLoginQrcodeResponse: {
          fields: {
            code: {
              type: 'bytes',
              id: 1,
            },
          },
        },
        GetLoginStatusRequest: {
          fields: {},
        },
        GetLoginStatusResponse: {
          fields: {
            status: {
              type: 'int32',
              id: 1,
            },
            wxId: {
              type: 'string',
              id: 2,
            },
          },
        },
        CheckLoginQrcodeRequest: {
          fields: {},
        },
        CheckLoginQrcodeResponse: {
          fields: {
            wxId: {
              type: 'string',
              id: 1,
            },
            status: {
              type: 'int32',
              id: 2,
            },
            nickName: {
              type: 'string',
              id: 3,
            },
            headImg: {
              type: 'string',
              id: 4,
            },
            expiredTime: {
              type: 'int32',
              id: 5,
            },
          },
        },
        GetWxSessionListRequest: {
          fields: {
            start: {
              type: 'int32',
              id: 1,
            },
            limit: {
              type: 'int32',
              id: 2,
            },
            typ: {
              type: 'int32',
              id: 3,
            },
          },
        },
        GetWxSessionListResponse: {
          fields: {
            list: {
              rule: 'repeated',
              type: 'WxSession',
              id: 1,
            },
            nextId: {
              type: 'int32',
              id: 2,
            },
            totalUnread: {
              type: 'int32',
              id: 3,
            },
            isEnd: {
              type: 'bool',
              id: 4,
            },
          },
        },
        GetWxMessageListRequest: {
          fields: {
            start: {
              type: 'int32',
              id: 1,
            },
            limit: {
              type: 'int32',
              id: 2,
            },
            sessionId: {
              type: 'int32',
              id: 3,
            },
            isNew: {
              type: 'bool',
              id: 4,
            },
          },
        },
        GetWxMessageListResponse: {
          fields: {
            list: {
              rule: 'repeated',
              type: 'WxMessage',
              id: 1,
            },
          },
        },
        SendTextRequest: {
          fields: {
            sessionId: {
              type: 'int32',
              id: 1,
            },
            content: {
              type: 'string',
              id: 2,
            },
            atList: {
              rule: 'repeated',
              type: 'string',
              id: 3,
            },
            clientImMsgId: {
              type: 'int64',
              id: 4,
            },
            chatId: {
              type: 'string',
              id: 5,
            },
          },
        },
        SendTextResponse: {
          fields: {
            clientImMsgId: {
              type: 'int64',
              id: 1,
            },
            msg: {
              type: 'WxMessage',
              id: 2,
            },
          },
        },
        SendImageRequest: {
          fields: {
            sessionId: {
              type: 'int32',
              id: 1,
            },
            imgName: {
              type: 'string',
              id: 2,
            },
            imgData: {
              type: 'bytes',
              id: 3,
            },
            clientImMsgId: {
              type: 'int64',
              id: 4,
            },
            chatId: {
              type: 'string',
              id: 5,
            },
            imgUrl: {
              type: 'string',
              id: 6,
            },
          },
        },
        SendImageResponse: {
          fields: {
            clientImMsgId: {
              type: 'int64',
              id: 1,
            },
            msg: {
              type: 'WxMessage',
              id: 2,
            },
          },
        },
        SendLinkRequest: {
          fields: {
            fromWxId: {
              type: 'string',
              id: 1,
            },
            toWxId: {
              type: 'string',
              id: 2,
            },
            title: {
              type: 'string',
              id: 3,
            },
            desc: {
              type: 'string',
              id: 4,
            },
            imgUrl: {
              type: 'string',
              id: 5,
            },
            url: {
              type: 'string',
              id: 6,
            },
            clientImMsgId: {
              type: 'int64',
              id: 7,
            },
            chatId: {
              type: 'string',
              id: 8,
            },
          },
        },
        SendLinkResponse: {
          fields: {
            clientImMsgId: {
              type: 'int64',
              id: 1,
            },
            msg: {
              type: 'WxMessage',
              id: 2,
            },
          },
        },
        CreateSessionRequest: {
          fields: {
            chatId: {
              type: 'string',
              id: 1,
            },
          },
        },
        CreateSessionResponse: {
          fields: {
            session: {
              type: 'WxSession',
              id: 1,
            },
          },
        },
        UploadImageRequest: {
          fields: {
            content: {
              type: 'bytes',
              id: 1,
            },
          },
        },
        UploadImageResponse: {
          fields: {
            imgUrl: {
              type: 'string',
              id: 1,
            },
          },
        },
        WxSnsTaskObject: {
          fields: {
            id: {
              type: 'int64',
              id: 1,
            },
            taskName: {
              type: 'string',
              id: 2,
            },
            status: {
              type: 'int32',
              id: 3,
            },
            type: {
              type: 'int32',
              id: 4,
            },
            text: {
              type: 'string',
              id: 5,
            },
            urls: {
              rule: 'repeated',
              type: 'string',
              id: 6,
            },
            remark: {
              type: 'string',
              id: 7,
            },
            startTime: {
              type: 'int64',
              id: 8,
            },
            createAt: {
              type: 'int64',
              id: 9,
            },
          },
        },
        WxSnsTaskResultObject: {
          fields: {
            id: {
              type: 'int64',
              id: 1,
            },
            sid: {
              type: 'string',
              id: 2,
            },
            taskId: {
              type: 'int64',
              id: 3,
            },
            wxAlias: {
              type: 'string',
              id: 4,
            },
            code: {
              type: 'int32',
              id: 5,
            },
            failReason: {
              type: 'string',
              id: 6,
            },
          },
        },
        WxSnsCommentObject: {
          fields: {
            id: {
              type: 'int64',
              id: 1,
            },
            cid: {
              type: 'string',
              id: 2,
            },
            taskId: {
              type: 'int64',
              id: 3,
            },
            content: {
              type: 'string',
              id: 4,
            },
          },
        },
        WxSnsTask: {
          fields: {
            task: {
              type: 'WxSnsTaskObject',
              id: 1,
            },
            results: {
              rule: 'repeated',
              type: 'WxSnsTaskResultObject',
              id: 2,
            },
            comments: {
              rule: 'repeated',
              type: 'WxSnsCommentObject',
              id: 3,
            },
          },
        },
        GetWxSnsTaskListRequest: {
          fields: {
            page: {
              type: 'int32',
              id: 1,
            },
            perPage: {
              type: 'int32',
              id: 2,
            },
            statuses: {
              rule: 'repeated',
              type: 'int32',
              id: 3,
            },
          },
        },
        GetWxSnsTaskListResponse: {
          fields: {
            list: {
              rule: 'repeated',
              type: 'WxSnsTask',
              id: 1,
            },
            total: {
              type: 'int32',
              id: 2,
            },
            perPage: {
              type: 'int32',
              id: 3,
            },
          },
        },
        GetWxSnsTaskRequest: {
          fields: {
            id: {
              type: 'int64',
              id: 1,
            },
          },
        },
        GetWxSnsTaskResponse: {
          fields: {
            task: {
              type: 'WxSnsTask',
              id: 1,
            },
          },
        },
        CreateWxSnsTaskRequest: {
          fields: {
            task: {
              type: 'WxSnsTaskObject',
              id: 1,
            },
            comments: {
              rule: 'repeated',
              type: 'WxSnsCommentObject',
              id: 2,
            },
            sendType: {
              type: 'int32',
              id: 3,
            },
          },
        },
        CreateWxSnsTaskResponse: {
          fields: {
            task: {
              type: 'WxSnsTask',
              id: 1,
            },
          },
        },
        DeleteWxSnsTaskRequest: {
          fields: {
            id: {
              type: 'int64',
              id: 1,
            },
          },
        },
        DeleteWxSnsTaskResponse: {
          fields: {},
        },
        SnsCommentInfo: {
          fields: {
            commentId: {
              type: 'int64',
              id: 1,
            },
            replyCommentId: {
              type: 'int64',
              id: 2,
            },
            content: {
              type: 'string',
              id: 3,
            },
            nickname: {
              type: 'string',
              id: 4,
            },
            username: {
              type: 'string',
              id: 5,
            },
            replyUsername: {
              type: 'string',
              id: 6,
            },
            createTime: {
              type: 'int64',
              id: 7,
            },
            type: {
              type: 'int64',
              id: 8,
            },
          },
        },
        SnsObject: {
          fields: {
            id: {
              type: 'uint64',
              id: 1,
            },
            nickName: {
              type: 'string',
              id: 2,
            },
            userName: {
              type: 'string',
              id: 3,
            },
            description: {
              type: 'string',
              id: 4,
            },
            createTime: {
              type: 'int64',
              id: 5,
            },
            commentUserList: {
              rule: 'repeated',
              type: 'SnsCommentInfo',
              id: 6,
            },
            likeUserList: {
              rule: 'repeated',
              type: 'SnsCommentInfo',
              id: 7,
            },
          },
        },
        GetSnsListRequest: {
          fields: {
            lastId: {
              type: 'uint64',
              id: 1,
            },
            friendId: {
              type: 'string',
              id: 2,
            },
          },
        },
        GetSnsListResponse: {
          fields: {
            list: {
              rule: 'repeated',
              type: 'SnsObject',
              id: 1,
            },
          },
        },
        SendSnsRequest: {
          fields: {
            content: {
              type: 'string',
              id: 1,
            },
            imgUrls: {
              rule: 'repeated',
              type: 'string',
              id: 2,
            },
          },
        },
        SendSnsResponse: {
          fields: {
            sid: {
              type: 'uint64',
              id: 1,
            },
          },
        },
        SendSnsCommentRequest: {
          fields: {
            sid: {
              type: 'uint64',
              id: 1,
            },
            to: {
              type: 'string',
              id: 2,
            },
            content: {
              type: 'string',
              id: 3,
            },
          },
        },
        SendSnsCommentResponse: {
          fields: {
            cid: {
              type: 'uint64',
              id: 1,
            },
          },
        },
        DeleteSnsRequest: {
          fields: {
            sid: {
              type: 'uint64',
              id: 1,
            },
          },
        },
        DeleteSnsResponse: {
          fields: {},
        },
        DeleteSnsCommentRequest: {
          fields: {
            sid: {
              type: 'uint64',
              id: 1,
            },
            cid: {
              type: 'uint64',
              id: 2,
            },
          },
        },
        DeleteSnsCommentResponse: {
          fields: {},
        },
      },
    },
  },
);

export { $root as default };
