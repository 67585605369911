import { put, takeEvery, call, all } from 'redux-saga/effects';
import store from '@/store';
import { getParamValue } from '@/util';
import { ACTION_GET_CLIENT_LIST, ACTION_SET_CURRENT_CLIENT } from '../constant';
import { fetchOnlineClientList } from '../../http';
import {
  createSetClientListAction,
  createSetCurrentClientAction,
  createGetMockSessionListAction,
  createSetSyncClientAction,
  createGetWxSessionListAction,
  setCurrentTabAction,
  createGetWxFissionSessionListAction,
} from '../action';
import { WxClient } from '../types/state';

export function* getClientList(action: any) {
  try {
    const { data: list } = yield call(fetchOnlineClientList);
    if (Array.isArray(list) && list.length > 0) {
      yield put(
        createSetClientListAction(
          list.sort((item: any, next: any) => {
            return next.statusOnline - item.statusOnline;
          }),
        ),
      );
      if (action.reqType === 'init') {
        const wxId = getParamValue('wxId');
        let initData: WxClient = list[0];
        if (wxId) {
          initData = list.find((client: WxClient) => client.wxId === wxId) || list[0];
        }
        // 初始化请求, 添加默认选中账号，默认选择账号列表中的第一个账号未初始登录账号
        yield put(createSetCurrentClientAction(initData));
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* syncClient(action: any) {
  if (action.data.isFission && store.getState().currentSiderTab[0] !== '0') {
    try {
      yield put(setCurrentTabAction('01'));
      yield put(createSetSyncClientAction(action.data));
      yield put(createGetWxFissionSessionListAction({ type: store.getState().currentSiderTab[1] }));
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  } else if (!action.data.isFission && store.getState().currentSiderTab[0] === '0') {
    yield put(createGetMockSessionListAction([]));
    yield put(setCurrentTabAction('1'));
  }
  const { currentSiderTab } = store.getState();
  try {
    const {
      data: { wxId: currClientWxId },
    } = action;
    // 使用缓存中的sessionList数据预先渲染会话列表
    if (
      currClientWxId &&
      localStorage.getItem(`${currClientWxId}-sessionList`) &&
      currentSiderTab === '1'
    ) {
      const storageData = localStorage.getItem(`${currClientWxId}-sessionList`) || '';
      let cache: any;
      try {
        cache = JSON.parse(storageData);
      } catch (e) {
        console.log(e);
      }
      if (cache) {
        yield put(createGetMockSessionListAction(cache));
      }
    }
    yield put(createSetSyncClientAction(action.data));

    // 请求真实会话列表数据并覆盖渲染
    if (currentSiderTab === '1') {
      yield put(
        createGetWxSessionListAction({
          limit: 30,
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetClientList() {
  yield takeEvery(ACTION_GET_CLIENT_LIST, getClientList);
}

export function* watchSyncClient() {
  yield takeEvery(ACTION_SET_CURRENT_CLIENT, syncClient);
}

export function* watchClient() {
  // yield all([watchGetClientList()]); // watchSyncClient()
  yield all([watchGetClientList(), watchSyncClient()]); // watchSyncClient()
}
