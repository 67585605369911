import React, { PureComponent } from 'react';
import { Icon } from 'antd';

const IconFontComponent = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1428597_levkrucldsc.js',
});
interface IIConFont {
  onClick: () => void;
  style: React.CSSProperties;
  iconfont: string;
}
class IconFont extends PureComponent<Partial<IIConFont>, any> {
  render() {
    return (
      <IconFontComponent
        style={this.props.style}
        onClick={this.props.onClick}
        type={this.props.iconfont}
      ></IconFontComponent>
    );
  }
}

export default IconFont;
