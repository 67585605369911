import React from 'react';
import { Divider } from 'antd';
import { connect } from 'react-redux';
import UserItem from '../../../UserItem';
import { State, WxGroup, Target } from '@/store/types/state';
import {
  createGetWxGroupListAction,
  createSetCurrentTargetAction,
  setCurrentWindowTabAction,
} from '@/store/action';

import { TARGET_TYPE_GROUP, TARGET_TYPE_NEW_GROUP } from '@/store/constant';
import eventBus from '@/ws/event';

interface IProp {
  clients: any;
  currentSiderTab: string;
  groupList: WxGroup[];
  getGroupList: (param?: any) => any;
  setCurrentTarget: (data: Target | null) => any;
  setCurrentWindowTab: (data: string) => void;
}

interface IState {
  isEnd: boolean;
  start: number;
  count: number;
}

class SiderTab extends React.Component<IProp, IState> {
  constructor(props: IProp) {
    super(props);
    this.state = {
      isEnd: false,
      start: 0,
      count: 30,
    };
  }

  componentDidMount() {
    this.props.getGroupList();
    // 更新请求list的参数
    eventBus.on('updateRequestGroupListParam', (param: any) => {
      this.setState(param, () => {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      });
    });
    eventBus.on('resetRequestGroupListParam', () => this.resetRequestListParam());
  }

  componentDidUpdate(prevProps: any) {
    // console.log('getGroupList>>>groupProps>>>', this.props)
    const currClientWxId = this.props.clients.current && this.props.clients.current.wxId;
    const PrevClientWxId = prevProps.clients.current && prevProps.clients.current.wxId;
    const { currentSiderTab } = this.props;
    const { currentSiderTab: prevSiderTab } = prevProps;
    const condition1 = currentSiderTab === '3';
    const condition2 = prevProps && prevSiderTab !== currentSiderTab && condition1;
    const condition3 = !prevProps && this.props;
    const condition4 = currClientWxId !== PrevClientWxId;
    if (condition1 && (condition2 || condition3 || condition4)) {
      this.resetRequestListParam();
      this.props.getGroupList();
    }
  }

  isLoading: boolean = false;

  handlerNewGroupClick = () => {
    this.props.setCurrentTarget({ id: '0', type: TARGET_TYPE_NEW_GROUP });
  };

  handlerUserItemClick = (id: any, type: string) => () => {
    this.props.setCurrentWindowTab('1');
    this.props.setCurrentTarget({ id, type });
  };

  // 重置list请求参数
  resetRequestListParam = () => {
    this.setState({
      isEnd: false,
      start: 0,
      count: 30,
    });
  };

  scrollHandler = (e: any) => {
    if (this.isLoading) return;

    const { start, count, isEnd } = this.state;
    const { scrollTop, scrollHeight, offsetHeight } = e.target;

    if (scrollTop + 100 >= scrollHeight - offsetHeight) {
      if (isEnd) return;

      this.isLoading = true;
      this.props.getGroupList({
        start: start > 0 ? start : count,
        count,
        isScrollLoading: true,
      });
    }
  };

  render() {
    const { groupList } = this.props;
    return (
      <div className="scroll-box" id="scroll-box-2" onScroll={e => this.scrollHandler(e)}>
        <UserItem
          onClick={this.handlerNewGroupClick}
          name="新入群申请"
          avatar="http://awb.img.xmtbang.com/img/uploadnew/201506/06/b4eb4d56e8ff47fd91ca5663ea03706d.jpg"
        />
        {groupList && groupList.length > 0
          ? groupList.map((item: any, index: number) => (
              <div style={{ position: 'relative' }} key={index}>
                <UserItem
                  avatar={item.avatar}
                  name={item.name}
                  onClick={this.handlerUserItemClick(item.roomId, TARGET_TYPE_GROUP)}
                ></UserItem>
                <Divider
                  style={{
                    margin: '0px 16px',
                    width: '90%',
                    minWidth: 'unset',
                    position: 'absolute',
                  }}
                />
              </div>
            ))
          : null}
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  const { currentSiderTab, groupList, clients } = state;
  return { currentSiderTab, groupList, clients };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getGroupList: (param?: any) => dispatch(createGetWxGroupListAction(param)),
    setCurrentTarget: (data: Target | null) => dispatch(createSetCurrentTargetAction(data)),
    setCurrentWindowTab: (data: string) => dispatch(setCurrentWindowTabAction(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiderTab);
