import { put, takeEvery, call } from 'redux-saga/effects';
import { ACTION_GET_ACCOUNT_INFO } from '../constant';
import { createSetAccountDetailAction } from '../action';
import { fetchAccountDetail } from '../../http';

export function* getAccountDetail(action: any) {
    try {
        const data = yield call(fetchAccountDetail, action.param);
        if (data.detail) {
            yield put(createSetAccountDetailAction(data.detail));
        }
    } catch (error) {
        console.error(error);
    }
}

export function* watchGetAccountDetail() {
    yield takeEvery(ACTION_GET_ACCOUNT_INFO, getAccountDetail);
}
