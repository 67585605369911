import { WxAccountDetail } from '../types/state';
import { BaseAction } from '../types/action';
import { ACTION_SET_ACCOUNT_INFO } from '../constant/index';

export default (
    state: WxAccountDetail | { account: Record<string, any>; client: Record<string, any> } = {
        account: {},
        client: {}
    },
    action: BaseAction
) => {
    switch (action.type) {
    case ACTION_SET_ACCOUNT_INFO:
        return action.data;
    default:
        return state;
    }
};
