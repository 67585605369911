import React from 'react';
import clx from 'classnames';
import { Tooltip, Badge, Row, Col } from 'antd';
// import LazyLoad from 'react-lazyload';
import { WxClient } from '@/store/types/state';
import './index.less';

interface Props {
  // login_wxId?: any;
  client: WxClient;
  currentClient?: WxClient;
  onClick?: (target: any) => void;
}

// function PlaceHolderComponent() {
//   return <img
//           className="chat-item-avatar-placeholder"
//           src="/defaultHead.jpg"
//           alt="placeHolader"
//         />;
// }

function ClientItem(props: Props) {
  const {
    nickname,
    remarkName,
    headImg = '',
    wxId,
    statusOnline,
    newUnread,
    wxAlias,
  } = props.client;
  const size = headImg.indexOf('wework') > -1 ? 100 : 132;
  const miniHeadImage =
    headImg && headImg.substr(-2) === '/0'
      ? `${headImg.substr(0, headImg.length - 1)}${size}`
      : headImg;
  return (
    <Tooltip placement="right" title={`${remarkName || nickname} （${wxAlias}）`}>
      <div
        data-id={wxId}
        className={clx('client-item active', {
          // active: statusOnline === 1,
          'current-item':
            (props.currentClient &&
              !props.currentClient.isFission &&
              props.currentClient.wxId === wxId) ||
            (props.currentClient && props.currentClient.isFission && !wxId),
        })}
        onClick={() => {
          if (props.onClick) {
            props.onClick(props.client);
          }
        }}
      >
        <Row type="flex" align="middle">
          <Col>
            {/* <LazyLoad
              height={44}
              resize
              overflow
              offset={100}
              placeholder={<PlaceHolderComponent />}
            > */}
            <Badge
              className="client-item-avatar"
              count={newUnread && newUnread > 0 ? newUnread : 0}
              overflowCount={99}
            >
              <img
                className="client-item-avatar"
                src={miniHeadImage || '/defaultHead.jpg'}
                alt="head"
              />
            </Badge>
            {/* </LazyLoad> */}
          </Col>
          <Col offset={2}>
            <div className="client-item-text-box">
              <div className="client-item-name">{remarkName || nickname}</div>
              {statusOnline === 1 ? (
                <div>
                  <Badge status="success" /> 在线
                </div>
              ) : (
                <div>
                  <Badge status="error" /> 离线
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Tooltip>
  );
}

ClientItem.defaultProps = {
  currentClient: null,
  onClick: null,
};

export default ClientItem;
