import React from 'react';
import { connect } from 'react-redux';
import { Badge, Tooltip, Dropdown, Menu, Popconfirm } from 'antd';
import moment from 'moment';
import request from '@/http/request';
import './index.less';
import {
  createTopSingleSessionAction,
  createUpdateCurrClientInfoAction,
  createSetCurrentTargetAction,
  createGetWxSessionListAction,
  createGetClientListAction,
  createUpdateSingleSessionAction,
} from '@/store/action';
import { Target, WxSession } from '@/store/types/state';

moment.locale('zh-cn');

interface Props {
  currentTarget: Target;
  clients?: any;
  chatItemClick: () => void;
  newUnread?: number;
  msgType?: number;
  item: WxSession;
  setCurrentTarget: (data: any) => any;
  getClientList: () => any;
  getSessionList: () => void;
  topSession: (data: any) => any;
  updateSingleClient: (data: any) => any;
  updateCurrSession: (data: any) => any;
  isFission?: boolean;
}

class ChatItem extends React.Component<Props> {
  static displayName = 'ChatItem';

  handleClick = (item: any) => {
    const {
      chatItemClick,
      updateCurrSession,
      item: { newUnread },
    } = this.props;
    chatItemClick();
    if (newUnread > 0) {
      // 会话消息数清零 / 有人@我状态清零
      updateCurrSession({
        ...item,
        newUnread: 0,
        newUnreadAt: 0,
      });
    }
  };

  badgeClick = (item: any) => {
    request.put(`/bee/v2/sessions/${item.id}/alert`, { alert: 1 - item.alert }).then(() => {
      this.props.updateCurrSession({
        ...item,
        alert: 1 - item.alert,
      });
    });
  };

  // 删除会话
  handleDeleteSession = (sessionId: number) => () => {
    const { getSessionList, setCurrentTarget } = this.props;
    request.delete(`/v2/im/sessions/${sessionId}`).then(() => {
      setCurrentTarget(null);
      getSessionList();
    });
  };

  // 消息控制
  // id: sessionId；type：1-开启消息免打扰，0-关闭消息免打扰
  handlerMessagePrompt = (id: number, type: number = 1) => () => {
    const { getSessionList, getClientList } = this.props;
    request
      .put(`/im/v2/sessions/${id}/silence`, {
        status: type,
      })
      .then(() => {
        getSessionList();
        getClientList();
      });
  };

  // 置顶操作
  // type；1：置顶；0：取消置顶
  placeToTopOperate = (type: number, session: any) => () => {
    request.put(`/im/v2/sessions/${session.id}/top`, { status: type }).then(() => {
      const { topSession, getSessionList } = this.props;
      if (type) {
        topSession([{ ...session, status: 1 }]);
      } else {
        getSessionList();
      }
    });
  };

  render() {
    const {
      item,
      item: {
        newUnread,
        newUnreadAt,
        nickname,
        headImg,
        silence,
        id,
        lastContent,
        msgTime,
        alert,
        comment,
      },
    } = this.props;
    const showName = nickname || '未命名';
    const showHead = headImg || '/defaultHead.jpg';
    const showTime = (() => {
      if (!msgTime) return '';
      if (moment(Date.now()).isSame(msgTime, 'day')) {
        return moment(msgTime).format('HH:mm');
      }
      return moment(msgTime).format('MM/DD');
    })();

    const menu = (sessionId: number) => (
      <Menu selectable={false}>
        {item.status && item.status === 1 ? (
          <Menu.Item key="2" onClick={this.placeToTopOperate(0, item)}>
            <div>取消置顶</div>
          </Menu.Item>
        ) : (
          <Menu.Item key="2" onClick={this.placeToTopOperate(1, item)}>
            <div>置顶会话</div>
          </Menu.Item>
        )}
        {!item.silence ? (
          <Menu.Item key="1" onClick={this.handlerMessagePrompt(sessionId, 1)}>
            <div>消息免打扰</div>
          </Menu.Item>
        ) : (
          <Menu.Item key="1" onClick={this.handlerMessagePrompt(sessionId, 0)}>
            <div>开启新消息提醒</div>
          </Menu.Item>
        )}
        <Menu.Item key="3">
          <Popconfirm
            title="确认删除会话?"
            onConfirm={this.handleDeleteSession(sessionId)}
            okText="确认"
            cancelText="取消"
            align={{
              points: ['bl', 'tl'], // align top left point of sourceNode with top right point of targetNode
              offset: [0, 48], // the offset sourceNode by 10px in x and 20px in y,
              targetOffset: ['50%', '50%'], // the offset targetNode by 30% of targetNode width in x and 40% of targetNode height in y,
              overflow: { adjustX: true, adjustY: true },
            }}
          >
            <div>删除会话</div>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );
    const content = (
      <div className="chat-item" onClick={() => this.handleClick(item)}>
        <div style={{ display: 'flex' }}>
          {/* <LazyLoad height={38} resize overflow placeholder={<PlaceHolderComponent />}> */}
          {silence ? (
            <Badge dot={newUnread > 0}>
              <img className="chat-item-avatar" src={showHead} alt="head" />
            </Badge>
          ) : (
            <Badge count={newUnread} overflowCount={99}>
              <img className="chat-item-avatar" src={showHead} alt="head" />
            </Badge>
          )}
          {/* </LazyLoad> */}
          <div className="chat-item-text-box">
            <span className="chat-item-name">{showName}</span>
            <span className="chat-item-time">{showTime}</span>
            {!alert ? (
              ''
            ) : (
              <span
                style={{
                  position: 'absolute',
                  right: '36px',
                  padding: '6px 8px 0 0',
                }}
                onClick={() => this.badgeClick(item)}
              >
                <Badge dot style={{ width: '11px', height: '11px' }}></Badge>
              </span>
            )}
            <span className="chat-item-msg">
              {newUnreadAt > 0 ? (
                <>
                  <span style={{ color: 'red' }}>[有人@我]</span>
                  {lastContent}
                </>
              ) : (
                lastContent
              )}
            </span>
            <span className="chat-item-prompt">
              {silence ? (
                <i style={{ color: '#D9D9D9', fontSize: 12 }} className="iconfont">
                  &#xe638;
                </i>
              ) : (
                ''
              )}
            </span>
          </div>
        </div>
        {this.props.isFission ? (
          <div
            style={{ paddingTop: '8px', lineHeight: '12px', fontSize: '12px', textAlign: 'center' }}
          >
            邀请：{comment.grossCount || 0}人 兑奖：{comment.exchangedCount || 0}人 未触发消息：
            {comment.noReplyCount || 0}
          </div>
        ) : (
          ''
        )}
      </div>
    );

    return (
      <Tooltip placement="right" title={showName}>
        {this.props.isFission ? (
          content
        ) : (
          <Dropdown overlay={menu(id)} placement="topLeft" trigger={['contextMenu']}>
            {content}
          </Dropdown>
        )}
      </Tooltip>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return {
    getClientList: () => dispatch(createGetClientListAction()),
    setCurrentTarget: (data: any) => dispatch(createSetCurrentTargetAction(data)),
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 30 })),
    topSession: (data: any) => dispatch(createTopSingleSessionAction(data)),
    updateSingleClient: (data: any) => dispatch(createUpdateCurrClientInfoAction(data)),
    updateCurrSession: (data: any) => dispatch(createUpdateSingleSessionAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatItem);
