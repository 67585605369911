import React, { useCallback, useState } from 'react';
import { Popover, Modal, Input, message, Radio } from 'antd';
import IconFont from '@/components/IconFont';
import styles from './LuckMoneyIconGroup.module.less';
import request from '@/http/request';

const { confirm, destroyAll } = Modal;

const iconPos = {
  fontSize: '20px',
  marginRight: '16px',
  lineHeight: '16px',
};

export function numberFormat(
  number: any,
  decimals: any,
  decPoint: any,
  thousandsSep: any,
  roundtag?: any,
) {
  /*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * dec_point：小数点符号
   * thousands_sep：千分位符号
   * roundtag:舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
   * */
  const numberInner = number.toString().replace(/[^0-9+-Ee.]/g, '');
  const n = !Number.isFinite(+numberInner) ? 0 : +numberInner;
  const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint;
  let s: any = '';

  const toFixedFix = (num: any, precs: any) => {
    const k = 10 ** precs;
    return (
      parseFloat(Math.ceil(parseFloat((num * k).toFixed(precs * 2))).toFixed(precs * 2)) / k
    ).toString();
  };

  s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
  const re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, `$1${sep}$2`);
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

interface LuckMoneyProps {
  onClick: (
    redPackId: number,
    price: number,
    kind: number,
    payDesc: string,
    total: number,
    mchId: number,
    event: any,
  ) => void;
}
interface CustomerMoneyItem {
  name: string;
  id: number;
}

const LuckyMoneyGroup: React.FC<LuckMoneyProps> = props => {
  const [customerMoneyList, setCustomerMoney] = useState<CustomerMoneyItem[]>([]);
  const inputNum: any = React.createRef();
  const inputMoney: any = React.createRef();

  function getRedPacks() {
    request.get('/im/v2/merchants/all').then((res: any) => {
      const { list } = res.data;
      if (!list.length) {
        message.error('暂无账户');
        return;
      }
      setCustomerMoney(
        list.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
          };
        }),
      );
    });
  }

  let closeFlag = false;

  let hbFlag = false;

  const onClose = (val: boolean) => {
    closeFlag = val;
  };

  const onHb = (val: boolean) => {
    hbFlag = val;
  };

  const openConfirmModal = useCallback(
    (
      title: string,
      item: any,
      price: number,
      redPackId: number,
      payDesc: string,
      total: number,
    ) => {
      confirm({
        className: 'confirm-modal',
        icon: null,
        title: '发送红包',
        content: (
          <div className={styles['modal-content']}>
            <p>
              您确定要给群好友发送
              <span className={styles['high-light']}>
                {total}个 “{title}”{' '}
              </span>
              红包吗？
            </p>
            <p>
              发送后，好友打开红包可获得
              <span className={styles['high-light']}>
                {numberFormat(price / 100, 2, '.', ',')}{' '}
              </span>
              元
            </p>
            <p>
              红包账户：
              <span style={{ color: 'red' }}>{item.name}</span>
            </p>
          </div>
        ),
        okText: '发送',
        cancelText: '取消',
        onOk() {
          destroyAll();
          if (!closeFlag) {
            onClose(true);
            props.onClick(redPackId, price, hbFlag ? 2 : 1, payDesc, total, item.id, () => {
              if (closeFlag) {
                onClose(false);
              }
            });
            setTimeout(() => {
              if (closeFlag) {
                onClose(false);
              }
            }, 2000);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    [props.onClick, closeFlag, onClose, hbFlag],
  );

  const openCreateHongbao = useCallback(
    (item: any) => {
      confirm({
        className: 'confirm-modal',
        icon: null,
        title: '自定义红包',
        content: (
          <div className={styles['modal-content']}>
            <Radio.Group defaultValue={1}>
              <Radio value={1}>普通红包</Radio>
              <Radio value={2} disabled>
                拼手气红包
              </Radio>
            </Radio.Group>
            <p>
              红包个数：
              <Input
                style={{ margin: '8px 0 0 8px', width: '66%' }}
                type="text"
                placeholder="请输入红包个数"
                ref={inputNum}
              />
            </p>
            <p>
              单个金额：
              <Input
                style={{ margin: '8px 0 0 8px', width: '66%' }}
                type="text"
                placeholder="请输入红包金额，最少0.3，最多200"
                ref={inputMoney}
              />
            </p>
            <p className={styles['modal-account-title']}>
              红包账户：
              <span className={styles['modal-account-text']}>{item.name}</span>
            </p>
          </div>
        ),
        okText: '发送',
        cancelText: '取消',
        onOk() {
          const num = parseInt(inputNum.current.state.value, 0);
          const val = parseFloat(inputMoney.current.state.value);
          if (Number.isNaN(num)) {
            message.warning('请输入有效红包个数！');
            return;
          }
          if (num <= 0) {
            message.warning('红包数量不在范围内！');
            return;
          }
          if (Number.isNaN(val)) {
            message.warning('请输入有效金额！');
            return;
          }
          if (val < 0.3 || val > 200) {
            message.warning('红包金额超出限制！');
            return;
          }
          openConfirmModal('自定义红包', item, parseInt((val * 100).toFixed(0), 0), -1, '', num);
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    [onHb],
  );
  return (
    <Popover
      overlayClassName={styles['pop-style']}
      content={
        <ul className={styles['list-wrap']}>
          {customerMoneyList.length > 0 &&
            customerMoneyList.map((item, index) => (
              <li
                className={styles.item}
                key={index}
                onClick={() => {
                  onHb(false);
                  openCreateHongbao(item);
                }}
              >
                <div className={styles.title} style={{ cursor: 'pointer' }}>
                  <span style={{ textAlign: 'left' }}>{item.name}</span>
                  <span style={{ textAlign: 'right' }}>自定义红包</span>
                </div>
              </li>
            ))}
        </ul>
      }
      trigger="click"
    >
      <IconFont
        style={{ fontSize: '20px', marginLeft: '16px', cursor: 'poiner' }}
        iconfont="iconbianjiqihongbaoicon"
        onClick={getRedPacks}
      ></IconFont>
    </Popover>
  );
};

export default LuckyMoneyGroup;
