import React, { Component } from 'react';
import { Checkbox, Upload, Icon, Popconfirm, message } from 'antd';
import IconFont from '@/components/IconFont';
import request from '@/http/request';
import './index.less';

const customEmoticon = [
  { content: './quantou.png', id: -1, checked: undefined },
  { content: './shaizi.png', id: -2, checked: undefined },
];

interface IProps {
  syncMessage: (data: any) => any;
  onRef: (e: any) => any;
  sendGamesMessage: (data: any) => any;
}

interface IState {
  currIndex: number;
  data: any[];
  isEdit: boolean;
  loading: boolean;
}

class Emoticon extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currIndex: -1,
      data: [],
      isEdit: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
    this.getOriginData();
  }

  getOriginData = () => {
    return request
      .get('/im/v2/emoticon', {
        page: 0,
        perpage: 20,
      })
      .then((res: any) => {
        const data = res.data.list.map((item: any) => {
          return {
            ...item,
            checked: undefined,
          };
        });
        this.setState({ data: [...customEmoticon, ...data], isEdit: false });
        return data;
      });
  };

  handleEmoticonEdit = (status: any) => {
    const data = this.state.data.map((item: any, index: number) => {
      return {
        ...item,
        checked: index === 0 || index === 1 ? undefined : status,
      };
    });
    this.setState(() => {
      return {
        data,
        isEdit: true,
      };
    });
  };

  handleChecked = (index: number) => {
    const gamesMessage = index === 0 || index === 1;
    const { content } = this.state.data[index];
    if (!this.state.isEdit) {
      if (gamesMessage) {
        this.props.sendGamesMessage({
          image_url: content,
          msgType: 3,
          subMsgType: index + 1,
        });
      } else {
        this.props.syncMessage({
          rawMsg: content,
          msgType: 1,
        });
      }
      return;
    }
    if (gamesMessage) {
      return;
    }
    const tempData = [...this.state.data];
    tempData[index].checked = !tempData[index].checked;
    this.setState(() => {
      return {
        data: tempData,
      };
    });
  };

  setOrder = () => {
    const data = this.state.data.filter((item: any) => item.checked);
    if (!data.length) {
      message.warning('请选择表情！');
      return;
    }
    request
      .put('/im/v2/emoticon/order', { emoticonIds: data.map((item: any) => item.id) }, true)
      .then(async () => {
        await this.getOriginData();
        this.handleEmoticonEdit(false);
      });
  };

  deleteEmoticon = () => {
    const data = this.state.data.filter((item: any) => item.checked);
    if (!data.length) {
      message.warning('请选择表情！');
      return;
    }
    request
      .delete('/im/v2/emoticon', { emoticonIds: data.map((item: any) => item.id) })
      .then(async () => {
        await this.getOriginData();
        this.handleEmoticonEdit(false);
      });
  };

  handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      this.setState({ loading: false });
      if (info.file.response.code === 200) {
        const { data } = info.file.response;
        const obj = {
          imageType: data.isGif ? 4 : 3,
          imageUrl: data.url,
          imageMd5: data.md5,
          imageLen: data.len,
        };
        request.post('/im/v2/emoticon/upload', obj).then(async () => {
          await this.getOriginData();
          this.handleEmoticonEdit(false);
        });
      }
    }
  };

  render() {
    const { currIndex, data, isEdit } = this.state;
    const checkedData = data && data.filter((item: any) => item.checked);
    return (
      <div>
        {isEdit ? (
          <div className="emoticon-title">
            <a
              style={{ color: checkedData.length ? '#262626' : '#BFBFBF' }}
              onClick={this.setOrder}
            >
              移到最前
            </a>
            <Popconfirm
              title="确认删除表情?"
              onConfirm={this.deleteEmoticon}
              okText="确认"
              cancelText="取消"
              disabled={!checkedData.length}
            >
              <a
                style={{ paddingRight: '320px', color: checkedData.length ? '#262626' : '#BFBFBF' }}
              >
                删除
              </a>
            </Popconfirm>
            <a style={{ float: 'right' }} onClick={this.getOriginData}>
              取消
            </a>
          </div>
        ) : (
          <div className="emoticon-title">
            <span>我的收藏</span>
            <IconFont
              style={{ fontSize: '18px', cursor: 'poiner' }}
              iconfont="icon-biaoqingguanli1"
              onClick={() => this.handleEmoticonEdit(false)}
            ></IconFont>
          </div>
        )}
        <div className="scroll-box" id="scroll-box-7">
          {isEdit ? (
            <div className="emoticon-upload-container">
              <Upload
                name="file"
                listType="picture-card"
                showUploadList={false}
                action="/api/v2/upload/image"
                accept=".png,.jpg,.gif,.jpeg"
                onChange={this.handleChange}
              >
                <div style={{ position: 'relative' }}>
                  <Icon
                    className="ant-upload-text"
                    type={this.state.loading ? 'loading' : 'plus'}
                  />
                </div>
              </Upload>
            </div>
          ) : (
            ''
          )}
          <div style={{ marginTop: '-4px' }}>
            {data.length
              ? data.map((item: any, index: number) => (
                  <div
                    className="eomticon-img-container"
                    key={index}
                    style={{ background: currIndex === index ? 'rgba(56,158,13,0.2)' : '' }}
                    onClick={() => this.handleChecked(index)}
                  >
                    <img
                      alt="图片"
                      className="emoticon-img"
                      src={item.content}
                      onMouseEnter={() => {
                        this.setState({ currIndex: index });
                      }}
                      onMouseLeave={() => {
                        this.setState({ currIndex: -1 });
                      }}
                    />
                    {item.checked !== undefined ? (
                      <Checkbox className="emoticon-checkbox" checked={item.checked} />
                    ) : (
                      ''
                    )}
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>
    );
  }
}

export default Emoticon;
