import React from 'react';
import { Modal, Button } from 'antd';
import { connect } from 'react-redux';
import request from '@/http/request';
import { State, WxClient } from '@/store/types/state';
import {
  createGetAccountDetailAction,
  createCreateWxSessionAction,
  createGetWxFriendListAction,
  createGetWxFriendApplyListAction,
} from '@/store/action';
import SiderTab from './component/SiderTab';
import FissionTab from './component/FissionTab';
// import Search from '../../component/Search';
import Search from './component/Search';
import CreateQunMember from './component/SiderTab/components/CreateQunMember';

interface Prop {
  target: any;
  clientList: WxClient[];
  currentClient?: WxClient;
  accountDetail: any;
  getAccountDetail: any;
  sessionList: any;
  friendList: any;
  groupList: any;
  friendApplyList: any;
  createSession: (chatId: any, chatType?: string) => any;
  getFriendList: () => any;
  getFriendApplyList: () => any;
  currentSiderTab: string;
}

interface OwnState {
  showCreateQunModal: boolean;
  btnStatus: boolean;
  loading: boolean;
}

class SessionPanel extends React.Component<Prop, OwnState> {
  constructor(props: Prop) {
    super(props);
    this.state = {
      showCreateQunModal: false,
      btnStatus: false,
      loading: false,
    };
  }

  createQunMemberChildCopyData: any[] = [];

  child: any;

  handlerSearchItemClick = (chatId: any, chatType: string, wxId: string) => {
    if (this.props.currentSiderTab[0] !== '0') {
      this.props.createSession({ chatId });
    } else {
      this.props.createSession({ chatId, chatType, wxId });
    }
  };

  openCreateQunMember = () => {
    this.props.getFriendList();
    this.setState({
      showCreateQunModal: true,
    });
  };

  confirmCreateQunMember = () => {
    this.createQunMemberChildCopyData = this.child.state.copyData;
    const wxIds = this.createQunMemberChildCopyData.map((item: any) => item.wxId);
    this.setState({
      loading: true,
    });
    request
      .post('v2/group', {
        wxId: this.props.currentClient && this.props.currentClient.wxId,
        wxIds,
      })
      .then((res: any) => {
        if (res.code === 200 && res.msg === '操作成功') {
          this.child.clearQunData();
          this.props.createSession({ chatId: res.data.groupId });
          this.setState({
            showCreateQunModal: false,
            loading: false,
          });
        } else {
          this.setState({
            showCreateQunModal: false,
            loading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          showCreateQunModal: false,
          loading: false,
        });
      });
  };

  render() {
    const { showCreateQunModal, btnStatus, loading } = this.state;
    const { currentSiderTab } = this.props;
    return (
      <>
        {this.props.currentClient ? (
          <>
            <Search
              onClick={this.handlerSearchItemClick}
              openCreateQunMember={() => this.openCreateQunMember()}
              isFission={currentSiderTab[0] === '0'}
              currentSiderTab={currentSiderTab}
            />
            {currentSiderTab[0] === '0' ? <FissionTab /> : <SiderTab />}
            <Modal
              visible={showCreateQunModal}
              width={1220}
              closable={false}
              style={{ top: 40 }}
              className="add-modal"
              onCancel={() => {
                this.setState({ showCreateQunModal: false });
              }}
              // onOk={this.confirmCreateQunMember}
              footer={[
                <span key="text" style={{ padding: '0 20px' }}>
                  选择2-39位好友建群{' '}
                </span>,
                <Button
                  key="back"
                  className="im-modal-cancel-btn"
                  onClick={() => this.setState({ showCreateQunModal: false })}
                >
                  取消
                </Button>,
                <Button
                  key="submit"
                  onClick={this.confirmCreateQunMember}
                  type="primary"
                  loading={loading}
                  className="im-modal-confirm-btn"
                  disabled={!btnStatus}
                >
                  确认
                </Button>,
              ]}
            >
              <CreateQunMember
                friendList={this.props.friendList}
                currClientNickname={this.props.currentClient && this.props.currentClient.nickname}
                updateBtnStatus={(val: boolean) => {
                  if (val === btnStatus) {
                    return;
                  }
                  this.setState({
                    btnStatus: val,
                  });
                }}
                onRef={(ref: any) => {
                  this.child = ref;
                }}
              />
            </Modal>
          </>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
    accountDetail: state.accountDetail,
    sessionList: state.sessionList,
    friendList: state.friendList,
    groupList: state.groupList,
    friendApplyList: state.friendApplyList,
    clientList: state.clients.list,
    currentClient: state.clients.current,
    currentSiderTab: state.currentSiderTab,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getFriendList: () => dispatch(createGetWxFriendListAction({ count: 5000 })),
    getFriendApplyList: () => dispatch(createGetWxFriendApplyListAction({ limit: 5000 })),
    getAccountDetail: () => dispatch(createGetAccountDetailAction({})),
    createSession: (data: any) => dispatch(createCreateWxSessionAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionPanel);
