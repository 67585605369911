import { WxSession } from '../types/state';
import { BaseAction } from '../types/action';
import { getParamValue } from '@/util';
import {
  ACTION_SET_SESSION_LIST,
  ACTION_UPDATE_SINGLE_SESSION,
  ACTION_TOP_SINGLE_SESSION,
  ACTION_GET_MOCK_SESSION_LIST,
  ACTION_REPLACE_SINGLE_SESSION,
} from '../constant/index';

export default (state: WxSession[] = [], action: BaseAction) => {
  const { data } = action;
  switch (action.type) {
    case ACTION_GET_MOCK_SESSION_LIST:
    case ACTION_SET_SESSION_LIST:
      return data;
    case ACTION_UPDATE_SINGLE_SESSION:
      return state.map(session => {
        if (session.id === data.sessionId || session.id === data.id) {
          return {
            ...session,
            ...data,
          };
        }
        return session;
      });
    // 重排序
    // TODO: 重排序逻辑会导致页面卡顿
    case ACTION_TOP_SINGLE_SESSION: {
      // 更新方法, 支持传入数组同时更新多个会话
      if (Array.isArray(data)) {
        const uniqueElementsBy = (arr: any[], fn: (a: any, b: any) => void) =>
          arr.reduce((acc, v) => {
            if (!acc.some((e: any) => fn(v, e))) acc.push(v);
            return acc;
          }, []);

        const mergeData = uniqueElementsBy(
          data.concat(state),
          (a: WxSession, b: WxSession) => a.id === b.id,
        );

        const resultStick = mergeData
          .filter((sessionItem: WxSession) => sessionItem.status)
          .sort(
            (a: WxSession, b: WxSession) =>
              new Date(b.sortTime).getTime() - new Date(a.sortTime).getTime(),
          );
        const resultNormal = mergeData
          .filter((sessionItem: WxSession) => !sessionItem.status)
          .sort(
            (a: WxSession, b: WxSession) =>
              new Date(b.sortTime).getTime() - new Date(a.sortTime).getTime(),
          );

        if (getParamValue('enable_log')) {
          console.table(
            data.map((item: any) => {
              return {
                time: item.sortTime
                  .split('T')
                  .join(' ')
                  .split('+')[0],
                name: item.nickname,
                newUnread: item.newUnread,
                msg: item.lastContent,
                status: item.status,
                wxId: item.wxId,
              };
            }),
          );
          const d = resultStick
            .concat(resultNormal)
            .slice(0, 20)
            .map((item: any) => {
              return {
                time: item.msgTime
                  .split('T')
                  .join(' ')
                  .split('+')[0],
                name: item.nickname,
                newUnread: item.newUnread,
                msg: item.lastContent,
                status: item.status,
              };
            });
          console.table(d.filter((item: any) => item.status));
          console.table(d.filter((item: any) => !item.status));
        }

        return resultStick.concat(resultNormal);
      }
      return [];

      // 若被操作对象（新消息）自身为置顶消息
      // 则去除原消息列表中旧信息对象后直接置于顶层
      // status 1: 置顶, 0: 非置顶

      // if (data && data.status) {
      //   let reArr: any[] = [];

      //   reArr = state.filter((session: any) => {
      //     const condition1 = data.sessionId && session.id !== data.sessionId;
      //     const condition2 = data.id && session.id !== data.id;
      //     return condition1 || condition2;
      //   });

      //   return [data, ...reArr];
      // }

      // // 被操作对象（新消息）为 非置顶消息 或 为置顶转换为非置顶 的处理
      // const ListOfPlaceToTop: WxSession[] = [];
      // const ListOfNotPlaceToTop: WxSession[] = [];
      // let targetSession: any = null;

      // state.forEach(session => {
      //   // 推送的会话 已存在 于sessionList当中
      //   if (session.id === data.sessionId || session.id === data.id) {
      //     let newSession: any = '';
      //     // 普通的数据格式，存在lastMsg，直接作为新的会话对象
      //     if (data.lastMsg) {
      //       newSession = data;
      //     } else {
      //       // 会话区消息发送接口(sendText)，返回的的数据格式
      //       // 无lastMsg，直接就是lastMsg内的数据结构
      //       // 需要与旧的会话的外部格式合并为正规会话格式
      //       newSession = { lastMsg: data };
      //     }
      //     targetSession = {
      //       ...session,
      //       ...newSession,
      //     };
      //   } else {
      //     // 推送会话 不存在 于sessionList当中
      //     const status = session.status || 0;
      //     if (status) {
      //       ListOfPlaceToTop.push(session);
      //     } else {
      //       ListOfNotPlaceToTop.push(session);
      //     }
      //   }
      // });

      // if ((targetSession && targetSession.status) || data.status) {
      //   // 具有 置顶设置 的会话推送至置顶部分头部
      //   ListOfPlaceToTop.unshift(targetSession || data);
      // } else {
      //   // 不具有 置顶设置 的会话推送至非置顶列表部分头部
      //   ListOfNotPlaceToTop.unshift(targetSession || data);
      // }

      // return [...ListOfPlaceToTop, ...ListOfNotPlaceToTop];
    }
    case ACTION_REPLACE_SINGLE_SESSION: {
      if (Array.isArray(data)) {
        const newData = [...state];
        data.forEach(d => {
          const index = newData.findIndex(item => item.id === d.id);
          if (index > -1) {
            newData.splice(index, 1, d);
          } else {
            newData.splice(0, 0, d);
          }
        });
        return newData;
      }
      return [];
    }
    default:
      return state;
  }
};
